import listPlatformLogo from "../../images/ic-nav-userlist-black.svg";
// import auditSettingLogo from "../../images/ic-nav-rolemgt-black.svg";

export const routes = [
    {
        key: 'platform',
        title: "Platform List",
        icon: listPlatformLogo,
        link: "/integration"
    },
    // {
    //     key: "audit",
    //     title: "Audit Trail Settings",
    //     icon: auditSettingLogo,
    //     link: "/integration/audit"
    // }
];