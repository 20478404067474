import { ApolloClient, InMemoryCache, createHttpLink } from "@apollo/client";
import { ApolloLink } from "apollo-link";
import { createAuthLink } from "aws-appsync-auth-link";
import { createSubscriptionHandshakeLink } from "aws-appsync-subscription-link";
import { TokenUtil } from "../../shared/utility/session";

const token = TokenUtil.get();

const appSyncConfig = {
  url: process.env.REACT_APP_AWS_APPSYNC_GRAPHQL_ENDPOINT,
  region: process.env.REACT_APP_AWS_APPSYNC_REGION,
  auth: {
    type: process.env.REACT_APP_AWS_APPSYNC_AUTHENTICATION_TYPE,
    apiKey: process.env.REACT_APP_AWS_APPSYNC_API_KEY,
    token: `Bearer ${token}`,
  },
};

const httpLink = createHttpLink({
  uri: appSyncConfig.url,
  headers: {
    authorization: `Bearer ${token}`,
    "x-bayad-platform-id": process.env.REACT_APP_APP_KEY,
  },
});

export const apolloClient = new ApolloClient({
  link: ApolloLink.from([
    createAuthLink(appSyncConfig),
    createSubscriptionHandshakeLink(appSyncConfig, httpLink),
  ]),
  cache: new InMemoryCache(),
  disableOffline: true,
});

export default apolloClient;
