import {Image, Modal, Row} from "react-bootstrap"
import React from "react";
import spinner from "../../images/ic-loading.svg"
import "./loading.css"

function Loading(props) {
    return (
        <Modal.Dialog centered className="login-load">
            <div className="loading-body">
                <Modal.Body>
                    <Row className="justify-content-md-center">
                        <Image src={spinner} className="spinner-logo"/>
                    </Row>
                    <Row className="justify-content-md-center">
                        <div className="loading-text">
                            {props.text}
                        </div>
                        <div className="loading-subtext">
                            It will take a few seconds
                        </div>
                    </Row>
                </Modal.Body>
            </div>
        </Modal.Dialog>
    )
}

export default Loading;