import React, { Component } from "react";
import "react-bootstrap";
import {
  Image,
  Form,
  Modal,
  Button,
  Container,
  Col,
  Row,
  FormCheck,
  Accordion,
  AccordionToggle,
  AccordionCollapse,
} from "react-bootstrap";
import "./ViewRole.css";
import "../../shared/styles/floating-label.css";
import instance from "../../shared/utility/http";
import AuditRole from "./AuditRole";
import { isUserHasAccess } from "../../shared/utility/utils";
import ExpandIcon from "../../images/ic-expand.svg";
import CollapseIcon from "../../images/ic-collapse.svg";
import _, { xor } from "lodash";
import { mapChannelModules } from './AddRole';
const EDIT_ROLE_PERMISSION = {
  scope: "uam.roles.update",
  resource: "/roles/{role_id}",
};
class ViewRole extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      activeModal: undefined,
      moduleList: [],
      productTypeList: [],
      isEditRole: false,
      isSelectAll: false,
      initialGeneralPermissions: [],
      initialProductPermissions: {},
      grantedGeneralPermissions: [],
      grantedProductPermissions: {},
      permissionList: [],
      walletPermission: {
        walletAdjustment: {
          id: 0,
          isDisplay: false,
          minAmount: "",
          maxAmount: "",
          isMinAmountValid: true,
          isMaxAmountValid: true,
        },
        walletReplenishment: {
          id: 0,
          isDisplay: false,
          minAmount: "",
          maxAmount: "",
          isMinAmountValid: true,
          isMaxAmountValid: true,
        },
      },
      baseWalletPermission: {
        walletAdjustment: {
          id: 0,
          isDisplay: false,
          minAmount: "",
          maxAmount: "",
          isMinAmountValid: true,
          isMaxAmountValid: true,
        },
        walletReplenishment: {
          id: 0,
          isDisplay: false,
          minAmount: "",
          maxAmount: "",
          isMinAmountValid: true,
          isMaxAmountValid: true,
        },
      },
      activeKeys: {},
    };
    this.viewAuditModalRef = React.createRef();
    this.onFormSubmit = this.onFormSubmit.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onBlur = this.onBlur.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.selectAll = this.selectAll.bind(this);
    this.initialize = this.initialize.bind(this);
    this.table = this.table.bind(this);
    this.onClickEditButton = this.onClickEditButton.bind(this);
    this.storeWalletAmountValidation =
      this.storeWalletAmountValidation.bind(this);
    this.storeWalletOnCheckboxChange =
      this.storeWalletOnCheckboxChange.bind(this);

    this.columns = [
      {
        Header: "Date and Time",
        accessor: ({ createdAt }) => {
          return { createdAt };
        },
        Cell: ({ value }) => {
          return <span>{value.createdAt}</span>;
        },
        id: "createdAt",
      },
      {
        Header: "Action",
        accessor: ({ action }) => {
          return { action };
        },
        Cell: ({ value }) => {
          return <span>{value.action}</span>;
        },
        id: "name",
      },
    ];
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.grantedGeneralPermissions !==
        prevState.grantedGeneralPermissions ||
      this.state.grantedProductPermissions !==
        prevState.grantedProductPermissions
    ) {
      this.setState({ isSelectAll: this.isSelectAll() });
    }
  }

  onInputChange(e) {
    let name = e.target.name;
    let val = e.target.value;
    this.setState({ [name]: val });

    let wallet = this.state.walletPermission;
    if (name.toString().includes("Approve Wallet Adjustment")) {
      const amount = val.toString().replace(/,/g, "");
      const formattedAmount = amount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.storeWalletAmountValidation(
        wallet,
        "walletAdjustment",
        name,
        formattedAmount
      );
    } else if (name.toString().includes("Approve Wallet Replenishment")) {
      const amount = val.toString().replace(/,/g, "");
      const formattedAmount = amount
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      this.storeWalletAmountValidation(
        wallet,
        "walletReplenishment",
        name,
        formattedAmount
      );
    }
  }

  onBlur(e) {
    const name = e.target.name;
    const val = e.target.value;
    if (name !== "roleName") {
      if (val !== "") {
        let wallet = this.state.walletPermission;
        const amount = parseFloat(val.toString().replace(/,/g, "")).toFixed(2);
        const formattedAmount = amount
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        if (name.toString().includes("Approve Wallet Adjustment")) {
          this.storeWalletAmountValidation(
            wallet,
            "walletAdjustment",
            name,
            formattedAmount
          );
        } else if (name.toString().includes("Approve Wallet Replenishment")) {
          this.storeWalletAmountValidation(
            wallet,
            "walletReplenishment",
            name,
            formattedAmount
          );
        }
      }
    }
  }

  async toggleModal() {
    if (!this.state.showModal) {
      this.setState({ showModal: true });
      const isEdit = this.props.isEditRole ? true : false;
      await this.setState({
        activeModal: this.props.activeModal,
        isEditRole: isEdit,
        initialGeneralPermissions: [],
        initialProductPermissions: {},
        grantedGeneralPermissions: [],
        grantedProductPermissions: {},
        activeKeys: {},
        walletPermission: {
          walletAdjustment: {
            id: 0,
            isDisplay: false,
            minAmount: "",
            maxAmount: "",
            isMinAmountValid: true,
            isMaxAmountValid: true,
          },
          walletReplenishment: {
            id: 0,
            isDisplay: false,
            minAmount: "",
            maxAmount: "",
            isMinAmountValid: true,
            isMaxAmountValid: true,
          },
        },
        baseWalletPermission: {
          walletAdjustment: {
            id: 0,
            isDisplay: false,
            minAmount: "",
            maxAmount: "",
            isMinAmountValid: true,
            isMaxAmountValid: true,
          },
          walletReplenishment: {
            id: 0,
            isDisplay: false,
            minAmount: "",
            maxAmount: "",
            isMinAmountValid: true,
            isMaxAmountValid: true,
          },
        },
      });
      await this.initialize();
    } else {
      await this.setState({
        moduleList: undefined,
        activeModal: undefined,
        selectedAll: false,
        logs: undefined,
        isSelectAll: false,
        reason: undefined,
        initialGeneralPermissions: [],
        initialProductPermissions: {},
        grantedGeneralPermissions: [],
        grantedProductPermissions: {},
        activeKeys: {},
        walletPermission: {
          walletAdjustment: {
            id: 0,
            isDisplay: false,
            minAmount: "",
            maxAmount: "",
            isMinAmountValid: true,
            isMaxAmountValid: true,
          },
          walletReplenishment: {
            id: 0,
            isDisplay: false,
            minAmount: "",
            maxAmount: "",
            isMinAmountValid: true,
            isMaxAmountValid: true,
          },
        },
        baseWalletPermission: {
          walletAdjustment: {
            id: 0,
            isDisplay: false,
            minAmount: "",
            maxAmount: "",
            isMinAmountValid: true,
            isMaxAmountValid: true,
          },
          walletReplenishment: {
            id: 0,
            isDisplay: false,
            minAmount: "",
            maxAmount: "",
            isMinAmountValid: true,
            isMaxAmountValid: true,
          },
        },
      });
      this.setState({ showModal: false });
    }
  }

  onCheckboxChange(id, checked, checkboxLabel = "") {
    let wallet = this.state.walletPermission;
    if (checkboxLabel === "Approve Wallet Adjustment") {
      wallet = this.storeWalletOnCheckboxChange(
        wallet,
        "walletAdjustment",
        id,
        checked
      );
    } else if (checkboxLabel === "Approve Wallet Replenishment") {
      wallet = this.storeWalletOnCheckboxChange(
        wallet,
        "walletReplenishment",
        id,
        checked
      );
    }
    this.setState({ walletPermission: wallet });
  }

  isSelectAll() {
    const generalModule = this.state.moduleList && this.state.moduleList.filter(
      (module) => module.name !== "Product Management"
    );
    const productModule = this.state.moduleList && this.state.moduleList.find(
      (module) => module.name === "Product Management"
    );
    const productTypeList = this.state.productTypeList;
    let currentGeneralPermissions = [];
    let currentProductPermissions = [];
    let maxGeneralPermissions = 0;
    let maxProductPermissions = 0;
    let isSelectAll = false;
    if (module && generalModule) {
      generalModule.forEach((module) => {
        maxGeneralPermissions += module.permissions.length;
      });
    }
    if (productModule) {
      productTypeList.forEach((productType) => {
        maxProductPermissions += productModule.permissions.length;
      });
    }
    this.state.grantedGeneralPermissions?.forEach((id) => {
		generalModule && generalModule.forEach((module) => {
        if (module.permissions.find((permission) => permission.id === id)) {
          if (!currentGeneralPermissions.includes(id))
            currentGeneralPermissions.push(id);
        }
      });
    });
    productTypeList.forEach((productType) => {
		this.state.grantedProductPermissions[productType.code] && this.state.grantedProductPermissions[productType.code].forEach((id) => {
        if (
          productModule.permissions.find((permission) => permission.id === id)
        ) {
          currentProductPermissions.push(id);
        }
      });
    });
    isSelectAll =
      currentGeneralPermissions.length >= maxGeneralPermissions &&
      currentProductPermissions.length >= maxProductPermissions;
    this.setState({ isSelectAll: isSelectAll });
    return isSelectAll;
  }

  selectAll(e) {
    if (this.state.isEditRole) {
      let generalPermissionIds = [];
      let productPermissionIds = {};
      this.state.productTypeList.forEach((productType) => {
        productPermissionIds[productType.code] = [];
      });
      let generalModules = this.state.moduleList.filter(
        (module) => module.name !== "Product Management"
      );
      let productModule = this.state.moduleList.find(
        (module) => module.name === "Product Management"
      );
      if (e.target.checked === true) {
        generalModules.forEach((module) => {
          module.permissions.forEach((permission) => {
            generalPermissionIds.push(permission.id);
          });
        });
        this.state.productTypeList.forEach((productType) => {
          productModule && productModule.permissions.forEach((permission) => {
            productPermissionIds[productType.code].push(permission.id);
          });
        });
      }
      generalModules.forEach((module) => {
        module.permissions.forEach((permission) => {
          this.onCheckboxChange(
            permission.id,
            e.target.checked,
            permission.name
          );
        });
      });

      this.setState({
        grantedGeneralPermissions: generalPermissionIds,
        grantedProductPermissions: productPermissionIds,
        isSelectAll: e.target.checked,
      });
    }
  }

  isSelectAllCategory(dataCategory) {
    const categoryIds = dataCategory.map(({ id }) => id);
    const findIds = this.state.grantedGeneralPermissions.filter(
      (grantedGeneralPermissionsIds) => {
        return categoryIds.some(
          (catIds) => catIds === grantedGeneralPermissionsIds
        );
      }
    );

    return categoryIds.length === findIds.length;
  }

  isSelectAllModule(name, key) {
    const module = this.state.moduleList.find((module) => module.name === name);
    let currentModulePermissions = [];
    if (!key) {
      this.state.grantedGeneralPermissions.forEach((id) => {
        if (module.permissions.find((permission) => permission.id === id)) {
          if (!currentModulePermissions.includes(id))
            currentModulePermissions.push(id);
        }
      });
    } else if (this.state.productTypeList.some((type) => type.code === key)) {
      this.state.grantedProductPermissions[key].forEach((id) => {
        if (module.permissions.find((permission) => permission.id === id)) {
          if (!currentModulePermissions.includes(id))
            currentModulePermissions.push(id);
        }
      });
    } else {
      this.state.grantedGeneralPermissions.forEach((id) => {
        module.permissions.find((permission) => {
          if (permission.id === id) {
            if (permission.submodule === null || permission.submodule === key) {
              // added submodule === null to handle non product module without submodule
              if (!currentModulePermissions.includes(id)) {
                currentModulePermissions.push(id);
              }
            }
          }
        });
      });

      return (
        currentModulePermissions.length ===
        module.permissions.filter(
          (permission) =>
            permission.submodule === key || permission.submodule === null
        ).length
      );
    }

    return currentModulePermissions.length == module.permissions.length;
  }

  selectAllCategory(e, dataCategory) {
    const categoryIds = dataCategory.map(({ id }) => id);
    let currentModulePermissions = [];

    if (this.state.isEditRole) {
      currentModulePermissions = this.state.grantedGeneralPermissions;
      currentModulePermissions = currentModulePermissions.filter(
        (id) => !categoryIds.includes(id)
      );
      if (e.target.checked === true) {
        dataCategory.forEach(({ id }) => {
          currentModulePermissions.push(id);
        });
      }

      this.setState({
        grantedGeneralPermissions: currentModulePermissions,
      });
    }
  }

  selectAllModule(e, name, key) {
    const module = this.state.moduleList.find((module) => module.name === name);
    const modulePermissionIds = module.permissions.map(
      (permission) => permission.id
    );
    let currentModulePermissions = [];
    if (this.state.isEditRole) {
      if (!key) {
        currentModulePermissions = this.state.grantedGeneralPermissions;
        currentModulePermissions = currentModulePermissions.filter(
          (id) => !modulePermissionIds.includes(id)
        );
        if (e.target.checked === true) {
          module.permissions.forEach((permission) => {
            currentModulePermissions.push(permission.id);
          });
        }
        this.setState({
          grantedGeneralPermissions: currentModulePermissions,
        });
      } else if (this.state.productTypeList.some((type) => type.code === key)) {
        if (e.target.checked === true) {
          module.permissions.forEach((permission) => {
            currentModulePermissions.push(permission.id);
          });
        }
        this.setState({
          grantedProductPermissions: {
            ...this.state.grantedProductPermissions,
            [key]: currentModulePermissions,
          },
        });
      } else {
        /* NON PRODUCT PERMISSIONS */
        currentModulePermissions = this.state.grantedGeneralPermissions;
        const subModulePermissionIds = module.permissions.map((permission) => {
          if (permission.submodule === key || permission.submodule === null) {
            return permission.id;
          }
        });
        currentModulePermissions = currentModulePermissions.filter(
          (id) => !subModulePermissionIds.includes(id)
        );

        if (e.target.checked === true) {
          module.permissions.forEach((permission) => {
            if (permission.submodule === key || permission.submodule === null) {
              // this should select channel management wide only but inserted the permissions to other submodule - done
              currentModulePermissions.push(permission.id); //this selects all 39 - done
            }
          });
        }

        this.setState({
          grantedGeneralPermissions: currentModulePermissions,
        });
      }
    }

    module.permissions.forEach((permission) => {
      if (permission.submodule === key) {
        this.onCheckboxChange(permission.id, e.target.checked, permission.name);
      }
    });

    e.stopPropagation();
  }

  selectPermission(e, id, key) {
    let currentModulePermissions = [];
    let walletPermission = this.state.walletPermission;
    const permissionWalletAdj = "walletAdjustment";
    const permissionWalletRepl = "walletReplenishment";

    if (this.state.isEditRole) {
      if (!key) {
        currentModulePermissions = this.state.grantedGeneralPermissions;
        if (e.target.checked === true) {
          currentModulePermissions.push(id);
        } else {
          currentModulePermissions.splice(
            currentModulePermissions.indexOf(id),
            1
          );
        }
        this.setState({
          grantedGeneralPermissions: currentModulePermissions,
          isSelectAll: this.isSelectAll()
        });
      } else if (this.state.productTypeList.some((type) => type.code === key)) {
        currentModulePermissions = this.state.grantedProductPermissions[key];
        if (e.target.checked === true) {
          currentModulePermissions.push(id);
        } else {
          currentModulePermissions.splice(
            currentModulePermissions.indexOf(id),
            1
          );
        }
        this.setState({
          grantedProductPermissions: {
            ...this.state.grantedProductPermissions,
            [key]: currentModulePermissions,
          },
        });
      } else {
        currentModulePermissions = this.state.grantedGeneralPermissions;

        if (e.target.checked === true) {
          currentModulePermissions.push(id);
        } else {
          currentModulePermissions = currentModulePermissions.filter(
            (permissionId) => permissionId != id
          );
        }

        this.setState({
          grantedGeneralPermissions: currentModulePermissions,
          isSelectAll: this.isSelectAll()
        });
      }

      const _p = this.state.permissionList.find((v) => id === v.id); //permission object (with details)
      if (_p.name?.includes("Approve Wallet")) {
        const isWalletAdjustment = _p.name.includes("Adjustment");
        walletPermission = this.storeWalletApprovalInfo(
          walletPermission,
          isWalletAdjustment ? permissionWalletAdj : permissionWalletRepl,
          "id",
          id
        );
        walletPermission = this.storeWalletApprovalInfo(
          walletPermission,
          isWalletAdjustment ? permissionWalletAdj : permissionWalletRepl,
          "isDisplay",
          e.target.checked
        );
      }

      this.setState({
        walletPermission,
      });
    }
    e.stopPropagation();
  }

  channelModulesMap = {
    "Channel": [
        "Add New Channel",
        "View Channel List",
        "View Primary Information",
        "View Contract Details",
        "View Reports",
        "Download Contract Details Attachment",
        "Edit Channel Status",
        "Edit Primary Information",
        "Edit Contract Details",
        "Edit Reports",
        "Edit Draft",
        "Save Channel as Draft",
        "View Draft List",
        "View Channel Approval List",
        "View Service Fee Share Approval List",
        "Approve Channel Request",
        "Approve Service Fee Share Request",
        "Reject Channel Request",
        "Reject Service Fee Share Request",
        "Resubmission of Rejected Channel Request",
        "View Products Tab",
        "View Enable/Disable Product Tab",
        "Enable Product",
        "Disable Product",
        "Enable Partner",
        "Disable Partner",
        "Enable Branch",
        "Disable Branch",
        "Enable TPAID",
        "Disable TAPID",
        "View Service Fee Share Tab",
        "Edit Service Fee Share",
        "View Channel Audit Trail",
        "View Channel Integration",
        "View Channel Client Credentials",
        "Regenerate Client Credentials",
        "Send Client Credentials ",
        "View Channel Public Certificate",
        "Download Public Certificate"
    ],
    "Branch": [
        "Add New Branch",
        "Edit Branch",
        "Edit Branch Status",
        "Save Branch as Draft",
        "View Branch",
        "View Branch List",
        "View Branch Audit Trail"
    ],
    "TPAID": [
        "Add New TPAID",
        "Edit TPAID",
        "Edit TPAID Status",
        "Save TPAID as Draft",
        "View TPAID",
        "View TPAID List",
        "View TPAID Audit Trail"
    ]
  };

  groupPermissionBySubModule(module) {
    /**
     * Group array by submodule (if submodule == null module name will be use)
     * Time Complexity: O(n)
     */
    return Object.values(
      module.permissions.reduce((acc, permission) => {
        const submodule = permission.submodule || permission.module;
        if (!acc[submodule]) {
          acc[submodule] = { name: submodule, permissions: [] };
        }
        acc[submodule].permissions.push(permission);
        return acc;
      }, {})
    );
  }

  mapChannelModules(module) {
    const channelSubModules = Object.keys(this.channelModulesMap);
    const objval = channelSubModules.reduce((acc, submodule) => {
        const perms= this.channelModulesMap[submodule].map((permission) => {
            return module.permissions.find((modulePermission) => {
                return modulePermission.name === permission
            })
            
        })
        const permissions = perms.filter(Boolean);
        if (!acc[submodule]) {
            acc[submodule] = { name: submodule, permissions };
        }
        acc[submodule].permissions = permissions;
        return acc;
    }, {})
    return Object.values(objval);
  }

  checkIfPermissionListUpdated() {
    let isPermissionListUpdated = false;
    // Check General Permissions
    if (
      xor(
        this.state.grantedGeneralPermissions,
        this.state.initialGeneralPermissions
      ).length > 0
    ) {
      isPermissionListUpdated = true;
    }
    // Check Product Permissions
    const productManagementModule = this.state.moduleList.find(
      (module) => module.name === "Product Management"
    );
    if (productManagementModule) {
      this.state.productTypeList.forEach((productType) => {
        if (
          this.state.grantedProductPermissions[productType.code].length !=
          productManagementModule.permissions.length
        ) {
          isPermissionListUpdated = true;
        }
      });
    }
    return isPermissionListUpdated;
  }

  checkIfApprovalAmountUpdated() {
    const { baseWalletPermission, walletPermission } = this.state;
    const {
      walletAdjustment: baseWalletAdjustment,
      walletReplenishment: baseWalletReplenishment,
    } = baseWalletPermission;
    const { walletAdjustment, walletReplenishment } = walletPermission;
    const {
      minAmount: baseAdjustmentMinAmount,
      maxAmount: baseAdjustmentMaxAmount,
    } = baseWalletAdjustment;
    const {
      minAmount: baseReplenishmentMinAmount,
      maxAmount: baseReplenishmentMaxAmount,
    } = baseWalletReplenishment;
    const { minAmount: adjustmentMinAmount, maxAmount: adjustmentMaxAmount } =
      walletAdjustment;
    const {
      minAmount: replenishmentMinAmount,
      maxAmount: replenishmentMaxAmount,
    } = walletReplenishment;

    return ![
      baseAdjustmentMinAmount !== adjustmentMinAmount,
      baseAdjustmentMaxAmount !== adjustmentMaxAmount,
      baseReplenishmentMinAmount !== replenishmentMinAmount,
      baseReplenishmentMaxAmount !== replenishmentMaxAmount,
    ].includes(true);
  }

  checkIfApprovalAmountIsValid(permission) {
    const isValid =
      !this.state.walletPermission[permission].isMinAmountValid ||
      !this.state.walletPermission[permission].isMaxAmountValid ||
      this.state.walletPermission[permission].minAmount == "" ||
      this.state.walletPermission[permission].maxAmount == "";
    return isValid;
  }

  isDisabled() {
    if (this.state.walletPermission.walletAdjustment.isDisplay) {
      if (this.checkIfApprovalAmountIsValid("walletAdjustment")) {
        return true;
      }
    }
    if (this.state.walletPermission.walletReplenishment.isDisplay) {
      if (this.checkIfApprovalAmountIsValid("walletReplenishment")) {
        return true;
      }
    }
    return false;
  }

  onClickEditButton = async () => {
    if (await isUserHasAccess(EDIT_ROLE_PERMISSION)) {
      this.setState({ isEditRole: true });
    } else {
      window.isAccessDenied = true;
    }
  };

  storeWalletApprovalInfo(walletState, permission, field, value) {
    let walletPermission = walletState;
    let adjustment = walletPermission[permission];
    adjustment[field] = value;
    walletPermission[permission] = adjustment;
    walletState = walletPermission;

    return walletState;
  }

  storeWalletAmountValidation(wallet, walletPermission, name, val) {
    const permission = walletPermission;
    const field = name.toString().split("-")[1];
    const oppositeField = field === "minAmount" ? "maxAmount" : "minAmount";

    const validNumberRegEx = /^(?=.)(\d{1,3}(,\d{3})*)?(\.\d+)?$/;
    const isValidNumber = validNumberRegEx.test(val);
    const fieldValidation =
      field === "minAmount" ? "isMinAmountValid" : "isMaxAmountValid";
    wallet = this.storeWalletApprovalInfo(wallet, permission, field, val);
    if (isValidNumber) {
      wallet = this.storeWalletApprovalInfo(
        wallet,
        permission,
        fieldValidation,
        true
      );

      const permissionApprovalAmount = this.state.walletPermission[permission];
      const currentFieldAmount = parseFloat(
        permissionApprovalAmount[field].toString().replace(/,/g, "")
      ).toFixed(2);
      const oppositeFieldAmount = parseFloat(
        permissionApprovalAmount[oppositeField].toString().replace(/,/g, "")
      ).toFixed(2);
      if (
        Number(currentFieldAmount) < 1 ||
        Number(currentFieldAmount) > 1000000000
      ) {
        wallet = this.storeWalletApprovalInfo(
          wallet,
          permission,
          fieldValidation,
          false
        );
      } else {
        if (
          field === "minAmount" &&
          permissionApprovalAmount[oppositeField] != ""
        ) {
          if (Number(currentFieldAmount) < Number(oppositeFieldAmount)) {
            wallet = this.storeWalletApprovalInfo(
              wallet,
              permission,
              "isMinAmountValid",
              true
            );
            wallet = this.storeWalletApprovalInfo(
              wallet,
              permission,
              "isMaxAmountValid",
              true
            );
          } else {
            wallet = this.storeWalletApprovalInfo(
              wallet,
              permission,
              "isMinAmountValid",
              false
            );
          }
        }
        if (
          field === "maxAmount" &&
          permissionApprovalAmount[oppositeField] != ""
        ) {
          if (Number(currentFieldAmount) > Number(oppositeFieldAmount)) {
            wallet = this.storeWalletApprovalInfo(
              wallet,
              permission,
              "isMaxAmountValid",
              true
            );
            wallet = this.storeWalletApprovalInfo(
              wallet,
              permission,
              "isMinAmountValid",
              true
            );
          } else {
            wallet = this.storeWalletApprovalInfo(
              wallet,
              permission,
              "isMaxAmountValid",
              false
            );
          }
        }
      }
    } else {
      wallet = this.storeWalletApprovalInfo(
        wallet,
        permission,
        fieldValidation,
        false
      );
    }

    this.setState({ walletPermission: wallet });
  }

  storeWalletOnCheckboxChange(wallet, permission, id, isChecked) {
    wallet = this.storeWalletApprovalInfo(
      wallet,
      permission,
      "isDisplay",
      isChecked
    );
    wallet = this.storeWalletApprovalInfo(wallet, permission, "id", id);
    if (!isChecked) {
      wallet = this.storeWalletApprovalInfo(
        wallet,
        permission,
        "minAmount",
        ""
      );
      wallet = this.storeWalletApprovalInfo(
        wallet,
        permission,
        "maxAmount",
        ""
      );
      wallet = this.storeWalletApprovalInfo(
        wallet,
        permission,
        "isMinAmountValid",
        true
      );
      wallet = this.storeWalletApprovalInfo(
        wallet,
        permission,
        "isMaxAmountValid",
        true
      );
    }
    return wallet;
  }

  async onFormSubmit(e) {
    e.preventDefault();

    const roleId = this.state.activeModal.id;
    const editRoleBody = {
      name: this.state.roleName,
      description: this.state.roleName,
      reason: this.state.reason,
    };
    let message = "",
      type = "";

    let isRoleNameUnique = true;
    let isSaveRoleSuccess = true;
    let isSaveRolePermissionSuccess = true;
    try {
      if (this.state.initialRoleName !== this.state.roleName) {
        await instance
          .put("/v3/roles/" + roleId, JSON.stringify(editRoleBody))
          .then((data) => {
            message = `Updated information for ${editRoleBody.name} role`;
            type = "success";
            isSaveRoleSuccess = true;
          })
          .catch((error) => {
            isSaveRoleSuccess = false;
            if (error.response.status === 422) {
              message = `${this.state.roleName} role is already taken.`;
              type = "failed";
              isRoleNameUnique = false;
            } else {
              message = `${editRoleBody.name} role is not successfully updated`;
              type = "failed";
            }
          });
      }

      if (this.checkIfPermissionListUpdated() && isSaveRoleSuccess) {
        await instance
          .put(`/v3/roles/${roleId}`, {
            name: this.state.roleName,
            permissions: this.state.grantedGeneralPermissions,
            product_permissions: this.state.grantedProductPermissions,
            reason: this.state.reason,
          })
          .then((resp) => {
            message = `Updated permissions for ${editRoleBody.name} role`;
            type = "success";
            isSaveRolePermissionSuccess = true;
          })
          .catch((error) => {
            console.log(error);
            message = `${editRoleBody.name} role's permissions are not successfully updated`;
            type = "failed";
            isSaveRolePermissionSuccess = false;
          });
      }

      const walletPermission = this.state.walletPermission;
      if (!this.checkIfApprovalAmountUpdated()) {
        for (const permission in walletPermission) {
          const {
            id: permissionId,
            minAmount,
            maxAmount,
          } = walletPermission[permission];
          const data = {
            roleId,
            permissionId,
            minAmount,
            maxAmount,
          };

          if (data["permissionId"] !== 0) {
            await instance.put("/v2/permissions/approval", data);
          }
        }
      }

      if (!isRoleNameUnique) {
        message = `${this.state.roleName} role is already taken.`;
        type = "failed";
      } else {
        if (isSaveRoleSuccess && isSaveRolePermissionSuccess) {
          message = `Updated information for ${editRoleBody.name} role`;
          type = "success";
        } else {
          message = `${editRoleBody.name} role is not successfully updated`;
          type = "failed";
        }
      }
    } catch (e) {
      message = `${editRoleBody.name} role is not successfully updated`;
      type = "failed";
    }
    this.props.parentCallback(message, type, this.state.roleName);
    this.toggleModal();
  }

  async initialize() {
    let moduleList = [];
    let permissions = [];
    let productTypeList = [];
    let productPermissions = {};
    let activeKeys = {};
    const roleId = this.state.activeModal.id;
    await instance
      .get("/v3/products/types")
      .then((resp) => {
        productTypeList = resp.data.data || [];
        productTypeList.forEach((productType) => {
          productPermissions[productType.code] = [];
        });
        this.setState({ productTypeList: productTypeList });
      })
      .catch((err) => {
        console.log(err);
      });

    await instance
      .get(`/v3/permissions`)
      .then(function (response) {
        permissions = response.data.data;
        let moduleNameList = [];
        permissions.forEach((x) => {
          if (!moduleNameList.includes(x.module) && x.module) {
            moduleNameList.push(x.module);
            moduleList.push({
              name: x.module,
              permissions: [],
            });
            activeKeys[x.module.replace(" ", "")] = true;
          }
        });
        moduleList.forEach((x) => {
          x.permissions.push(...permissions.filter((y) => y.module === x.name));
        });
      })
      .catch(function (error) {
        console.log(error);
      });

    await instance
      .get(`/v3/roles/${roleId}/permissions`)
      .then((resp) => {
        const permissionIds = resp.data.permissions;
        const product_permissions = resp.data.product_permissions;
        let approvalAmountList = [];
        let wallet = this.state.walletPermission;
        const permissionWalletAdj = "walletAdjustment";
        const permissionWalletRepl = "walletReplenishment";

        permissionIds.map((resp) => {
          const _p = permissions.find((v) => resp == v.id); //permission object (with details)
          if (_p.name?.includes("Approve Wallet")) {
            const isWalletAdjustment = _p.name.includes("Adjustment");
            wallet = this.storeWalletApprovalInfo(
              wallet,
              isWalletAdjustment ? permissionWalletAdj : permissionWalletRepl,
              "id",
              resp
            );
            wallet = this.storeWalletApprovalInfo(
              wallet,
              isWalletAdjustment ? permissionWalletAdj : permissionWalletRepl,
              "isDisplay",
              true
            );
            approvalAmountList.push(resp);
          }
        });
        const permissionApprovalUrl = approvalAmountList.map(
          (permission) => `/v2/permissions/approval/${roleId}/${permission}`
        );
        let requests = permissionApprovalUrl.map((url) => instance.get(url));
        let baseWallet = this.state.baseWalletPermission;
        Promise.all(requests)
          .then((responses) => {
            responses.forEach((resp) => {
              const val = resp["data"][0];
              const isWalletAdj =
                val["permission_id"] == wallet.walletAdjustment.id;
              const permission = isWalletAdj
                ? permissionWalletAdj
                : permissionWalletRepl;
              const min_amount = val["min_amount"]
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              const max_amount = val["max_amount"]
                .toString()
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
              wallet = this.storeWalletApprovalInfo(
                wallet,
                permission,
                "minAmount",
                min_amount
              );
              wallet = this.storeWalletApprovalInfo(
                wallet,
                permission,
                "maxAmount",
                max_amount
              );
              baseWallet = this.storeWalletApprovalInfo(
                baseWallet,
                permission,
                "minAmount",
                min_amount
              );
              baseWallet = this.storeWalletApprovalInfo(
                baseWallet,
                permission,
                "maxAmount",
                max_amount
              );

              this.setState({ walletPermission: wallet });
            });
          })
          .catch((err) => {
            console.log(err);
          });
        this.setState({
          moduleList: moduleList,
          walletPermission: wallet,
          baseWalletPermission: baseWallet,
          activeKeys: activeKeys,
          selectedAll: false,
          permissionList: permissions,
          roleName: this.state.activeModal.name,
          initialRoleName: this.state.activeModal.name,
          initialGeneralPermissions: [...permissionIds],
          initialProductPermissions: {
            ...productPermissions,
            ...product_permissions,
          },
          grantedGeneralPermissions: [...permissionIds],
          grantedProductPermissions: {
            ...productPermissions,
            ...product_permissions,
          },
        });
      })
      .catch((err) => {
        this.setState({
          moduleList: moduleList,
          isEditRole: false,
          selectedAll: false,
          activeKeys: activeKeys,
          roleName: this.state.activeModal.name,
          initialRoleName: this.state.activeModal?.name,
          initialProductPermissions: { ...productPermissions },
          grantedProductPermissions: { ...productPermissions },
        });
        console.log(err);
      });

    await instance
      .get(`/v1/roles/${roleId}/logs?limit=5`)
      .then((resp) => {
        this.setState({ logs: resp.data.data });
      })
      .catch((err) => {
        console.log(err);
      });

    this.isSelectAll();
  }

  showViewRoleModal() {
    const productManagementModules = [
      "Wallet Adjustment",
      "Wallet Management",
      "Wallet Replenishment",
      "Wallet List",
      "Cash Flow",
      "Product Management",
      "Transaction Management",
    ];

    const transactionManagementPermissions = this.state.moduleList
      ? this.state.moduleList.filter(
          (module) => module.name === "Transaction Management"
        )
      : [];
    const centralWalletSystemPermissions = this.state.moduleList
      ? this.state.moduleList.filter(
          (module) => module.name === "Central Wallet System"
        )
      : [];
    const productManagementPermissions = this.state.moduleList
      ? this.state.moduleList.filter(
          (module) => module.name === "Product Management"
        )
      : [];

    const reportsPermissions = this.state.moduleList
      ? this.state.moduleList.filter(({ name }) => name === "Reports")
      : [];

    const reportMaintenancePermissions = this.state.moduleList
      ? this.state.moduleList.filter(
          ({ name }) => name === "Report Maintenance"
        )
      : [];

    const channelManagementPermissions = this.state.moduleList
      ? this.state.moduleList.filter(
          ({ name }) => name === "Channel Management"
        )
      : [];

    const reportsGenPermission = [
      {
        name: "Business Reports and Generation",
        submodule: "Reports",
        permissions:
          reportsPermissions && reportsPermissions.length > 0
            ? reportsPermissions[0].permissions
            : [],
        category: {
          "Product Reports":
            reportsPermissions && reportsPermissions.length > 0
              ? reportsPermissions[0].permissions.filter(
                  (data) => data.submodule === "Product Reports"
                )
              : [],
          "Channel Reports":
            reportsPermissions && reportsPermissions.length > 0
              ? reportsPermissions[0].permissions.filter(
                  (data) => data.submodule === "Channel Reports"
                )
              : [],
          "Reconciliation Reports":
            reportsPermissions && reportsPermissions.length > 0
              ? reportsPermissions[0].permissions.filter(
                  (data) => data.submodule === "Reconciliation Reports"
                )
              : [],
          "Finance Reports":
            reportsPermissions && reportsPermissions.length > 0
              ? reportsPermissions[0].permissions.filter(
                  (data) => data.submodule === "Finance Reports"
                )
              : [],
          "Accounting Reports":
            reportsPermissions && reportsPermissions.length > 0
              ? reportsPermissions[0].permissions.filter(
                  (data) => data.submodule === "Accounting Reports"
                )
              : [],
          "RPA Reports":
            reportsPermissions && reportsPermissions.length > 0
              ? reportsPermissions[0].permissions.filter(
                  (data) => data.submodule === "RPA Reports"
                )
              : [],
          "Other Reports":
            reportsPermissions && reportsPermissions.length > 0
              ? reportsPermissions[0].permissions.filter(
                  (data) => data.submodule === "Other Reports"
                )
              : [],
        },
      },
      {
        name: "Business Reports and Generation",
        submodule: "Report Maintenance",
        permissions:
          reportMaintenancePermissions &&
          reportMaintenancePermissions.length > 0
            ? reportMaintenancePermissions[0].permissions
            : [],
        category: {
          "Product Reports":
            reportMaintenancePermissions &&
            reportMaintenancePermissions.length > 0
              ? reportMaintenancePermissions[0].permissions.filter(
                  (data) => data.submodule === "Product Reports"
                )
              : [],
          "Channel Reports":
            reportMaintenancePermissions &&
            reportMaintenancePermissions.length > 0
              ? reportMaintenancePermissions[0].permissions.filter(
                  (data) => data.submodule === "Channel Reports"
                )
              : [],
          "Reconciliation Reports":
            reportMaintenancePermissions &&
            reportMaintenancePermissions.length > 0
              ? reportMaintenancePermissions[0].permissions.filter(
                  (data) => data.submodule === "Reconciliation Reports"
                )
              : [],
          "Finance Reports":
            reportMaintenancePermissions &&
            reportMaintenancePermissions.length > 0
              ? reportMaintenancePermissions[0].permissions.filter(
                  (data) => data.submodule === "Finance Reports"
                )
              : [],
          "Accounting Reports":
            reportMaintenancePermissions &&
            reportMaintenancePermissions.length > 0
              ? reportMaintenancePermissions[0].permissions.filter(
                  (data) => data.submodule === "Accounting Reports"
                )
              : [],
          "RPA Reports":
            reportMaintenancePermissions &&
            reportMaintenancePermissions.length > 0
              ? reportMaintenancePermissions[0].permissions.filter(
                  (data) => data.submodule === "RPA Reports"
                )
              : [],
          "Other Reports":
            reportMaintenancePermissions &&
            reportMaintenancePermissions.length > 0
              ? reportMaintenancePermissions[0].permissions.filter(
                  (data) => data.submodule === "Other Reports"
                )
              : [],
        },
      },
    ];
    const centralWalletSystemGroupedPermissions =
      centralWalletSystemPermissions.length > 0
        ? this.groupPermissionBySubModule(centralWalletSystemPermissions[0])
        : [];
    const channelManagementGroupedPermissions =
      channelManagementPermissions.length > 0
        ? mapChannelModules(channelManagementPermissions[0])
        : [];
    const generalPermissions = this.state.moduleList
      ? this.state.moduleList.filter(
          (module) =>
            !productManagementModules.includes(module.name) &&
            module.name !== "Central Wallet System" &&
            module.name !== "Old Business Reports and Generation" &&
            module.name !== "Business Reports and Generation" &&
            module.name !== "Reports" &&
            module.name !== "Report Maintenance" && 
            module.name !== "Channel Management"

        )
      : [];
    return (
      <Modal size="xl" centered show={this.state.showModal} animation={false}>
        <Modal.Body>
          <h3 className="modal-title">
            {!this.state.isEditRole ? "View Role" : "Edit Role"}
          </h3>
          <Container fluid>
            <Row lg="12" className="px-4">
              <Col lg={{ span: 4, offset: 8 }}>
                <div style={{ float: "right" }}>
                  There are
                  <span style={{ color: "#0077c0", fontWeight: "bold" }}>
                    {" "}
                    {this.state.activeModal
                      ? this.state.activeModal.connectedUser
                      : ""}{" "}
                    users{" "}
                  </span>
                  on this role
                </div>
              </Col>
            </Row>
            <Form onSubmit={this.onFormSubmit} className="p-2">
              <Row>
                <Col>
                  <Row style={{ lineHeight: "55px" }}>
                    <Col xl="4" lg="8">
                      <div>Enable/Disable Role</div>
                    </Col>
                    <Col>
                      <FormCheck
                        disabled={!this.state.isEditRole}
                        readOnly={!this.state.isEditRole}
                        custom
                        id="role-switch"
                        type="switch"
                        checked={
                          this.state.activeModal
                            ? this.state.activeModal.isActive
                            : false
                        }
                        className="role-switch"
                        style={{
                          left: "-23px",
                          top: "16px",
                          position: "absolute",
                        }}
                      />
                    </Col>
                  </Row>
                </Col>
                <Col>
                  <Row>
                    <Col className="text-center">
                      <Button
                        bsPrefix="btn-no-fill"
                        className="m-2 btn-no-fill view-role"
                        disabled={this.state.isEditRole}
                        onClick={this.onClickEditButton}
                      >
                        Edit
                      </Button>
                    </Col>
                    <Col className="text-center">
                      <Button
                        bsPrefix="btn-no-fill"
                        className="m-2 btn-no-fill view-role"
                        onClick={() => this.onViewAuditClick()}
                        disabled={this.state.isEditRole}
                      >
                        Check Audit Trail
                      </Button>
                    </Col>
                    <Col className="text-center">
                      <Button
                        bsPrefix="btn-fill"
                        className="m-2 btn-delete view-role"
                        disabled={this.state.isEditRole}
                      >
                        Delete Role
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="p-2">
                <Col>
                  <div className="input-block checkbox-holder">
                    <Form.Control
                      disabled={!this.state.isEditRole}
                      readOnly={!this.state.isEditRole}
                      className="roleName"
                      name="roleName"
                      type="text"
                      required
                      maxLength={255}
                      defaultValue={
                        this.state.activeModal
                          ? this.state.activeModal.name
                          : ""
                      }
                      onChange={this.onInputChange}
                    ></Form.Control>
                    <span
                      className="placeholder"
                      style={{
                        transform: "scale(.75) translateY(-28px)",
                        background: "#fff",
                      }}
                    >
                      Role Name
                    </span>
                  </div>
                </Col>
              </Row>
              <Form.Group
                controlId="formAllPermission"
                className="permission-list"
              >
                <Row lg="12" className="p-4 checkbox-holder">
                  <Form.Check
                    disabled={!this.state.isEditRole}
                    readOnly={!this.state.isEditRole}
                    checked={this.state.isSelectAll}
                    type="checkbox"
                    id="all-permissions"
                    label="Select All Permissions"
                    onChange={(e) => this.selectAll(e)}
                  />
                </Row>
                <hr className="mx-4" />
                {/* FOR Business Reports and Generation Permission */}
                {reportsPermissions.length > 0 &&
                  reportMaintenancePermissions.length > 0 && (
                    <>
                      <div className="px-4 pt-4">
                        <label className="px-4">
                          <strong>Business Reports and Generation</strong>
                        </label>
                      </div>
                      <div className="px-4">
                        {reportsGenPermission.map((module, i) => {
                          const submoduleId = module.submodule.replace(" ", "");
                          const submodule = module.submodule;
                          const category = module.category;
                          const categoryKeys = Object.keys(category);
                          return (
                            <Accordion key={i} defaultActiveKey={submoduleId}>
                              <Col
                                lg="12"
                                className="px-5 checkbox-holder pt-4"
                                key={submoduleId}
                              >
                                <Form.Check
                                  disabled={!this.state.isEditRole}
                                  readOnly={!this.state.isEditRole}
                                  checked={this.isSelectAllModule(submodule)}
                                  type="checkbox"
                                  id={submoduleId}
                                  label={
                                    <>
                                      {submodule}
                                      <AccordionToggle
                                        style={{
                                          border: "none",
                                          background: "none",
                                        }}
                                        eventKey={submoduleId}
                                        onClick={(evt) => {
                                          this.setState({
                                            activeKeys: {
                                              ...this.state.activeKeys,
                                              [submoduleId]:
                                                !this.state.activeKeys[
                                                  submoduleId
                                                ],
                                            },
                                          });
                                        }}
                                      >
                                        <label className="expandCollapseLink">
                                          {this.state.activeKeys[
                                            submoduleId
                                          ] === true ? (
                                            <Image
                                              className="expandIcon"
                                              src={ExpandIcon}
                                            />
                                          ) : (
                                            <Image
                                              className="collapseIcon"
                                              src={CollapseIcon}
                                            />
                                          )}
                                        </label>
                                      </AccordionToggle>
                                    </>
                                  }
                                  onChange={(e) =>
                                    this.selectAllModule(e, submodule)
                                  }
                                />
                              </Col>
                              <AccordionCollapse eventKey={submoduleId}>
                                <Row lg="12">
                                  <Col
                                    lg={{ span: 11, offset: 1 }}
                                    className="checkbox-holder px-1 py-2"
                                    key={submodule}
                                  >
                                    {categoryKeys.map((key) => {
                                      const categoryPermission = category[key];
                                      const idCategory = key.replace(" ", "");
                                      const categoryEventKey = `${submoduleId}_${idCategory}`;

                                      return (
                                        <>
                                          <Accordion
                                            key={i}
                                            defaultActiveKey={categoryEventKey}
                                          >
                                            <Form.Check
                                              disabled={!this.state.isEditRole}
                                              readOnly={!this.state.isEditRole}
                                              checked={this.isSelectAllCategory(
                                                categoryPermission
                                              )}
                                              type="checkbox"
                                              id={categoryEventKey}
                                              label={
                                                <>
                                                  {key}
                                                  <AccordionToggle
                                                    style={{
                                                      border: "none",
                                                      background: "none",
                                                    }}
                                                    eventKey={categoryEventKey}
                                                    onClick={(evt) => {
                                                      this.setState({
                                                        activeKeys: {
                                                          ...this.state
                                                            .activeKeys,
                                                          [categoryEventKey]:
                                                            !this.state
                                                              .activeKeys[
                                                              categoryEventKey
                                                            ],
                                                        },
                                                      });
                                                    }}
                                                  >
                                                    <label className="expandCollapseLink">
                                                      {this.state.activeKeys[
                                                        categoryEventKey
                                                      ] === true ? (
                                                        <Image
                                                          className="collapseIcon"
                                                          src={CollapseIcon}
                                                        />
                                                      ) : (
                                                        <Image
                                                          className="expandIcon"
                                                          src={ExpandIcon}
                                                        />
                                                      )}
                                                    </label>
                                                  </AccordionToggle>
                                                </>
                                              }
                                              onChange={(e) =>
                                                this.selectAllCategory(
                                                  e,
                                                  categoryPermission
                                                )
                                              }
                                            />
                                            <AccordionCollapse
                                              eventKey={categoryEventKey}
                                            >
                                              <Row>
                                                {categoryPermission &&
                                                  categoryPermission.map(
                                                    (data) => {
                                                      const { name, id } = data;
                                                      return (
                                                        <>
                                                          <Col
                                                            lg={{
                                                              span: 3,
                                                              offset: 1,
                                                            }}
                                                            key={
                                                              categoryPermission.id
                                                            }
                                                            className="checkbox-holder px-0 py-1"
                                                          >
                                                            <Form.Check
                                                              disabled={
                                                                !this.state
                                                                  .isEditRole
                                                              }
                                                              readOnly={
                                                                !this.state
                                                                  .isEditRole
                                                              }
                                                              checked={this.state.grantedGeneralPermissions.includes(
                                                                id
                                                              )}
                                                              type="checkbox"
                                                              id={id}
                                                              label={name}
                                                              className="m-2"
                                                              onChange={(e) =>
                                                                this.selectPermission(
                                                                  e,
                                                                  id
                                                                )
                                                              }
                                                            />
                                                          </Col>
                                                        </>
                                                      );
                                                    }
                                                  )}
                                              </Row>
                                            </AccordionCollapse>
                                          </Accordion>
                                        </>
                                      );
                                    })}
                                  </Col>
                                </Row>
                              </AccordionCollapse>
                            </Accordion>
                          );
                        })}
                      </div>
                    </>
                  )}
                {/* FOR PRODUCT MANAGEMENT PERMISSIONS */}
                {transactionManagementPermissions.length > 0 && (
                  <div>
                    <div className="px-4 pt-4">
                      <label className="px-4">
                        <strong>Transaction Management</strong>
                      </label>
                    </div>
                    <div className="px-4">
                      {transactionManagementPermissions.map((module, i) => {
                        const permissions = module.permissions;
                        const eventKey = module.name.replace(" ", "");
                        return (
                          <Accordion key={i} defaultActiveKey={eventKey}>
                            <Row lg="12">
                              <Col
                                lg="12"
                                className="px-5 checkbox-holder pt-4"
                                key={module.name}
                              >
                                <Form.Check
                                  disabled={!this.state.isEditRole}
                                  readOnly={!this.state.isEditRole}
                                  checked={this.isSelectAllModule(module.name)}
                                  type="checkbox"
                                  id={module.name}
                                  label={
                                    <>
                                      {module.name}
                                      <AccordionToggle
                                        style={{
                                          border: "none",
                                          background: "none",
                                        }}
                                        eventKey={eventKey}
                                        onClick={(evt) => {
                                          this.setState({
                                            activeKeys: {
                                              ...this.state.activeKeys,
                                              [eventKey]:
                                                !this.state.activeKeys[
                                                  eventKey
                                                ],
                                            },
                                          });
                                        }}
                                      >
                                        <label className="expandCollapseLink">
                                          {this.state.activeKeys[eventKey] ===
                                          true ? (
                                            <Image
                                              className="expandIcon"
                                              src={ExpandIcon}
                                            />
                                          ) : (
                                            <Image
                                              className="collapseIcon"
                                              src={CollapseIcon}
                                            />
                                          )}
                                        </label>
                                      </AccordionToggle>
                                    </>
                                  }
                                  onChange={(e) =>
                                    this.selectAllModule(e, module.name)
                                  }
                                />
                              </Col>
                              <AccordionCollapse eventKey={eventKey}>
                                <Row lg="12">
                                  <Col lg={{ span: 11, offset: 1 }}>
                                    <Row>
                                      {permissions.map((permission) => {
                                        return (
                                          <>
                                            <Col
                                              lg={4}
                                              key={permission.id}
                                              className="checkbox-holder"
                                            >
                                              <Form.Check
                                                disabled={
                                                  !this.state.isEditRole
                                                }
                                                readOnly={
                                                  !this.state.isEditRole
                                                }
                                                checked={this.state.grantedGeneralPermissions.includes(
                                                  permission.id
                                                )}
                                                type="checkbox"
                                                id={permission.id}
                                                label={permission.name}
                                                className="m-2"
                                                onChange={(e) =>
                                                  this.selectPermission(
                                                    e,
                                                    permission.id
                                                  )
                                                }
                                              />
                                            </Col>
                                          </>
                                        );
                                      })}
                                    </Row>
                                  </Col>
                                </Row>
                              </AccordionCollapse>
                            </Row>
                          </Accordion>
                        );
                      })}
                    </div>
                    <hr className="mx-4 mt-5" />
                  </div>
                )}
                {centralWalletSystemPermissions.length > 0 && (
                  <div>
                    <div className="px-4 pt-4">
                      <label className="px-4">
                        <strong>Central Wallet System</strong>
                      </label>
                    </div>
                    <div className="px-4">
                      {centralWalletSystemGroupedPermissions.map(
                        (subModule, i) => {
                          const module = centralWalletSystemPermissions[0];
                          const permissions = subModule.permissions;
                          const eventKey = subModule.name.replace(" ", "");
                          return (
                            <Accordion key={i} defaultActiveKey={eventKey}>
                              <Row lg="12">
                                <Col
                                  lg="12"
                                  className="px-5 checkbox-holder pt-4"
                                  key={module.name}
                                >
                                  <Form.Check
                                    disabled={!this.state.isEditRole}
                                    readOnly={!this.state.isEditRole}
                                    checked={this.isSelectAllModule(
                                      module.name,
                                      subModule.name
                                    )}
                                    type="checkbox"
                                    id={subModule.name}
                                    label={
                                      <>
                                        {subModule.name}
                                        <AccordionToggle
                                          style={{
                                            border: "none",
                                            background: "none",
                                          }}
                                          eventKey={eventKey}
                                          onClick={(evt) => {
                                            this.setState({
                                              activeKeys: {
                                                ...this.state.activeKeys,
                                                [eventKey]:
                                                  !this.state.activeKeys[
                                                    eventKey
                                                  ],
                                              },
                                            });
                                          }}
                                        >
                                          <label className="expandCollapseLink">
                                            {this.state.activeKeys[eventKey] ===
                                            true ? (
                                              <Image
                                                className="expandIcon"
                                                src={ExpandIcon}
                                              />
                                            ) : (
                                              <Image
                                                className="collapseIcon"
                                                src={CollapseIcon}
                                              />
                                            )}
                                          </label>
                                        </AccordionToggle>
                                      </>
                                    }
                                    onChange={(e) =>
                                      this.selectAllModule(
                                        e,
                                        module.name,
                                        subModule.name
                                      )
                                    }
                                  />
                                </Col>
                                <AccordionCollapse eventKey={eventKey}>
                                  <Row lg="12">
                                    <Col lg={{ span: 11, offset: 1 }}>
                                      <Row>
                                        {permissions.map((permission) => {
                                          return (
                                            <>
                                              <Col
                                                lg={`${
                                                  [
                                                    "Wallet Adjustment",
                                                    "Wallet Replenishment",
                                                  ].includes(subModule.name)
                                                    ? 8
                                                    : 4
                                                }`}
                                                key={permission.id}
                                                className="checkbox-holder"
                                              >
                                                <Form.Check
                                                  disabled={
                                                    !this.state.isEditRole
                                                  }
                                                  readOnly={
                                                    !this.state.isEditRole
                                                  }
                                                  checked={this.state.grantedGeneralPermissions.includes(
                                                    permission.id
                                                  )}
                                                  type="checkbox"
                                                  id={`${subModule.name}.${permission.id}`}
                                                  label={permission.name}
                                                  className="m-2"
                                                  onChange={(e) =>
                                                    this.selectPermission(
                                                      e,
                                                      permission.id,
                                                      permission.name
                                                    )
                                                  }
                                                />
                                              </Col>
                                              {(permission.name ===
                                                "Approve Wallet Adjustment" ||
                                                permission.name ===
                                                  "Approve Wallet Replenishment") && (
                                                <>
                                                  <div
                                                    style={{
                                                      display: `${
                                                        (
                                                          permission.name ===
                                                            "Approve Wallet Adjustment" ||
                                                          this.state.grantedGeneralPermissions.includes(
                                                            "Approve Wallet Adjustment"
                                                          )
                                                            ? this.state
                                                                .walletPermission
                                                                .walletAdjustment
                                                                .isDisplay
                                                            : this.state
                                                                .walletPermission
                                                                .walletReplenishment
                                                                .isDisplay
                                                        )
                                                          ? "block"
                                                          : "none"
                                                      }`,
                                                    }}
                                                  >
                                                    <Col className="p-1" lg={4}>
                                                      <div className="input-block">
                                                        <Form.Control
                                                          disabled={
                                                            !this.state
                                                              .isEditRole
                                                          }
                                                          readOnly={
                                                            !this.state
                                                              .isEditRole
                                                          }
                                                          onChange={
                                                            this.onInputChange
                                                          }
                                                          onBlur={this.onBlur}
                                                          name={
                                                            permission.name +
                                                            "-" +
                                                            "minAmount"
                                                          }
                                                          type="text"
                                                          value={
                                                            permission.name ===
                                                            "Approve Wallet Adjustment"
                                                              ? this.state
                                                                  .walletPermission
                                                                  .walletAdjustment
                                                                  .minAmount
                                                              : this.state
                                                                  .walletPermission
                                                                  .walletReplenishment
                                                                  .minAmount
                                                          }
                                                          className={`${
                                                            (
                                                              permission.name ===
                                                              "Approve Wallet Adjustment"
                                                                ? this.state
                                                                    .walletPermission
                                                                    .walletAdjustment
                                                                    .isMinAmountValid
                                                                : this.state
                                                                    .walletPermission
                                                                    .walletReplenishment
                                                                    .isMinAmountValid
                                                            )
                                                              ? ""
                                                              : "isError"
                                                          }`}
                                                        />
                                                        <span className="placeholder">
                                                          Minimum Amount for
                                                          Approval
                                                        </span>
                                                        {(permission.name ===
                                                        "Approve Wallet Adjustment"
                                                          ? !this.state
                                                              .walletPermission
                                                              .walletAdjustment
                                                              .isMinAmountValid
                                                          : !this.state
                                                              .walletPermission
                                                              .walletReplenishment
                                                              .isMinAmountValid) && (
                                                          <small
                                                            style={{
                                                              color: "#FF0000",
                                                            }}
                                                          >
                                                            Invalid Amount for
                                                            Approval
                                                          </small>
                                                        )}
                                                      </div>
                                                    </Col>
                                                    <Col className="p-1" lg={4}>
                                                      <div className="input-block">
                                                        <Form.Control
                                                          disabled={
                                                            !this.state
                                                              .isEditRole
                                                          }
                                                          readOnly={
                                                            !this.state
                                                              .isEditRole
                                                          }
                                                          onChange={
                                                            this.onInputChange
                                                          }
                                                          onBlur={this.onBlur}
                                                          name={
                                                            permission.name +
                                                            "-" +
                                                            "maxAmount"
                                                          }
                                                          type="text"
                                                          value={
                                                            permission.name ===
                                                            "Approve Wallet Adjustment"
                                                              ? this.state
                                                                  .walletPermission
                                                                  .walletAdjustment
                                                                  .maxAmount
                                                              : this.state
                                                                  .walletPermission
                                                                  .walletReplenishment
                                                                  .maxAmount
                                                          }
                                                          className={`${
                                                            (
                                                              permission.name ===
                                                              "Approve Wallet Adjustment"
                                                                ? this.state
                                                                    .walletPermission
                                                                    .walletAdjustment
                                                                    .isMaxAmountValid
                                                                : this.state
                                                                    .walletPermission
                                                                    .walletReplenishment
                                                                    .isMaxAmountValid
                                                            )
                                                              ? ""
                                                              : "isError"
                                                          }`}
                                                        />
                                                        <span className="placeholder">
                                                          Maximum Amount for
                                                          Approval
                                                        </span>
                                                        {(permission.name ===
                                                        "Approve Wallet Adjustment"
                                                          ? !this.state
                                                              .walletPermission
                                                              .walletAdjustment
                                                              .isMaxAmountValid
                                                          : !this.state
                                                              .walletPermission
                                                              .walletReplenishment
                                                              .isMaxAmountValid) && (
                                                          <small
                                                            style={{
                                                              color: "#FF0000",
                                                            }}
                                                          >
                                                            Invalid Amount for
                                                            Approval
                                                          </small>
                                                        )}
                                                      </div>
                                                    </Col>
                                                  </div>
                                                </>
                                              )}
                                            </>
                                          );
                                        })}
                                      </Row>
                                    </Col>
                                  </Row>
                                </AccordionCollapse>
                              </Row>
                            </Accordion>
                          );
                        }
                      )}
                    </div>
                    <hr className="mx-4 mt-5" />
                  </div>
                )}
                
                {productManagementPermissions.length > 0 &&
                  this.state.productTypeList.length > 0 && (
                    <div>
                      <div className="px-4 pt-4">
                        <label className="px-4">
                          <strong>Product Management</strong>
                        </label>
                      </div>
                      <div className="px-4">
                        {this.state.productTypeList.map((productType, i) => {
                          return productManagementPermissions.map(
                            (module, i) => {
                              const permissions = module.permissions;
                              const eventKey = productType.name.replace(
                                " ",
                                ""
                              );
                              return (
                                <Accordion key={i} defaultActiveKey={eventKey}>
                                  <Row lg="12">
                                    <Col
                                      lg="12"
                                      className="px-5 checkbox-holder pt-4"
                                      key={module.name}
                                    >
                                      <Form.Check
                                        disabled={!this.state.isEditRole}
                                        readOnly={!this.state.isEditRole}
                                        checked={this.isSelectAllModule(
                                          module.name,
                                          productType.code
                                        )}
                                        type="checkbox"
                                        id={productType.name}
                                        label={
                                          <>
                                            {productType.name}
                                            <AccordionToggle
                                              style={{
                                                border: "none",
                                                background: "none",
                                              }}
                                              eventKey={eventKey}
                                              onClick={(evt) => {
                                                this.setState({
                                                  activeKeys: {
                                                    ...this.state.activeKeys,
                                                    [eventKey]:
                                                      !this.state.activeKeys[
                                                        eventKey
                                                      ],
                                                  },
                                                });
                                              }}
                                            >
                                              <label className="expandCollapseLink">
                                                {this.state.activeKeys[
                                                  eventKey
                                                ] === true ? (
                                                  <Image
                                                    className="expandIcon"
                                                    src={ExpandIcon}
                                                  />
                                                ) : (
                                                  <Image
                                                    className="collapseIcon"
                                                    src={CollapseIcon}
                                                  />
                                                )}
                                              </label>
                                            </AccordionToggle>
                                          </>
                                        }
                                        onChange={(e) =>
                                          this.selectAllModule(
                                            e,
                                            module.name,
                                            productType.code
                                          )
                                        }
                                      />
                                    </Col>
                                    <AccordionCollapse eventKey={eventKey}>
                                      <Row lg="12">
                                        <Col lg={{ span: 11, offset: 1 }}>
                                          <Row>
                                            {permissions.map((permission) => {
                                              return (
                                                <>
                                                  <Col
                                                    lg={4}
                                                    key={permission.id}
                                                    className="checkbox-holder"
                                                  >
                                                    <Form.Check
                                                      disabled={
                                                        !this.state.isEditRole
                                                      }
                                                      readOnly={
                                                        !this.state.isEditRole
                                                      }
                                                      checked={this.state.grantedProductPermissions[
                                                        productType.code
                                                      ].includes(permission.id)}
                                                      type="checkbox"
                                                      id={`${productType.code}.${permission.id}`}
                                                      label={permission.name}
                                                      className="m-2"
                                                      onChange={(e) =>
                                                        this.selectPermission(
                                                          e,
                                                          permission.id,
                                                          productType.code
                                                        )
                                                      }
                                                    />
                                                  </Col>
                                                </>
                                              );
                                            })}
                                          </Row>
                                        </Col>
                                      </Row>
                                    </AccordionCollapse>
                                  </Row>
                                </Accordion>
                              );
                            }
                          );
                        })}
                      </div>
                      <hr className="mx-4 mt-5" />
                    </div>
                  )}

                {/* FOR NON PRODUCT MANAGEMENT PERMISSIONS */}
                {generalPermissions.map((module, i) => {
                  const subModules = this.groupPermissionBySubModule(module);
                  return (
                    subModules.length > 0 && (
                      <div>
                        <div className="px-4 pt-4">
                          <label className="px-4">
                            <strong>{module.name}</strong>
                          </label>
                        </div>
                        <div className="px-4">
                          {subModules.map((subModule, i) => {
                            const permissions = subModule.permissions;
                            const eventKey = subModule.name.replace(" ", "");
                            return (
                              <Accordion key={i} defaultActiveKey={eventKey}>
                                <Row lg="12">
                                  <Col
                                    lg="12"
                                    className="px-5 checkbox-holder pt-4"
                                    key={module.name}
                                  >
                                    <Form.Check
                                      disabled={!this.state.isEditRole}
                                      readOnly={!this.state.isEditRole}
                                      checked={this.isSelectAllModule(
                                        module.name,
                                        subModule.name
                                      )}
                                      type="checkbox"
                                      id={subModule.name}
                                      label={
                                        <>
                                          {subModule.name}
                                          <AccordionToggle
                                            style={{
                                              border: "none",
                                              background: "none",
                                            }}
                                            eventKey={eventKey}
                                            onClick={(evt) => {
                                              this.setState({
                                                activeKeys: {
                                                  ...this.state.activeKeys,
                                                  [eventKey]:
                                                    !this.state.activeKeys[
                                                      eventKey
                                                    ],
                                                },
                                              });
                                            }}
                                          >
                                            <label className="expandCollapseLink">
                                              {this.state.activeKeys[
                                                eventKey
                                              ] === true ? (
                                                <Image
                                                  className="expandIcon"
                                                  src={ExpandIcon}
                                                />
                                              ) : (
                                                <Image
                                                  className="collapseIcon"
                                                  src={CollapseIcon}
                                                />
                                              )}
                                            </label>
                                          </AccordionToggle>
                                        </>
                                      }
                                      onChange={(e) =>
                                        this.selectAllModule(e, subModule.name)
                                      }
                                    />
                                  </Col>
                                  <AccordionCollapse eventKey={eventKey}>
                                    <Row lg="12">
                                      <Col lg={{ span: 11, offset: 1 }}>
                                        <Row>
                                          {permissions.map((permission) => {
                                            return (
                                              <>
                                                <Col
                                                  lg={4}
                                                  key={permission.id}
                                                  className="checkbox-holder"
                                                >
                                                  <Form.Check
                                                    disabled={
                                                      !this.state.isEditRole
                                                    }
                                                    readOnly={
                                                      !this.state.isEditRole
                                                    }
                                                    checked={this.state.grantedGeneralPermissions.includes(
                                                      permission.id
                                                    )}
                                                    type="checkbox"
                                                    id={`${subModule.name}.${permission.id}`}
                                                    label={permission.name}
                                                    className="m-2"
                                                    onChange={(e) =>
                                                      this.selectPermission(
                                                        e,
                                                        permission.id,
                                                        permission.name
                                                      )
                                                    }
                                                  />
                                                </Col>
                                              </>
                                            );
                                          })}
                                        </Row>
                                      </Col>
                                    </Row>
                                  </AccordionCollapse>
                                </Row>
                              </Accordion>
                            );
                          })}
                        </div>
                        <hr className="mx-4 mt-5" />
                      </div>
                    )
                  );
                })}
                {channelManagementPermissions.length > 0 && (
                  <div>
                    <div className="px-4 pt-4">
                      <label className="px-4">
                        <strong>Channel Management</strong>
                      </label>
                    </div>
                    <div className="px-4">
                      {channelManagementGroupedPermissions.map(
                        (subModule, i) => {
                          const module = channelManagementPermissions[0];
                          const permissions = subModule.permissions;
                          const eventKey = subModule.name.replace(" ", "");
                          return (
                            <Accordion key={i} defaultActiveKey={eventKey}>
                              <Row lg="12">
                                <Col
                                  lg="12"
                                  className="px-5 checkbox-holder pt-4"
                                  key={module.name}
                                >
                                  <Form.Check
                                    disabled={!this.state.isEditRole}
                                    readOnly={!this.state.isEditRole}
                                    checked={this.isSelectAllModule(
                                      module.name,
                                      subModule.name
                                    )}
                                    type="checkbox"
                                    id={subModule.name}
                                    label={
                                      <>
                                        {subModule.name}
                                        <AccordionToggle
                                          style={{
                                            border: "none",
                                            background: "none",
                                          }}
                                          eventKey={eventKey}
                                          onClick={(evt) => {
                                            this.setState({
                                              activeKeys: {
                                                ...this.state.activeKeys,
                                                [eventKey]:
                                                  !this.state.activeKeys[
                                                    eventKey
                                                  ],
                                              },
                                            });
                                          }}
                                        >
                                          <label className="expandCollapseLink">
                                            {this.state.activeKeys[eventKey] ===
                                            true ? (
                                              <Image
                                                className="expandIcon"
                                                src={ExpandIcon}
                                              />
                                            ) : (
                                              <Image
                                                className="collapseIcon"
                                                src={CollapseIcon}
                                              />
                                            )}
                                          </label>
                                        </AccordionToggle>
                                      </>
                                    }
                                    onChange={(e) =>
                                      this.selectAllModule(
                                        e,
                                        module.name,
                                        subModule.name
                                      )
                                    }
                                  />
                                </Col>
                                <AccordionCollapse eventKey={eventKey}>
                                  <Row lg="12">
                                    <Col lg={{ span: 11, offset: 1 }}>
                                      <Row>
                                        {permissions.map((permission) => {
                                          return (
                                            <>
                                              <Col
                                                lg={4}
                                                key={permission.id}
                                                className="checkbox-holder"
                                              >
                                                <Form.Check
                                                  disabled={
                                                    !this.state.isEditRole
                                                  }
                                                  readOnly={
                                                    !this.state.isEditRole
                                                  }
                                                  checked={this.state.grantedGeneralPermissions.includes(
                                                    permission.id
                                                  )}
                                                  type="checkbox"
                                                  id={`${subModule.name}.${permission.id}`}
                                                  label={permission.name}
                                                  className="m-2"
                                                  onChange={(e) =>
                                                    this.selectPermission(
                                                      e,
                                                      permission.id,
                                                      permission.name
                                                    )
                                                  }
                                                />
                                              </Col>
                                            </>
                                          );
                                        })}
                                      </Row>
                                    </Col>
                                  </Row>
                                </AccordionCollapse>
                              </Row>
                            </Accordion>
                          );
                        }
                      )}
                    </div>
                    <hr className="mx-4 mt-5" />
                  </div>
                )}
              </Form.Group>
              {this.state.isEditRole && (
                <Row>
                  <Col className="m-4">
                    <Form.Control
                      as="textarea"
                      rows={4}
                      name="reason"
                      placeholder="Reason for updating the role/permission."
                      onChange={this.onInputChange}
                    ></Form.Control>
                  </Col>
                </Row>
              )}
              <Row className="p-4">
                <h6>Last 5 Actions</h6>
                {this.table()}
              </Row>
              <Row className="p-2">
                <Col className="text-center">
                  <Button
                    bsPrefix="btn-fill"
                    className="m-2 btn-fill view-role"
                    onClick={this.toggleModal}
                  >
                    Close
                  </Button>
                  {this.state.isEditRole && (
                    <Button
                      type="submit"
                      bsPrefix="platform-btn platform-btn-add"
                      className="m-2"
                      disabled={this.isDisabled()}
                    >
                      Save
                    </Button>
                  )}
                </Col>
              </Row>
            </Form>
          </Container>
        </Modal.Body>
      </Modal>
    );
  }

  render() {
    return (
      <>
        {this.showViewRoleModal()}
        <AuditRole
          {...{ activeModal: this.state.activeModal }}
          ref={this.viewAuditModalRef}
        />
      </>
    );
  }

  table() {
    return this.state.logs ? (
      <>
        <table
          className="table table-striped rounded"
          style={{ overflow: "hidden", borderTop: "1px solid black" }}
        >
          <colgroup>
            <col span="1" width="20%"></col>
            <col span="1" width="10%"></col>
            <col span="1" width="20%"></col>
            <col span="1" width="20%"></col>
            <col span="1" width="10%"></col>
            <col span="1" width="10%"></col>
            <col span="1" width="10%"></col>
          </colgroup>
          <thead style={{ backgroundColor: "#FFFFFF" }}>
            <tr>
              <th>Date and Time</th>
              <th>Action</th>
              <th>Old Values</th>
              <th>New Values</th>
              <th>Logged By</th>
              <th>Source IP</th>
              <th>Reason</th>
            </tr>
          </thead>
          <tbody>
            {this.state.logs.map((row, i) => {
              return (
                <tr key={i}>
                  <td>{row.created_at}</td>
                  <td>{row.action}</td>
                  <td style={{ wordBreak: "break-all" }}>{row.old_values}</td>
                  <td style={{ wordBreak: "break-all" }}>{row.new_values}</td>
                  <td>{row.name}</td>
                  <td>{row.source_ip}</td>
                  <td>{row.note}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    ) : (
      <>No records found</>
    );
  }

  async onViewAuditClick() {
    this.viewAuditModalRef.current.toggleModal();
  }

  viewAuditModalRef = ({ toggleModal }) => {
    this.showModal = toggleModal;
  };
}

export default ViewRole;
