import { Pagination as BSPagination } from 'react-bootstrap';

const Pagination = ({
	page = 1,
	count = 0,
	limit = 25,
	onPageSelect,
	className = '',
}) => {
	const pageCount = Math.ceil(count / limit);
	const handlePageSelect = (p) => {
		onPageSelect && onPageSelect(p);
	};
	return (
		<BSPagination className={className}>
			{Array(pageCount)
				.fill()
				.map((_, i) => (
					<BSPagination.Item
						key={i}
						active={i + 1 === page}
						onClick={() => handlePageSelect(i + 1)}
					>
						{i + 1}
					</BSPagination.Item>
				))}
		</BSPagination>
	);
};

export default Pagination;
