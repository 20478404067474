import React, { Component, useMemo } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { MdEdit, MdRemoveCircle } from "react-icons/md";
import { FaList } from "react-icons/fa";
import uamLogo from "../../images/logo-UAM.png";
import faLogo from "../../images/logo-BFA.png";
import ipLogo from "../../images/logo-BIP.png";
import IcReset from "../../images/ic-reset.svg";
import Dashboard from "../../containers/Dashboard/Dashboard";
import { routes } from "./routes";
import Header from "../header/Header";
import { Route, Switch } from "react-router";
import instance from "../../shared/utility/http";
import { Image, Card } from "react-bootstrap";
import UserListFilter from "./UserListFilter";
import RoleList from "./RoleList";
import "./UserList.css";
import AddDataButton from "./AddDataButton";
import AddUserModal from "./AddUserModal";
import AddRole from "./AddRole";
import CustomModal from "../../shared/modal/CustomModal";
import moment from "moment";
import UserDetails from "./UserDetails";
import { getSessionStorage } from "../../shared/utility/storage";
import ReactTooltip from "react-tooltip";
import ResetPassword from "./ResetPasswordModal";
import BlockModal from "./BlockModal";
import { isAdmin } from "../../shared/utility/utils";
import { isUserHasAccess } from "../../shared/utility/utils";
import Loading from "../loading/Loading";
import LoadingModal from "./LoadingModal";
import AddByBatchResult from "./AddByBatchResult";
import { capFirstLetter } from "./Utility/Text";

const CREATE_USER_PERMISSION = {
  scope: "uam.users.create",
  resource: "/v1/users",
};

const CREATE_ROLE_PERMISSION = {
  scope: "uam.roles.create",
  resource: "/v1/roles",
};

const VIEW_USER_PERMISSION_DEV = {
  scope: "uam.users.details",
  resource: "/v1/users/{userId}",
};

const VIEW_USER_PERMISSION_STG = {
  scope: "uam.users.view",
  resource: "/v1/users/{userId}",
};

class UserDashboard extends Component {
  constructor() {
    super();
    this.state = {
      title: "User List",
      buttonTitle: "Add New User",
      flag: "User",
      showModal: true,
      activeModal: undefined,
      data: [],
      controlledPageCount: 0,
      totalCount: 0,
      search: "",
      userrole: "",
      usertype: "",
      status: "",
      selectedUserId: "",
      selectedUserHash: "",
      place: "bottom",
      type: "light",
      condition: false,
      runQueryData: false,
      filters: {},
      ackId: "",
    };
    this.addRoleModalRef = React.createRef();
    this.roleListRef = React.createRef();
    this.addUserModalRef = React.createRef();
    this.viewUserDetailsRef = React.createRef();
    this.viewResetPasswordRef = React.createRef();
    this.viewBlockModalRef = React.createRef();
    this.AddByBatchResultRef = React.createRef();

    this.setTitle = this.setTitle.bind(this);
    this.setButtonTitle = this.setButtonTitle.bind(this);
    this.setFlag = this.setFlag.bind(this);
    this.onDisplayModal = this.onDisplayModal.bind(this);
    this.callbackFunction = this.callbackFunction.bind(this);
    this.callbackResetPassFunction = this.callbackResetPassFunction.bind(this);
    this.displayAlertNotification = this.displayAlertNotification.bind(this);
    this.setDisplayModal = this.setDisplayModal.bind(this);
    this.pagination = this.pagination.bind(this);
    this.sort = this.sort.bind(this);
    this.searchOnChange = this.searchOnChange.bind(this);
    this.onViewUserClick = this.onViewUserClick.bind(this);
    this.onViewResetPasswordRefClick =
      this.onViewResetPasswordRefClick.bind(this);
    this.onViewBlockModalRefClick = this.onViewBlockModalRefClick.bind(this);
    this.onViewAddBatchResultOpen = this.onViewAddBatchResultOpen.bind(this);
    this.onViewLoadingClose = this.onViewLoadingClose.bind(this);
    this.setLogo = this.setLogo.bind(this);
    this.properties = {
      pageIndex: 0,
      pageSize: 25,
      sort: undefined,
      column: undefined,
    };
    this.columns = [
      {
        Header: "Date and Time Created",
        accessor: ({ createdAt, status }) => {
          return { createdAt, status };
        },
        Cell: ({ value }) => {
          var color = "";
          if (value.status.toUpperCase() == "ACTIVE") {
            color = "#000";
          } else if (value.status.toUpperCase() == "INACTIVE") {
            color = "#6c757d";
          } else if (value.status.toUpperCase() == "BLOCKED") {
            color = "#df0818";
          } else if (
            value.status.toUpperCase() == "TEMPORARY_BLOCKED" ||
            value.status.toUpperCase() == "TEMPORARY BLOCKED"
          ) {
            color = "#0176c0";
          }
          return <span style={{ color }}>{value.createdAt}</span>;
        },
        sortInverted: true,
        id: "createdAt",
      },
      {
        Header: "Username",
        accessor: ({ username, status }) => {
          return { username, status };
        },
        Cell: ({ value }) => {
          var color = "";
          if (value.status.toUpperCase() == "ACTIVE") {
            color = "#000";
          } else if (value.status.toUpperCase() == "INACTIVE") {
            color = "#6c757d";
          } else if (value.status.toUpperCase() == "BLOCKED") {
            color = "#df0818";
          } else if (
            value.status.toUpperCase() == "TEMPORARY_BLOCKED" ||
            value.status.toUpperCase() == "TEMPORARY BLOCKED"
          ) {
            color = "#0176c0";
          }
          return <span style={{ color }}>{value.username}</span>;
        },
        id: "username",
      },
      {
        Header: "User Role",
        accessor: ({ roleName, status }) => {
          return { roleName, status };
        },
        Cell: ({ value }) => {
          var color = "";
          if (value.status.toUpperCase() == "ACTIVE") {
            color = "#000";
          } else if (value.status.toUpperCase() == "INACTIVE") {
            color = "#6c757d";
          } else if (value.status.toUpperCase() == "BLOCKED") {
            color = "#df0818";
          } else if (
            value.status.toUpperCase() == "TEMPORARY_BLOCKED" ||
            value.status.toUpperCase() == "TEMPORARY BLOCKED"
          ) {
            color = "#0176c0";
          }
          return <span style={{ color }}>{value.roleName}</span>;
        },
        sortInverted: true,
        id: "roleName",
      },
      {
        Header: "User Type",
        accessor: ({ type, status }) => {
          return { type, status };
        },
        Cell: ({ value }) => {
          var color = "";
          if (value.status.toUpperCase() == "ACTIVE") {
            color = "#000";
          } else if (value.status.toUpperCase() == "INACTIVE") {
            color = "#6c757d";
          } else if (value.status.toUpperCase() == "BLOCKED") {
            color = "#df0818";
          } else if (
            value.status.toUpperCase() == "TEMPORARY_BLOCKED" ||
            value.status.toUpperCase() == "TEMPORARY BLOCKED"
          ) {
            color = "#0176c0";
          }
          return <span style={{ color }}>{value.type}</span>;
        },
        sortInverted: true,
        id: "type",
      },
      {
        Header: "Status",
        accessor: ({ status }) => {
          return { status };
        },
        Cell: ({ value }) => {
          var color = "";
          if (value.status.toUpperCase() == "ACTIVE") {
            color = "#000";
          } else if (value.status.toUpperCase() == "INACTIVE") {
            color = "#6c757d";
          } else if (value.status.toUpperCase() == "BLOCKED") {
            color = "#df0818";
          } else if (
            value.status.toUpperCase() == "TEMPORARY_BLOCKED" ||
            value.status.toUpperCase() == "TEMPORARY BLOCKED"
          ) {
            color = "#0176c0";
          }

          return <span style={{ color }}>{capFirstLetter(value.status)}</span>;
        },
        sortInverted: true,
        id: "status",
      },
      {
        Header: "Action",
        accessor: ({ id, status, username, connectedUser }) => {
          return { id, status, username, connectedUser };
        },
        disableSortBy: true,
        Cell: ({ value }) => {
          const colorEditMore = value.status ? "#F26122" : "#6c757d";
          const enableDisableColor = ["ACTIVE", "INACTIVE"].includes(
            value.status.toUpperCase()
          )
            ? "#F81C2C"
            : "#00AF00";

          const { place, type, effect } = this.state;
          const toolTipStatus = ["ACTIVE", "INACTIVE"].includes(
            value.status.toUpperCase()
          )
            ? "block"
            : "unblock";

          return (
            <div
              className="d-flex justify-content-between"
              style={{ marginRight: "10px" }}
            >
              <a data-tip="Resend Link">
                <Image
                  src={IcReset}
                  style={{ fill: colorEditMore, cursor: "pointer" }}
                  onClick={() => this.onViewResetPasswordRefClick(value)}
                  hidden={!isAdmin()}
                />
              </a>
              <a
                data-tip={
                  ["ACTIVE", "INACTIVE"].includes(value.status.toUpperCase())
                    ? "Block"
                    : "Unblock"
                }
              >
                <MdRemoveCircle
                  style={{ fill: enableDisableColor, cursor: "pointer" }}
                  onClick={() =>
                    this.onViewBlockModalRefClick({
                      idObj: value,
                      type: toolTipStatus,
                    })
                  }
                />
              </a>

              <ReactTooltip place={place} type="light" />

              <a data-tip data-for="viewDetails">
                <FaList
                  onClick={() => this.onViewUserClick(value, value.status)}
                  style={{ fill: colorEditMore, cursor: "pointer" }}
                />
              </a>

              <ReactTooltip id="viewDetails" place={place} type="light">
                <span>View Details</span>
              </ReactTooltip>
            </div>
          );
        },
        id: "action",
      },
    ];

    this.queryData = this.queryData.bind(this);
  }

  onViewUserClick = async ({ id, status }) => {
    const [isHasAccessOnDev, isHasAccessOnStg] = await Promise.all([
      await isUserHasAccess(VIEW_USER_PERMISSION_DEV),
      await isUserHasAccess(VIEW_USER_PERMISSION_STG),
    ]);

    if (isHasAccessOnDev || isHasAccessOnStg) {
      const userHash = this.state.data
        .filter((user) => user.id === id)
        .map((r) => {
          return r.hash;
        })[0];
      this.setState({ selectedUserId: id, selectedUserHash: userHash }, () => {
        this.viewUserDetailsRef.current.toggleModal(status);
      });
    } else {
      window.isAccessDenied = true;
    }
  };

  onViewResetPasswordRefClick({ id }) {
    const userHash = this.state.data
      .filter((user) => user.id === id)
      .map((r) => {
        return r.hash;
      })[0];
    this.setState({ selectedUserId: id, selectedUserHash: userHash }, () => {
      this.viewResetPasswordRef.current.toggleModal();
    });
  }

  onViewBlockModalRefClick({ idObj, type }) {
    const userHash = this.state.data
      .filter((user) => user.id === idObj.id)
      .map((r) => {
        return r.hash;
      })[0];
    this.setState({ selectedUserId: idObj, selectedUserHash: userHash }, () => {
      this.viewBlockModalRef.current.toggleModal(type);
    });
  }

  onViewAddBatchResultOpen(value = () => {}) {
    this.setState({ ackId: value });
    this.AddByBatchResultRef.current.toggleModal(this.state.ackId);
  }

  onViewLoadingClose() {
    // this.AddByBatchResultRef.current.toggleModal();
    // this.setState({ ackId: false });
  }

  callbackFunction(message, type) {
    this.setState({
      message: message,
      type: type,
      display: true,
      runQueryData: true,
    });
  }

  callbackResetPassFunction(message, type) {
    this.setState({
      message: message,
      type: type,
      display: true,
      runQueryData: false,
    });
  }

  setLogo() {
    const platformName = getSessionStorage("platformName");
    if (platformName === "Bayad IP") {
      return ipLogo;
    } else if (platformName === "Bayad FA") {
      return faLogo;
    }
    return uamLogo;
  }

  setTitle(title) {
    this.setState({ title: title });
  }

  setButtonTitle(title) {
    this.setState({ buttonTitle: title });
  }

  setFlag(flag) {
    this.setState({ flag: flag });
  }

  onDisplayModal = async (event) => {
    const flag = this.state.flag;
    if (flag.toUpperCase() === "ROLE") {
      if (await isUserHasAccess(CREATE_ROLE_PERMISSION)) {
        this.addRoleModalRef.current.toggleModal();
      } else {
        window.isAccessDenied = true;
      }
    } else {
      if (await isUserHasAccess(CREATE_USER_PERMISSION)) {
        this.addUserModalRef.current.toggleModal();
      } else {
        window.isAccessDenied = true;
      }
    }
  };

  setDisplayModal(display) {
    this.setState({ display: display });
  }

  displayAlertNotification() {
    if (this.state && this.state.display) {
      if (this.state.flag === "Role") {
        if (this.state.runQueryData) this.roleListRef.current.queryData();
      } else {
        // call user list query data while keeping filters
        if (this.state.runQueryData) this.queryData(this.state.filters);
      }

      return (
        <CustomModal {...this.state} setDisplayModal={this.setDisplayModal} />
      );
    }
  }

  searchOnChange(event) {
    this.setState({ search: event.target.value }, () => {
      if (this.state.search.length > 2 || this.state.search === "") {
        this.queryData();
      }
    });
  }

  setDate(date, key) {
    this.setState({ [key]: date }, () => {
      if (this.state.dateFrom !== "" && this.state.dateTo !== "") {
        this.queryData();
      }
    });
  }

  queryData(filters = {}) {
    this.setState({ runQueryData: false, filters: filters });
    let params = { page: this.properties.pageIndex + 1 };
    const self = this;

    if (this.properties.sort && this.properties.column) {
      params["sort"] = this.properties.sort;
      params["sort_by"] = this.properties.column;
    }

    if (
      filters.search &&
      (filters.search.length > 2 || filters.search !== "")
    ) {
      params["search"] = filters.search;
    }

    if (filters.role && filters.role !== "") {
      params["user_role"] = filters.role;
    }

    if (filters.type && filters.type !== "") {
      params["type"] = filters.type;
    }

    if (filters.status && filters.status !== "") {
      params["status"] = filters.status;
    }

    instance
      .get("/v1/users/", {
        params,
      })
      .then(function (response) {
        self.setState({
          data: response.data.data.map((x) => {
            return {
              ...x,
              createdAt: moment(new Date(x.createdAt)).format(
                "MM/DD/YYYY h:mm:ss a"
              ),
              status:
                x.status === "TEMPORARY_BLOCKED"
                  ? "TEMPORARY BLOCKED"
                  : x.status,
            };
          }),
          totalCount: response.data.meta.total_count,
          controlledPageCount: Math.ceil(response.data.meta.total_count / 25),
        });

        ReactTooltip.rebuild();
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  sort(desc, column) {
    const isDesc = column === "createdAt" ? !desc : desc;
    this.properties.sort = isDesc ? "DESC" : "ASC";
    this.properties.column = column;
    this.queryData();
  }

  searchOnChange() {
    this.queryData();
  }

  pagination({ pageIndex, pageSize }) {
    this.properties.pageIndex = pageIndex;
    this.properties.pageSize = pageSize;
    this.queryData();
  }

  render() {
    const sideBarProps = {
      routes: routes,
      logo: this.setLogo(),
      uppernavExtras: (
        <AddDataButton
          buttonTitle={this.state.buttonTitle}
          onClick={this.onDisplayModal}
        />
      ),
    };

    const headerProps = {
      title: this.state.title,
    };

    const tableProps = {
      columns: this.columns,
      data: this.state.data,
      controlledPageCount: this.state.controlledPageCount,
      pagination: this.pagination,
      count: this.state.totalCount,
      sort: this.sort,
    };

    const filterProps = {
      searchOnChange: this.searchOnChange,
    };

    return (
      <Dashboard sideBarProps={sideBarProps}>
        <AddUserModal
          ref={this.addUserModalRef}
          parentCallback={this.callbackFunction}
          onViewAddBatchResultOpen={this.onViewAddBatchResultOpen}
        />
        <UserDetails
          ref={this.viewUserDetailsRef}
          userId={this.state.selectedUserId}
          userHash={this.state.selectedUserHash}
          emitBlock={(idObj, type) => {
            this.onViewBlockModalRefClick({ idObj, type });
          }}
        />
        <ResetPassword
          {...this.state}
          ref={this.viewResetPasswordRef}
          userId={this.state.selectedUserId}
          userHash={this.state.selectedUserHash}
          parentCallback={this.callbackResetPassFunction}
        />
        <BlockModal
          {...this.state}
          ref={this.viewBlockModalRef}
          userId={this.state.selectedUserId}
          userHash={this.state.selectedUserHash}
          parentCallback={this.callbackFunction}
        />
        <AddByBatchResult
          {...this.state}
          ref={this.AddByBatchResultRef}
          ack_id={this.state.ackId}
        />
        <div className="parent container-fluid">
          <AddRole
            ref={this.addRoleModalRef}
            parentCallback={this.callbackFunction}
          />
          {this.displayAlertNotification()}
          <div className="header">
            <Header {...headerProps} />
          </div>
          <Switch>
            <Route
              path="/user-dashboard/role-management"
              render={(props) => (
                <RoleList
                  {...props}
                  setTitle={this.setTitle}
                  setButtonTitle={this.setButtonTitle}
                  setFlag={this.setFlag}
                  ref={this.roleListRef}
                />
              )}
            />
            <Route path="/user-dashboard">
              <UserListFilter
                queryData={(v) => {
                  //reset to page 1 when filtering
                  this.properties.pageIndex = 0;
                  this.queryData(v);
                }}
              />
              <div className="p-3">
                <Card>
                  <Table {...tableProps} />
                </Card>
              </div>
            </Route>
          </Switch>
        </div>
      </Dashboard>
    );
  }
}

function Table(props) {
  let columns = useMemo(() => props.columns, [props.columns]);
  let data = useMemo(() => props.data, [props.data]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        sortBy: useMemo(() => [{ id: "createdAt", desc: false }], []),
      },
      manualSortBy: true,
      manualPagination: true,
      pageCount: props.controlledPageCount,
    },
    useSortBy,
    usePagination
  );

  const { pagination } = props;
  React.useEffect(() => {
    pagination({ pageIndex, pageSize });
  }, [pagination, pageIndex, pageSize]);

  return (
    <>
      <table
        className="table table-striped rounded table-main"
        style={{ overflow: "hidden" }}
        {...getTableProps()}
      >
        <colgroup>
          <col span="1" width="18%"></col>
          <col span="1" width="18%"></col>
          <col span="1" width="18%"></col>
          <col span="1" width="18%"></col>
          <col span="1" width="18%"></col>
          <col span="1" width="10%"></col>
        </colgroup>
        <thead className="text-white" style={{ backgroundColor: "#0176C0" }}>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => (
                <th
                  {...column.getHeaderProps()}
                  className={
                    i + 1 === 4 ? "text-center fw-normal" : "fw-normal"
                  }
                  style={{
                    paddingLeft: "19px",
                    paddingTop: "14px",
                    paddingBottom: "15px",
                  }}
                >
                  {column.canSort ? (
                    <div className="d-flex justify-content-start">
                      <div style={{ marginRight: "10px" }}>
                        {column.render("Header")}
                      </div>

                      <div style={{ fontSize: "8px", marginTop: "3px" }}>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <div
                              className="lh-1"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                column.toggleSortBy(false, false);
                                props.sort(false, column.id);
                              }}
                            >
                              &#9650;
                            </div>
                          ) : (
                            <div
                              style={{ marginTop: "7px", cursor: "pointer" }}
                              onClick={() => {
                                column.toggleSortBy(true, false);
                                props.sort(true, column.id);
                              }}
                            >
                              &#9660;
                            </div>
                          )
                        ) : (
                          <>
                            <div
                              className="lh-1"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                column.toggleSortBy(false, false);
                                props.sort(false, column.id);
                              }}
                            >
                              &#9650;
                            </div>
                            <div
                              className="lh-1"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                column.toggleSortBy(true, false);
                                props.sort(true, column.id);
                              }}
                            >
                              &#9660;
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div style={{ marginRight: "10px" }}>
                      {column.render("Header")}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, i) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{ paddingLeft: "19px" }}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="d-flex justify-content-between w-100 p-4">
        <div>
          Showing {page.length} of {props.count}
        </div>
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li
              className={!canPreviousPage ? "page-item disabled" : "page-item"}
            >
              <a
                onClick={() => gotoPage(0)}
                className="page-link"
                href="#"
                style={{ color: "#000" }}
                aria-label="Previous"
              >
                <span aria-hidden="true">&lt;</span>
              </a>
            </li>
            <li
              className={!canPreviousPage ? "page-item disabled" : "page-item"}
            >
              <a
                onClick={() => previousPage()}
                className="page-link"
                style={{ color: "#000" }}
                href="#"
                aria-label="Previous"
              >
                <span aria-hidden="true">&lt;</span>
              </a>
            </li>
            {pageOptions.map((num) => {
              const disabled = pageIndex === num;
              return (
                <li key={num} className="pagination-disabled">
                  <a
                    className="page-link"
                    style={{ color: "#000" }}
                    onClick={() => gotoPage(num)}
                    href="#"
                  >
                    {num + 1}
                  </a>
                </li>
              );
            })}
            <li className={!canNextPage ? "page-item disabled" : "page-item"}>
              <a
                onClick={() => nextPage()}
                className="page-link"
                style={{ color: "#000" }}
                href="#"
                aria-label="Next"
              >
                <span aria-hidden="true">&gt;</span>
              </a>
            </li>
            <li className={!canNextPage ? "page-item disabled" : "page-item"}>
              <a
                onClick={() => gotoPage(pageCount - 1)}
                className="page-link"
                style={{ color: "#000" }}
                href="#"
                aria-label="Next"
              >
                <span aria-hidden="true">&gt;</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}

export default UserDashboard;
