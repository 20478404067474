import React, { Component, useMemo } from 'react';
import { usePagination, useSortBy, useTable } from 'react-table';
import { Button, Card, Dropdown, FormControl, InputGroup } from 'react-bootstrap';
import DropdownItem from 'react-bootstrap/DropdownItem';
import DropdownToggle from 'react-bootstrap/DropdownToggle';
import DropdownMenu from 'react-bootstrap/DropdownMenu';
import { BsFile } from 'react-icons/bs';
import { FiSearch } from 'react-icons/fi';

import './PlatformList.css';
import AddPlatform from './AddPlatform';
import HTTP from '../../shared/utility/http';
import { isUserHasAccess } from '../../shared/utility/utils';

const CREATE_PLATFORM_PERMISSION = {
	scope: 'uam.platforms.create',
	resource: '/v2/platforms',
};

class PlatformList extends Component {
	constructor(props) {
		super(props);
		this.state = { data: [], grantDdlValue: 'Grant Type', searchValue: undefined, addedRoleSuccessfully: false };
		this.addPlatformModalRef = React.createRef();
		this.pagination = this.pagination.bind(this);
		this.sort = this.sort.bind(this);
		this.handleGrantDdlChange = this.handleGrantDdlChange.bind(this);
		this.handleSearchChange = this.handleSearchChange.bind(this);
		this.addedRoleSuccessfully = this.addedRoleSuccessfully.bind(this);
		this.properties = {
			pageIndex: 0,
			pageSize: 25,
			sort: undefined,
			column: undefined,
			grant_type: undefined,
			search: undefined,
		};
		this.columns = [
			{
				Header: 'Date and Time Created',
				accessor: 'created_at',
				sortInverted: true,
			},
			{
				Header: 'Platform Name',
				accessor: 'name',
			},
			{
				Header: 'Client ID',
				accessor: 'key',
				disableSortBy: true,
			},
			{
				Header: 'Grant Type',
				accessor: 'grant_type',
				disableSortBy: true,
			},
		];

		this.dropdownValues = [
			{
				key: 'none',
				value: 'None',
			},
			{
				key: 'authorization_code',
				value: 'Authorization Code',
			},
		];
	}

	componentDidMount() {
		this.queryData();
	}

	sort(desc, column) {
		this.properties.sort = desc ? 'desc' : 'asc';
		this.properties.column = column;
		this.queryData();
	}

	pagination({ pageIndex, pageSize }) {
		this.properties.pageIndex = pageIndex;
		this.properties.pageSize = pageSize;
		// this.queryData();
	}

	handleGrantDdlChange(e) {
		const value = e.target.innerHTML;
		this.setState({ grantDdlValue: value });
		this.properties.grant_type = value === 'Authorization Code' ? 'authorization_code' : '';
		this.queryData();
	}

	addedRoleSuccessfully(success) {
		this.setState({ addedRoleSuccessfully: success }, () => {
			this.queryData();
		});
	}

	handleSearchChange(e) {
		const value = e.target.value.trim();
		if (value.length >= 3) {
			this.properties.search = value;
			this.queryData();
		} else if (this.properties.search) {
			this.properties.search = undefined;
			this.queryData();
		}
	}

	queryData() {
		let params = {};
		let grant_type = this.properties.grant_type;
		let search = this.properties.search;

		const self = this;

		if (this.properties.sort && this.properties.column) {
			params['sort'] = this.properties.sort;
			params['sort_by'] = this.properties.column;
		}
		if (grant_type) {
			if (grant_type !== '') {
				params['grant_type'] = grant_type;
			}
		}
		if (search) {
			params['search'] = search;
		}

		HTTP.get('/v2/platforms', { params })
			.then((resp) => {
				const clients = resp.data.data.map((item) => {
					if (item.grant_type === 'password') {
						item.grant_type = 'Password';
					} else if (item.grant_type === 'authorization_code') {
						item.grant_type = 'Authorization Code';
					} else if (item.grant_type === 'client_credential') {
						item.grant_type = 'Client Credential';
					}
					return item;
				});
				self.setState({
					data: clients,
					totalCount: resp.data.data.length,
					controlledPageCount: Math.ceil(resp.data.data.length / 25),
				});
			})
			.catch(function (error) {
				console.log(error);
			});
	}

	addPlatformModalRef = ({ toggleModal }) => {
		this.showModal = toggleModal;
	};

	onAddPlatformClick = async () => {
		if (await isUserHasAccess(CREATE_PLATFORM_PERMISSION)) {
			this.addPlatformModalRef.current.toggleModal();
		} else {
			window.isAccessDenied = true;
		}
	};

	render() {
		const tableProps = {
			columns: this.columns,
			data: this.state.data,
			controlledPageCount: this.state.controlledPageCount,
			pagination: this.pagination,
			count: this.state.totalCount,
			sort: this.sort,
		};
		return (
			<div className='container-fluid'>
				<div className='search-grp'>
					<div>
						<label className='search-grp-text header-item'>Filter:</label>
					</div>
					<div className='ddl-container'>
						<Dropdown ic='grant-ddl'>
							<DropdownToggle id='grant-ddl-toggle'>
								{this.state.grantDdlValue}
								<span style={{ float: 'right' }}>
									<BsFile />
								</span>
							</DropdownToggle>
							<DropdownMenu id='grant-ddl-menu'>
								{this.dropdownValues.map((item) => {
									return (
										<DropdownItem id='grant-item' key={item.key} onClick={this.handleGrantDdlChange}>
											{item.value}
										</DropdownItem>
									);
								})}
							</DropdownMenu>
						</Dropdown>
					</div>
					<div className='search-container'>
						<InputGroup id='search-input-group'>
							<InputGroup.Prepend>
								<InputGroup.Text id='search-input-group-text'>
									<FiSearch className='search-icon' />
								</InputGroup.Text>
							</InputGroup.Prepend>
							<FormControl placeholder='Platform Name & Client ID' onChange={this.handleSearchChange} />
						</InputGroup>
					</div>
					<div className='add-btn-container'>
						<AddPlatform ref={this.addPlatformModalRef} addedRoleSuccessfully={this.addedRoleSuccessfully} />
						<Button id='add-btn' onClick={this.onAddPlatformClick}>
							Add Platform
						</Button>
					</div>
				</div>
				<div className='p-3'>
					<Card>
						<Table {...tableProps} />
					</Card>
				</div>
			</div>
		);
	}
}

function Table(props) {
	let columns = useMemo(() => props.columns, [props.columns]);
	let data = useMemo(() => props.data, [props.data]);
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		// Get the state from the instance
		state: { pageIndex, pageSize },
	} = useTable(
		{
			columns,
			data,
			initialState: {
				pageIndex: 0,
			},
			manualSortBy: true,
			manualPagination: true,
			pageCount: props.controlledPageCount,
		},
		useSortBy,
		usePagination
	);

	React.useEffect(() => {
		props.pagination({ pageIndex, pageSize });
	}, [props.pagination, pageIndex, pageSize]);

	return (
		<>
			<table className='table table-striped rounded' id='platform-table' style={{ overflow: 'hidden' }} {...getTableProps()}>
				<colgroup>
					<col span='1' width='25%' />
					<col span='1' width='25%' />
					<col span='1' width='25%' />
					<col span='1' width='25%' />
				</colgroup>
				{/*Table header block*/}
				<thead className='text-white' style={{ backgroundColor: '#0176C0', height: '48px' }}>
					{headerGroups.map((headerGroup) => (
						<tr {...headerGroup.getHeaderGroupProps()}>
							{headerGroup.headers.map((column, i) => (
								<th
									{...column.getHeaderProps()}
									className={i + 1 === 4 ? 'text-center fw-normal' : 'fw-normal'}
									style={{ paddingLeft: '19px', paddingTop: '14px', paddingBottom: '15px' }}
								>
									<div className='d-flex justify-content-start'>
										<div style={{ marginRight: '10px' }}>{column.render('Header')}</div>
										{column.canSort ? (
											<div style={{ fontSize: '8px', marginTop: '3px' }}>
												{column.isSorted ? (
													column.isSortedDesc ? (
														<div
															className='lh-1'
															style={{ cursor: 'pointer' }}
															onClick={() => {
																column.toggleSortBy(false, false);
																props.sort(false, column.id);
															}}
														>
															&#9650;
														</div>
													) : (
														<div
															style={{ marginTop: '7px', cursor: 'pointer' }}
															onClick={() => {
																column.toggleSortBy(true, false);
																props.sort(true, column.id);
															}}
														>
															&#9660;
														</div>
													)
												) : (
													<>
														<div
															className='lh-1'
															style={{ cursor: 'pointer' }}
															onClick={() => {
																column.toggleSortBy(false, false);
																props.sort(false, column.id);
															}}
														>
															&#9650;
														</div>
														<div
															className='lh-1'
															style={{ cursor: 'pointer' }}
															onClick={() => {
																column.toggleSortBy(true, false);
																props.sort(true, column.id);
															}}
														>
															&#9660;
														</div>
													</>
												)}
											</div>
										) : (
											''
										)}
									</div>
								</th>
							))}
						</tr>
					))}
				</thead>
				{/*Table Body*/}
				<tbody {...getTableBodyProps()}>
					{page.map((row, i) => {
						prepareRow(row);
						return (
							<tr {...row.getRowProps()}>
								{row.cells.map((cell, i) => {
									return (
										<td {...cell.getCellProps()} style={{ paddingLeft: '19px' }}>
											{cell.render('Cell')}
										</td>
									);
								})}
							</tr>
						);
					})}
				</tbody>
			</table>
			{/*Pagination block*/}
			<div className='d-flex justify-content-between w-100 p-4'>
				<div>
					Showing {page.length} of {props.count}
				</div>
			</div>
		</>
	);
}

export default PlatformList;
