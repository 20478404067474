import React, { Component } from 'react';
import { Image, Button } from 'react-bootstrap';
import avatar from '../../images/ic-avatar.png';
import './Nametag.css';
import { Link } from 'react-router-dom';
import { FiLogOut } from 'react-icons/fi';
import Logout from '../Logout/Logout';
import { UserInfoUtil } from '../../shared/utility/session';

class Nametag extends Component {
	constructor() {
		super();
		this.state = {};
		this.logOutRef = React.createRef();
		this.logOut = this.logOut.bind(this);
	}

	componentDidMount() {
		const userInfo = UserInfoUtil.get(null);
		if (userInfo) {
			const { firstname, lastname, username } = userInfo;
			this.setState({
				fullname: firstname + ' ' + lastname,
				username: username,
			});
		}
	}

	logOut() {
		this.logOutRef.current.logOut();
	}

	render() {
		return (
			<div className='nametag'>
				<div>
					<Image className='avatar' src={avatar} />
				</div>
				<Link onClick={() => this.logOut()} className='profile-name'>
					{this.state.fullname} ({this.state.username})
					<div className='dropdown-content'>
						<Logout ref={this.logOutRef} />
						<Button className='logOutButton' onClick={this.logOut}>
							<FiLogOut /> Logout
						</Button>
					</div>
				</Link>
			</div>
		);
	}
}

export default Nametag;
