import React, { Component } from 'react';
import { Modal, Button, Col } from 'react-bootstrap';
import './AccessModal.css';
import Row from 'react-bootstrap/Row';

export const AccessModal = ({ showModal, handleClose }) => {
	return (
		<Modal id='access-denied-modal' show={showModal} onHide={handleClose} style={{ marginTop: '125px' }}>
			<Modal.Body>
				<Row>
					<Col className='text-center'>
						<div className='m-2'>
							<h5 className='mb-5' style={{ fontWeight: 600 }}>
								Access Denied
							</h5>
							<div className='mb-5'>
								<span>Sorry, you do not have access to this function. Please contact your Admin to grant access permissions. Thanks!</span>
							</div>
						</div>
					</Col>
				</Row>
				<Row>
					<Col className='text-center'>
						<Button bsPrefix='session-btn cancel m-2 p-2 mb-4' onClick={handleClose}>
							Close
						</Button>
					</Col>
				</Row>
			</Modal.Body>
		</Modal>
	);
};
