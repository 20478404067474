import {Component} from "react"

class AuditTrailSettings extends Component {

    render() {
        return (
            <div>
                Audit Trial Settings
            </div>
        );
    }
}

export default AuditTrailSettings;