import React, {Component} from 'react';
import {Modal, Button, Col} from 'react-bootstrap';
import "./IdleTimeOutModal.css"
import Row from "react-bootstrap/Row";

export const IdleTimeOutModal = ({showModal, handleClose, handleLogoutClick, remainingTime}) => {

    return (
        <Modal show={showModal} onHide={handleClose}>
            <Modal.Body>
                <Row>
                    <Col className="text-center m-2">
                        <div className="m-2">
                            <h5 className="m-1">Your session is about to expire!</h5>
                            <div className="m-3">
                                    <span>You will be logged out in <span style={{color: '#F26122'}}>{remainingTime}</span> seconds.
                                        <br/> Do you want to stay logged in?</span>
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-center">
                        <Button bsPrefix="session-btn cancel m-2 p-2" onClick={handleClose}>
                            Yes, Keep Me Logged In
                        </Button>
                        <Button bsPrefix="session-btn add m-2 p-2" onClick={handleLogoutClick}>
                            No, Logout
                        </Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}
