import React, { Component } from "react";
import { Row, Button } from "react-bootstrap";

class AddDataButton extends Component {
  render() {
    return (
      <div className="d-flex justify-content-center">
        <Row className="col-12">
          <Button style={{ height: "36px", backgroundColor: "#F26122", border: 0, font: "normal normal normal 11px/17px Poppins-Regular" }}
            onClick={this.props.onClick}>
            {this.props.buttonTitle}
          </Button>
        </Row>
      </div>
    );
  }
}

export default AddDataButton;
