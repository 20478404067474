import {
	deleteSessionStorage,
	getSessionStorage,
	setSessionStorage,
} from './storage';

function sessionStorageUtilBuilder(key, defaultValue = undefined) {
	return {
		set: (value) => setSessionStorage(key, value),
		get: (dv) => getSessionStorage(key, dv !== undefined ? dv : defaultValue),
		delete: () => deleteSessionStorage(key),
	};
}

export const TokenUtil = sessionStorageUtilBuilder('token');
export const RefreshTokenUtil = sessionStorageUtilBuilder('refreshToken');
export const TerminalUtil = sessionStorageUtilBuilder('terminal', {});
export const UserInfoUtil = sessionStorageUtilBuilder('userInfo', {});
export const BranchUtil = sessionStorageUtilBuilder('branch', []);
