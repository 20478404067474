import { UserInfoUtil } from './session';
import HTTP from './http';

export const isSuperAdmin = () => {
	const userInfo = UserInfoUtil.get(null);
	if (userInfo && userInfo.uamRole === 'Super Admin') {
		return true;
	}

	return false;
};

export const isAdmin = () => {
	const userInfo = UserInfoUtil.get(null);
	if (userInfo && (userInfo.uamRole === 'Super Admin' || userInfo.uamRole === 'Admin')) {
		return true;
	}

	return false;
};

export const isUserHasAccess = async (permission) => {
	let scopes = JSON.parse(localStorage.getItem('scopes'));
	if (!scopes) {
		try {
			const { data: scopes } = await HTTP.get(process.env.REACT_APP_ACCOUNTS_API + '/v1/oauth/scopes');
			localStorage.setItem('scopes', JSON.stringify(scopes.data));
		} catch (error) {
			console.log(error);
			if (error.response.status === 403) {
				window.isAccessDenied = true;
			}
		}
		scopes = JSON.parse(localStorage.getItem('scopes'));
	}
	return !!scopes.find((obj) => obj.scope === permission.scope /*&& obj.resource === permission.resource*/);
};
