import React, {Component, useMemo} from "react";
import {Modal} from "react-bootstrap";
import HTTP from "../../shared/utility/http";
import './ViewPlatforms.css'
import {usePagination, useSortBy, useTable} from "react-table";

class ViewPlatforms extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            data: [],
            controlledPageCount: undefined,
            pagination: {
                totalPages: undefined,
                page: 1,
                total: undefined
            },
            btnPages: []

        }
        this.queryData = this.queryData.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.selectPlatforms = this.selectPlatforms.bind(this);

        this.columns = [
            {
                Header: "Platform",
                width: "75px",
                accessor: "name"
            }
        ]
    }
    queryData() {
        let params = {
            sort_by: 'name',
            sort: 'ASC'
          }
        HTTP.get('/v2/platforms',{params: params}).then(resp => {
            let platformResp = resp
            this.setState({
                data: platformResp.data.data
            })
        }).catch((err) => {
            console.log(err)
        })
    }
    toggleModal() {
        this.setState({showModal: !this.state.showModal})
        if (!this.state.showModal) {
            this.queryData()
        }
    }

    selectPlatforms = (e, row) => {
        this.props.setActive(e)
        const selectedPlatform = row.allCells[0].value
        let match = this.state.data.filter(platforms => selectedPlatform === platforms.name)[0]
        let platforms = this.props.platforms
        this.props.setPlatforms(platforms.concat({value: match.key, label: match.name}))
        this.toggleModal()
    }

    render() {
        const tableProps = {
            columns: this.columns,
            data: this.state.data,
            selectPlatforms: this.selectPlatforms
        };
        return (
            <div>
                <Modal
                    id="view-all-branch"
                    keyboard={false}
                    scrollable={true}
                    onHide={this.toggleModal}
                    show={this.state.showModal}>
                    <Modal.Body>
                        <CustomTable {...tableProps} />
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}

function CustomTable(props) {
    let columns = useMemo(() => props.columns, [props.columns]);
    let data = useMemo(() => props.data, [props.data]);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
            },
            manualSortBy: true,
            manualPagination: true,
            pageCount: props.controlledPageCount,
        },
        useSortBy,
        usePagination
    );

    return (
        <div style={{overflow: 'auto'}}>
            <table
                className="table table-striped rounded"
                id="platforms-table"
                style={{overflow: "hidden"}}
                {...getTableProps()}
            >
                <colgroup>
                    <col span="1" width="100%"/>
                </colgroup>
                {/*Table header block*/}
                <thead className="text-white" style={{
                    backgroundColor: "#0176C0",
                    height: "48px", position: 'sticky', top: 0
                }}>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column, i) => (
                            <th
                                {...column.getHeaderProps()}
                                className={
                                    i + 1 === 4 ? "text-center fw-normal" : "fw-normal"
                                }
                                style={{paddingLeft: "19px", paddingTop: "14px", paddingBottom: "15px"}}
                            >
                                <div className="d-flex justify-content-start">
                                    <div style={{marginRight: "10px"}}>
                                        {column.render("Header")}
                                    </div>
                                </div>
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                {/*Table Body*/}
                <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps({onClick: (e) => props.selectPlatforms(e, row)})}>
                            {row.cells.map((cell, i) => {
                                return (
                                    <td {...cell.getCellProps()}
                                        style={{paddingLeft: "19px"}}>{cell.render("Cell")}</td>
                                );
                            })}
                        </tr>
                    );
                })}
                </tbody>
            </table>
        </div>
    );
}

export default ViewPlatforms