import React, { useEffect, useState } from 'react';
import { Switch, Route } from 'react-router-dom';
import IdleTimer from 'react-idle-timer';
import LandingPage from '../landingpage/Landingpage';
import { IdleTimeOutModal } from './IdleTimeOutModal';
import Logout from '../Logout/Logout';
import HTTP from '../../shared/utility/http';
import { AccessModal } from '../accessHandler/AccessModal';
import { RefreshTokenUtil, TokenUtil } from '../../shared/utility/session';

class SessionTimeout extends React.Component {
	constructor(props) {
		super(props);
		this.timeout = process.env.REACT_APP_TOKEN_SESSION_EXPIRY * 1000 * 60;
		this.state = {
			remaining: this.timeout,
			showModal: false,
			showAccessModal: false,
		};

		this.idleTimer = null;
		this.handleClose = this.handleClose.bind(this);
		this.handleLogout = this.handleLogout.bind(this);
		this.handleLogoutClick = this.handleLogoutClick.bind(this);
		this.handleCloseAccessModal = this.handleCloseAccessModal.bind(this);

		this.allowedEvents = [];
		this.timer = null;
		this.logoutRef = React.createRef();
		this.landingPageRef = React.createRef();
		this.apiCallCounter = 0;
	}

	componentDidMount() {
		this.setState({
			remaining: this.idleTimer.getRemainingTime(),
		});

		this.timer = setInterval(() => {
			const seconds = parseInt(
				Math.round(this.idleTimer.getRemainingTime() / 1000)
			);

			if (this.apiCallCounter != window.apiCall) {
				this.idleTimer.reset();
				this.apiCallCounter = window.apiCall;
			}

			if (this.idleTimer.isIdle && seconds <= 60) {
				this.setState({
					showModal: true,
					remaining: seconds,
				});
			}

			if (this.idleTimer.isIdle && seconds === 0) {
				this.handleLogout();
			}
			//Access Denied Checker
			if (window.isAccessDenied) {
				console.log(window.isAccessDenied);
				window.isAccessDenied = false;
				this.setState({
					showAccessModal: true,
				});
			}
		}, 1000);
	}

	handleClose() {
		this.refreshToken();
		this.idleTimer.reset();
		this.setState({ showModal: false });
	}

	handleLogout() {
		this.landingPageRef.current.isSessionExpired(true, () => {
			this.logoutRef.current.logOut();
		});
		this.setState({ showModal: false });
		clearTimeout(this.timer);
	}

	handleLogoutClick() {
		this.logoutRef.current.logOut();
		this.setState({ showModal: false });
		clearTimeout(this.timer);
	}

	refreshToken() {
		const refreshToken = RefreshTokenUtil.get();
		const req = JSON.stringify({
			grant_type: 'refresh_token',
			refresh_token: refreshToken,
			client_id: process.env.REACT_APP_APP_KEY,
			scope: '',
		});
		HTTP.post(process.env.REACT_APP_ACCESS_TOKEN_URL, req)
			.then((data) => {
				TokenUtil.set(data['data']['access_token']);
				RefreshTokenUtil.set(data['data']['refresh_token']);
			})
			.catch((error) => {
				console.error(error);
			});
	}

	handleCloseAccessModal() {
		this.setState({ showAccessModal: false });
	}

	render() {
		return (
			<>
				<IdleTimer
					ref={(ref) => {
						this.idleTimer = ref;
					}}
					element={document}
					debounce={250}
					timeout={this.timeout}
					events={this.allowedEvents}
					crossTab={{
						emitOnAllTabs: true,
					}}
				/>

				<IdleTimeOutModal
					showModal={this.state.showModal}
					handleClose={this.handleClose}
					handleLogoutClick={this.handleLogoutClick}
					remainingTime={this.state.remaining}
				/>

				<AccessModal
					showModal={this.state.showAccessModal}
					handleClose={this.handleCloseAccessModal}
				/>

				<div className=''>
					<Logout ref={this.logoutRef} />
					<Switch>
						<Route
							path='/'
							render={(props) => (
								<LandingPage {...props} ref={this.landingPageRef} />
							)}
						/>
					</Switch>
				</div>
			</>
		);
	}
}

export default SessionTimeout;
