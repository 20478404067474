import { gql } from "@apollo/client";

export const ON_CREATE_BATCHUSER = gql`
  subscription MySubscription($ack_id: String!) {
    onCreateBatchUser(ack_id: $ack_id) {
      ack_id
      error_details
      error_records_count
      progress
      success_records_count
      total_records
    }
  }
`;
