import React, { Component } from "react";
import {
  Alert,
  Button,
  Col,
  Form,
  InputGroup,
  Modal,
  ModalBody,
  ModalTitle,
  Row,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import "../../shared/styles/floating-label.css";
import "./userDetails.css";
import instance from "../../shared/utility/http";
import instanceBfa from "../../shared/utility/httpBFA";
import AsyncSelect from "react-select/async";
import AuditPreview from "../audit/AuditPreview";
import ModalHeader from "react-bootstrap/ModalHeader";
import moment from "moment-timezone";
import validator from "validator/es";
import CustomDatePicker from "../../shared/components/CustomDatePicker";
import { RiErrorWarningFill } from "react-icons/ri";
import _ from "underscore";
import { BsX } from "react-icons/bs";
import { RiSearchLine } from "react-icons/ri";
import { components } from "react-select";

import ViewBranches from "./ViewBranches";
import { UserInfoUtil } from "../../shared/utility/session";
import { getSessionStorage } from "../../shared/utility/storage";
import { isAdmin } from "../../shared/utility/utils";
import UserRoleListModal from "./UserRoleListModal";
import { isUserHasAccess } from "../../shared/utility/utils";

const EDIT_USER_PERMISSION_DEV = {
  scope: "uam.users.details",
  resource: "/v2/users/{userId}",
};
const EDIT_USER_PERMISSION_STG = {
  scope: "uam.users.details.update",
  resource: "/v2/users/{userId}",
};
class UserDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showConfirm: false,
      showResetPass: false,
      isEditMode: false,
      verifiedEditable: false,
      unverifiedEditable: false,
      isSaveDisabled: false,
      isBFA: false,
      multiSelectDisabled: true,
      id: "",
      hash: "",
      username: "",
      email: "",
      lastname: "",
      firstname: "",
      middlename: "",
      mobileNumber: "",
      status: "",
      isOtp: 0,
      type: "",
      allowDuplicate: false,
      emailVerifiedAt: "",
      mobileNumberVerifiedAt: "",
      createdAt: "",
      roleId: 0,
      roleName: "",
      branches: [],
      branchNames: [],
      jobTitle: "",
      division: "",
      department: "",
      employmentStartDate: "",
      userLogs: [],
      editDetails: {},
      otpEdit: 0,
      firstnameValid: true,
      lastnameValid: true,
      middlenameValid: true,
      emailValid: true,
      mobile_numberValid: true,
      divisionValid: true,
      job_titleValid: true,
      departmentValid: true,
      employment_start_dateValid: true,
      roleList: [],
      totalRoleCount: 0,
      userBranch: [],
      showBranch: true,
      uamPlatform: "",
      faPlatform: "Bayad FA",
      ipPlatform: "Bayad IP",
      userInfo: UserInfoUtil.get(),
      updateEnabled: false,
      alertDisplay: {
        show: false,
        message: "",
        style: "",
      },
      resetReason: "",
      resetValue: "",
      showRoleList: false,
    };
    this.columns = [
      {
        Header: "Date & Time",
        width: "160px",
        accessor: ({ created_at }) => {
          return moment(created_at).format("MM/DD/YYYY hh:mm:ss A");
        },
      },
      {
        Header: "Action",
        width: "75px",
        accessor: "action",
      },
      {
        Header: "Old Values",
        width: "150px",
        accessor: "old_values",
      },
      {
        Header: "New Values",
        width: "150px",
        accessor: "new_values",
      },
      {
        Header: "Logged By",
        width: "90px",
        accessor: "user_id",
      },
      {
        Header: "Source IP",
        width: "80px",
        accessor: "source_ip",
      },
      {
        Header: "Reason",
        width: "65px",
        accessor: "note",
      },
    ];
    this.timer = null;

    this.toggleModal = this.toggleModal.bind(this);
    this.toggleResetPassModal = this.toggleResetPassModal.bind(this);
    this.resetPass = this.resetPass.bind(this);
    this.toggleConfirmModal = this.toggleConfirmModal.bind(this);
    this.queryData = this.queryData.bind(this);
    this.changeEditMode = this.changeEditMode.bind(this);
    this.saveEdit = this.saveEdit.bind(this);
    this.onEditChange = this.onEditChange.bind(this);
    this.validateField = this.validateFields.bind(this);
    this.toggle = this.toggle.bind(this);
    this.toggleAllowDuplicate = this.toggleAllowDuplicate.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.showResetPassAlert = this.showResetPassAlert.bind(this);
    this.toggleAlert = this.toggleAlert.bind(this);
    this.reload = this.reload.bind(this);
    this.loadAfterChange = this.loadAfterChange.bind(this);
    this.setBranches = this.setBranches.bind(this);
    this.branchHeight = this.branchHeight.bind(this);
    this.handleRoleSelect = this.handleRoleSelect.bind(this);
    this.handleBlock = this.handleBlock.bind(this);

    this.roleRef = React.createRef();
    this.firstNameRef = React.createRef();
    this.lastNameRef = React.createRef();
    this.middleNameRef = React.createRef();
    this.emailRef = React.createRef();
    this.contactNumberRef = React.createRef();
    this.jobTitleRef = React.createRef();
    this.divisionRef = React.createRef();
    this.departmentRef = React.createRef();
    this.employmentRef = React.createRef();
    this.multiselectRef = React.createRef();
  }

  handleRoleSelect(role) {
    const inList = this.state.roleList.some((r) => r.name === role.name);
    const cb = () =>
      this.onEditChange({ target: { value: role.name, name: "role_id" } });
    if (!inList) {
      this.setState(
        ({ roleList }) => ({ roleList: roleList.concat(role) }),
        cb
      );
    } else {
      cb();
    }
  }

  toggleModal(status = "active") {
    this.setState({ showModal: !this.state.showModal, status: status }, () => {
      if (this.state.showModal) {
        this.queryData();
      } else {
        this.reload();
      }
    });
  }

  reload() {
    this.setState({
      showConfirm: false,
      isEditMode: false,
      verifiedEditable: false,
      unverifiedEditable: false,
      isSaveDisabled: false,
      id: "",
      hash: "",
      username: "",
      email: "",
      lastname: "",
      firstname: "",
      middlename: "",
      mobileNumber: "",
      status: "",
      isOtp: 0,
      type: "",
      allowDuplicate: false,
      emailVerifiedAt: "",
      mobileNumberVerifiedAt: "",
      createdAt: "",
      roleId: 0,
      roleName: "",
      branchNames: [],
      jobTitle: "",
      division: "",
      department: "",
      employmentStartDate: "",
      userLogs: [],
      editDetails: {},
      otpEdit: 0,
      firstnameValid: true,
      lastnameValid: true,
      middlenameValid: true,
      emailValid: true,
      mobile_numberValid: true,
      emailErrMsg: '',
      mobile_numberErrMsg: '',
      divisionValid: true,
      job_titleValid: true,
      departmentValid: true,
      employment_start_dateValid: true,
      roleList: [],
      userBranch: [],
      updateEnabled: false,
      alertDisplay: {
        show: false,
        message: "",
        style: "",
      },
      resetReason: "",
      resetValue: "",
    });
  }

  queryData() {
    const self = this;
    const roleList = instance.get("/v1/roles", {
      params: { sort: "ASC" },
    });
    const userPromise = instance.get("/v1/users/" + this.props.userId);
    const userLogsPromise = instance.get(`/v2/users/${this.props.userId}/logs`);

    const promises = [userPromise, userLogsPromise, roleList];

    if (this.state.isBFA) {
      const userBranch = instanceBfa.get(
        `/v2/users/${this.props.userHash}/branches`
      );

      promises.push(userBranch);
    }

    Promise.all(promises).then(async (resp) => {
      const employeeData = resp[0].data.data;

      const branchData = resp[3]?.data.data;

      const logs = resp[1].data.data.slice(0, 5).map((item) => {
        item.user_id = item.logged_by ? item.logged_by : employeeData.username;
        item.old_values = _.isEmpty(JSON.parse(item.old_values))
          ? "-"
          : item.old_values;
        item.new_values = _.isEmpty(JSON.parse(item.new_values))
          ? "-"
          : item.new_values;
        item.source_ip = _.isEmpty(item.source_ip) ? "-" : item.source_ip;
        item.note = _.isEmpty(item.note) ? "-" : item.note;
        return item;
      });
      const empStartDate =
        employeeData.type === "INTERNAL"
          ? moment(employeeData.employmentStartDate).format("MMMM/DD/YYYY")
          : "";
      self.setState(
        {
          ...employeeData,
          mobileNumber: employeeData.mobileNumber.substr(
            employeeData.mobileNumber.length - 10
          ),
          otpEdit: employeeData.isOtp,
          employmentStartDate:
            empStartDate === "" ? empStartDate : moment(empStartDate).toDate(),
          branchNames: branchData,
          userLogs: logs,
        },
        () => this.loadAfterChange()
      );

      const reducedList = resp[2].data.data.map((r) => {
        return { id: r.id, name: r.name };
      });

      const roleInList = reducedList.some((r) => r.id === employeeData.roleId);
      if (!roleInList) {
        const roleRes = await instance.get("/v1/roles", {
          params: { sort: "ASC", id: employeeData.roleId },
        });
        const role = roleRes.data.data[0];
        if (role) {
          reducedList.push({ id: role.id, name: role.name });
        }
      }

      this.setState({
        roleList: reducedList,
        totalRoleCount: resp[2].data.meta.total_count,
      });
    });
  }

  renderStatus() {
    if (this.state.emailVerifiedAt) {
      return <span className="user-verified">Verified User</span>;
    } else {
      return (
        <span className="user-not-verified">User is not yet verified</span>
      );
    }
  }

  resetValues() {
    this.multiselectRef.current.resetSelectedValues();
  }
  renderBlockBtn() {
    if (this.state.status == "Blocked") {
      return (
        <Button
          className="user-btn unblock-btn"
          style={{ width: "153px" }}
          disabled={this.state.isEditMode}
          onClick={() => this.handleBlock("unblock")}
        >
          Unblock User
        </Button>
      );
    } else {
      return (
        <Button
          className="user-btn block-btn"
          style={{ width: "153px" }}
          disabled={this.state.isEditMode}
          onClick={() => this.handleBlock("block")}
        >
          Block User
        </Button>
      );
    }
  }

  componentDidMount() {
    if (
      getSessionStorage("platformName", "").toUpperCase() ===
      "Bayad FA".toUpperCase()
    ) {
      this.setState({ isBFA: true });
    }
  }

  verifyPermission = async () => {
    if (this.state.isEditMode) {
      return true;
    } else {
      const [isHasAccessOnDev, isHasAccessOnStg] = await Promise.all([
        await isUserHasAccess(EDIT_USER_PERMISSION_DEV),
        await isUserHasAccess(EDIT_USER_PERMISSION_STG),
      ]);
      return isHasAccessOnDev || isHasAccessOnStg;
    }
  };

  changeEditMode = async (p) => {
    if (await this.verifyPermission()) {
      this.setState({
        editDetails: {},
        firstnameValid: true,
        lastnameValid: true,
        middlenameValid: true,
        divisionValid: true,
        job_titleValid: true,
        departmentValid: true,
        employment_start_dateValid: true,
        ...(p?.clearMobileEmailErrField &&  {
            emailValid: true,
            mobile_numberValid: true,
            emailErrMsg: '',
            mobile_numberErrMsg: ''
          }
        )
      });
      this.setState({ otpEdit: this.state.isOtp });
      this.loadAfterChange();

      if (this.state.emailVerifiedAt) {
        this.setState({
          verifiedEditable: !this.state.isEditMode,
          unverifiedEditable: !this.state.isEditMode,
        });
        if (this.state.multiSelectDisabled) {
          this.setState({
            multiSelectDisabled: false,
          });
        } else {
          this.setState({
            multiSelectDisabled: true,
          });
        }
      } else {
        this.setState({
          unverifiedEditable: !this.state.isEditMode,
        });
      }
      this.setState({
        isEditMode: !this.state.isEditMode,
      });
    } else {
      window.isAccessDenied = true;
    }
  };

  loadAfterChange() {
    this.roleRef.current.value = this.state.roleName;
    this.firstNameRef.current.value = this.state.firstname;
    this.lastNameRef.current.value = this.state.lastname;
    this.middleNameRef.current.value = this.state.middlename;
    this.emailRef.current.value = this.state.email;
    this.contactNumberRef.current.value = this.state.mobileNumber;
    if (this.jobTitleRef.current) {
      this.jobTitleRef.current.value = this.state.jobTitle;
      this.divisionRef.current.value = this.state.division;
      this.departmentRef.current.value = this.state.department;
      this.employmentRef.current.value = this.state.employmentStartDate;
    }
  }

  onEditChange(e) {
    let val = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    let name = e.target.name;

    let editDetailsCopy = { ...this.state.editDetails };
    if (name === "role_id") {
      if (val === "__SEEALL") {
        this.setState({ showRoleList: true });
        return;
      }
      this.setState({ roleName: val });
      editDetailsCopy["role_id"] = this.state.roleList
        .filter((role) => role.name === val)
        .map((r) => {
          return r.id;
        })[0];
    } else {
      editDetailsCopy[name] = val;
    }
    this.setState({ editDetails: editDetailsCopy });
    this.validateFields(name, val);
  }

  validateFields(name, value) {
    switch (name) {
      case "firstname":
        this.setState({
          firstnameValid: validator.isLength(value, { min: 1, max: 255 }),
        });
        break;
      case "lastname":
        this.setState({
          lastnameValid: validator.isLength(value, { min: 1, max: 255 }),
        });
        break;
      case "job_title":
        this.setState({
          job_titleValid: validator.isLength(value, { min: 1, max: 255 }),
        });
        break;
      case "department":
        this.setState({
          departmentValid: validator.isLength(value, { min: 1, max: 255 }),
        });
        break;
      case "division":
        this.setState({
          divisionValid: validator.isLength(value, { min: 1, max: 255 }),
        });
        break;
      case "middlename":
        this.setState({
          middlenameValid: validator.isLength(value, { min: 0, max: 255 }),
        });
        break;
      case "email":
        this.setState({ emailValid: validator.isEmail(value),
         emailErrMsg: 'Invalid email'
      });
        break;
      case "mobile_number":
        this.setState({
          mobile_numberValid:
            validator.isInt(value) &&
            validator.isLength(value, { max: 10, min: 10 }),
          mobile_numberErrMsg: 'Invalid mobile number'
        });
        break;
      case "employment_start_date":
        this.setState({
          employment_start_dateValid: validator.isDate(value, {
            format: "YYYY/DD/MM",
          }),
        });
        break;
      default:
        break;
    }
  }

  saveBranches() {
    instanceBfa.put(
      "/v2/users/" + this.props.userHash,
      JSON.stringify({ branches: this.state.branchNames.map((x) => x.id) })
    );
  }

  async saveEdit(event) {
    event.preventDefault();

    let editDetails = this.state.editDetails;

    // exclude otpEdit from editDetails, it has separate api call
    delete editDetails["otpEdit"];

    if (editDetails.employment_start_date) {
      const date = editDetails.employment_start_date;
      editDetails.employment_start_date = moment(date).format("MM/DD/YYYY");
    }
    let otpRes;
    let userRes;
    if (this.state.isOtp !== this.state.otpEdit) {
      const otp = this.state.otpEdit ? 1 : 0;
      await instance
        .put("/v1/users/" + this.props.userId + "/otp/" + otp)
        .then((resp) => {
          otpRes = resp.request.status;
        })
        .catch((err) => (otpRes = err));
    }
    if (!_.isEmpty(editDetails)) {
      await instance
        .put("/v2/users/" + this.props.userId, JSON.stringify(editDetails))
        .then((resp) => {
          userRes = resp.request.status;
        })
        .catch((err) => {
          userRes = err;
          const data = err?.response?.data
          if (data?.message === 'Validation Failed') {
            const errors = data?.errors;
            const keys = Object.keys(errors);
            keys.forEach((key) => {
              // for allow duplicate validation if checkbox is only changed
              if (key === 'allow_duplicate') {
                errors[key].forEach((msg) => { 
                  const _key = msg.includes('Mobile') ? 
                  'mobile_number' : 
                  msg.includes('Email') ? 'email' : null;

                  if (!_key) return

                  const isMobileKey = _key === 'mobile_number';
                  this.setState({ 
                    [`${_key}Valid`]: false, 
                    [`${_key}ErrMsg`]: 
                    `${msg}. ${isMobileKey 
                    ? '+63' + this.state.mobileNumber : this.state[_key]}` // get recently old or duplicate value before it resets
                  });
                }
              )}

              // for number and contact number field validation
              else if (errors[key][0].includes('already')) {
                this.setState({ 
                  [`${key}Valid`]: false, 
                  [`${key}ErrMsg`]: 
                    `${errors[key][0]}. ${key === 'mobile_number' 
                    ? '+63' + editDetails[key] : editDetails[key]}`
                });
              }
              else this.setState({ [`${key}Valid`]: false, [`${key}ErrMsg`]: errors[key][0] }); // get recently old value before it resets
            });
          }
        });
    }

    if (this.state.isBFA) {
      this.saveBranches();
    }
    let code = 0;
    this.toggleConfirmModal();
    if (
      otpRes !== undefined &&
      otpRes === 200 &&
      userRes !== undefined &&
      userRes === 200
    ) {
      code = 200;
    } else if (
      otpRes === undefined &&
      userRes !== undefined &&
      userRes === 200
    ) {
      code = 200;
    } else if (
      userRes === undefined &&
      otpRes !== undefined &&
      otpRes === 200
    ) {
      code = 200;
    } else {
      code = 400;
    }
    this.changeEditMode();
    this.queryData();
    this.showAlert(code);
  }

  setEmploymentDate(date) {
    let editDetailsCopy = { ...this.state.editDetails };
    editDetailsCopy.employment_start_date = date;
    this.setState({ editDetails: editDetailsCopy });
    this.validateFields("employment_start_date", date);
    this.setState({ employmentStartDate: date });
  }

  toggle(e, name) {
    this.setState({ [name]: e.target.checked });
    this.setState({ editDetails: { [name]: e.target.checked } });
  }

  toggleAllowDuplicate(e) {
    const val = e.target.checked 
    this.setState({ allowDuplicate: val });
    this.setState({ editDetails: { ...this.state.editDetails, allow_duplicate: val } });
  }

  toggleConfirmModal() {
    this.setState({ showConfirm: !this.state.showConfirm });
  }

  toggleResetPassModal() {
    this.setState({
      showResetPass: !this.state.showResetPass,
      resetValues: "",
      resetReason: "",
    });
  }

  async resetPass(event) {
    event.preventDefault();

    if (this.state.resetReason != "") {
      const body = {
        platform: getSessionStorage("platformHeaders"),

        passExpireDate: moment(new Date()).format("MM/DD/YYYY hh:mm:ss"),

        templateName: this.state.resetValue,

        reason: this.state.resetReason,

        recipientUserId: this.props.userId,
      };

      const response = await instance.post("/v2/email ", body);
      this.showResetPassAlert(response.status);
      this.toggleResetPassModal();
      this.queryData();
    }
  }

  showAlert(s) {
    if (s === 200) {
      let alertState = { ...this.state.alertDisplay };
      alertState.show = !alertState.show;
      alertState.message = "Your changes have been saved!";
      alertState.style = "alert-success";
      this.setState({ alertDisplay: alertState });
    } else {
      let alertState = { ...this.state.alertDisplay };
      alertState.show = !alertState.show;
      alertState.message = "Your changes were unsuccessful";
      alertState.style = "alert-error";
      this.setState({ alertDisplay: alertState });
    }
  }

  showResetPassAlert(s) {
    if (s === 200) {
      let alertState = { ...this.state.alertDisplay };
      alertState.show = !alertState.show;
      alertState.message =
        "Email for reset password has been sent to your email";
      alertState.style = "alert-success";
      this.setState({ alertDisplay: alertState });
    } else {
      let alertState = { ...this.state.alertDisplay };
      alertState.show = !alertState.show;
      alertState.message = "Failed to send password reset";
      alertState.style = "alert-error";
      this.setState({ alertDisplay: alertState });
    }

    this.timer = setTimeout(() => {
      this.toggleAlert();
    }, 8000);
  }

  toggleAlert() {
    let alertState = { ...this.state.alertDisplay };
    alertState.show = !alertState.show;
    this.setState({ alertDisplay: alertState });
    clearTimeout(this.timer);
  }

  setBranches(data) {
    this.setState({
      branchNames: data.map((x) => ({
        id: x.value,
        name: x.label,
      })),
      editDetails: {
        ...this.state.editDetails,
        branches: data.map((x) => x.label),
      },
    });
  }

  branchHeight() {
    let branchHeight = 90;
    let branchNameSize = 0;
    let maxWidth = 110;
    this.state.branchNames.map((size) => {
      branchNameSize += size.name.length;

      if (branchNameSize === maxWidth) {
        branchHeight += 30;
        maxWidth += 100;
      }
    });

    return branchHeight;
  }

  handleBlock(type) {
    this.props.emitBlock(
      {
        id: this.props.userId.id ? this.props.userId.id : this.props.userId,
        status: this.state.status,
        username: this.state.username,
      },
      type
    );
  }

  render() {
    const tableProps = {
      columns: this.columns,
      data: this.state.userLogs,
    };

    const dropdownResetReason = [
      {
        label: "New User",
        value: "new_user_reset_template",
      },
      {
        label: "Forgot Password",
        value: "forgot_password_reset_template",
      },
      {
        label: "Expired Password",
        value: "expired_password_reset_template",
      },
      {
        label: "Unblocked User Account",
        value: "unblocked_account_template",
      },
    ];

    return (
      <>
        <div>
          <Modal
            dialogClassName="user-detail"
            backdrop="static"
            keyboard={false}
            centered
            scrollable={true}
            show={this.state.showModal}
          >
            <Modal.Body className="user-body">
              <h3 className="user-profile">
                {this.state.firstname +
                  " " +
                  this.state.lastname +
                  "  (" +
                  this.state.username +
                  ") "}
                {this.renderStatus()}
              </h3>
              <Form className="input-block-height edit-user-form">
                <div className="d-flex flex-row align-items-center">
                  <div className="p-2">
                    <div className="otp-config">SMS OTP Configuration</div>
                  </div>
                  <div className="p-2">
                    <Form.Check
                      type="switch"
                      id="custom-switch"
                      className={
                        this.state.isEditMode ? "otp-switch" : "otp-switch-view"
                      }
                      disabled={!this.state.isEditMode}
                      checked={this.state.otpEdit}
                      onChange={(e)=> this.toggle(e, "otpEdit")}
                    />
                  </div>
                  <div className="ml-auto p-2">
                    <Button
                      className="user-btn"
                      style={{ width: "106px" }}
                      onClick={()=> this.changeEditMode({ clearMobileEmailErrField: true })}
                      disabled={this.state.isEditMode}
                    >
                      Edit
                    </Button>
                    <Button
                      className="user-btn"
                      disabled={this.state.isEditMode}
                    >
                      Check Audit Trail
                    </Button>
                    <Button
                      className="user-btn"
                      disabled={this.state.isEditMode || !isAdmin()}
                      onClick={this.toggleResetPassModal}
                    >
                      Reset Password
                    </Button>
                    {this.renderBlockBtn()}
                  </div>
                </div>
                <div>
                  <h4>UAM User Details</h4>
                </div>
                <Row>
                  <Col>
                    <div className="input-block ">
                      <Form.Control
                        id="username"
                        name="username"
                        type="text"
                        defaultValue={this.state.username}
                        value={this.state.username}
                        disabled
                      ></Form.Control>
                      <span className="placeholder">Username</span>
                    </div>
                  </Col>
                  <Col>
                    <div className="input-block">
                      <Form.Control
                        id="dateCreated"
                        name="dateCreated"
                        type="text"
                        defaultValue={this.state.createdAt}
                        disabled
                      ></Form.Control>
                      <span className="placeholder">Date Created</span>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="input-block">
                      <Form.Control
                        id="userType"
                        name="userType"
                        type="text"
                        disabled
                        defaultValue={this.state.type}
                      ></Form.Control>
                      <span className="placeholder">User Type</span>
                    </div>
                  </Col>
                  <Col>
                    <div className="input-block">
                      <Form.Control
                        id="userRole"
                        name="role_id"
                        style={{ paddingTop: 0, paddingBottom: 0 }}
                        as="select"
                        disabled={!this.state.verifiedEditable}
                        value={this.state.roleName}
                        ref={this.roleRef}
                        onChange={this.onEditChange}
                      >
                        {this.state.roleList.map((r) => {
                          return <option key={r.id}>{r.name}</option>;
                        })}
                        {this.state.totalRoleCount > 25 && (
                          <option value="__SEEALL">See All</option>
                        )}
                      </Form.Control>
                      <span className="placeholder">User Role</span>
                    </div>
                  </Col>
                </Row>
                {this.state.isBFA && (
                  <Row
                    style={
                      !this.state.isBFA
                        ? { display: "none" }
                        : { height: this.branchHeight(), width: "900" }
                    }
                  >
                    <Col
                      style={
                        !this.state.isBFA
                          ? { display: "none" }
                          : { height: this.branchHeight() }
                      }
                    >
                      <MultiSelectSearchField
                        placeholder="Branch Name"
                        branches={this.state.branchNames.map((data) => ({
                          value: data.id,
                          label: data.name,
                        }))}
                        setBranches={this.setBranches}
                        isDisabled={this.state.multiSelectDisabled}
                      />
                      
                    </Col>
                    { this.state.isBFA && 
                      <Col>
                        <Form.Check
                          name='allowDuplicate'
                          checked={this.state.allowDuplicate}
                          style={{ marginLeft: '12px', marginTop: '12px' }}
                          type='checkbox'
                          label='Allow duplicate email and contact no.'
                          onChange={this.toggleAllowDuplicate}
                          disabled={!this.state.unverifiedEditable}
                        />
                      </Col>
                    }
                  </Row>
                )}
                <Row>
                  <Col>
                    <div className="input-block">
                      <Form.Control
                        id="firstname"
                        name="firstname"
                        disabled={!this.state.verifiedEditable}
                        defaultValue={this.state.firstname}
                        type="text"
                        ref={this.firstNameRef}
                        onChange={this.onEditChange}
                      ></Form.Control>
                      <span className="placeholder">First Name</span>
                      <span
                        className={
                          this.state.firstnameValid
                            ? "invalid-feedback input-invalid"
                            : "hidden"
                        }
                      >
                        <RiErrorWarningFill />
                        Invalid First Name
                      </span>
                    </div>
                  </Col>
                  <Col>
                    <div className="input-block">
                      <Form.Control
                        id="middlename"
                        name="middlename"
                        disabled={!this.state.verifiedEditable}
                        defaultValue={this.state.middlename}
                        type="text"
                        ref={this.middleNameRef}
                        onChange={this.onEditChange}
                      ></Form.Control>
                      <span className="placeholder">Middle Name</span>
                      <span
                        className={
                          this.state.middlenameValid
                            ? "invalid-feedback input-invalid"
                            : "hidden"
                        }
                      >
                        <RiErrorWarningFill />
                        Invalid Middle Name
                      </span>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="input-block">
                      <Form.Control
                        id="lastname"
                        name="lastname"
                        disabled={!this.state.verifiedEditable}
                        defaultValue={this.state.lastname}
                        type="text"
                        ref={this.lastNameRef}
                        onChange={this.onEditChange}
                      ></Form.Control>
                      <span className="placeholder">Last Name</span>
                      <span
                        className={
                          this.state.lastnameValid
                            ? "invalid-feedback input-invalid"
                            : "hidden"
                        }
                      >
                        <RiErrorWarningFill />
                        Invalid Last Name
                      </span>
                    </div>
                  </Col>
                  <Col>
                    <div className="input-block">
                      <Form.Control
                        id="userStatus"
                        name="userStatus"
                        disabled
                        defaultValue={this.state.status}
                        type="text"
                      ></Form.Control>
                      <span className="placeholder">User Status</span>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="input-block">
                      <InputGroup className="mobile-input-group">
                        <InputGroup.Prepend className="mobile-prepend">
                          <InputGroup.Text
                            className="mobile-text"
                            style={{
                              background: "#fff",
                              borderRight: "#FFFFFF",
                            }}
                          >
                            +63
                          </InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control
                          id="contactNumber"
                          name="mobile_number"
                          className="mobile-form"
                          disabled={!this.state.unverifiedEditable}
                          defaultValue={this.state.mobileNumber}
                          type="text"
                          ref={this.contactNumberRef}
                          onChange={this.onEditChange}
                          style={{ borderLeft: "#FFFFFF", paddingLeft: 0 }}
                        ></Form.Control>
                        <span className="placeholder">
                          Contact Number (+63 xxx xxxxxxx)
                        </span>
                      </InputGroup>
                      <span
                        className={
                          this.state.mobile_numberValid
                            ? "invalid-feedback input-invalid"
                            : "hidden"
                        }
                      >
                        <RiErrorWarningFill />
                        { this.state?.mobile_numberErrMsg ||' Invalid Contact No.' }
                      </span>
                    </div>
                  </Col>
                  <Col>
                    <div className="input-block">
                      <Form.Control
                        id="emailAddress"
                        name="email"
                        disabled={!this.state.unverifiedEditable}
                        defaultValue={this.state.email}
                        type="text"
                        ref={this.emailRef}
                        onChange={this.onEditChange}
                      ></Form.Control>
                      <span className="placeholder">Email Address</span>
                      <span
                        className={
                          this.state.emailValid
                            ? "invalid-feedback input-invalid"
                            : "hidden"
                        }
                      >
                        <RiErrorWarningFill />
                        { this.state?.emailErrMsg ||' Invalid email' }
                      </span>
                    </div>
                  </Col>
                </Row>
                {this.state.type === "INTERNAL" ? (
                  <>
                    <Row>
                      <Col>
                        <div className="input-block">
                          <Form.Control
                            id="jobTitle"
                            name="job_title"
                            disabled={!this.state.verifiedEditable}
                            defaultValue={this.state.jobTitle}
                            type="text"
                            ref={this.jobTitleRef}
                            onChange={this.onEditChange}
                          ></Form.Control>
                          <span className="placeholder">Job Title</span>
                          <span
                            className={
                              this.state.job_titleValid
                                ? "invalid-feedback input-invalid"
                                : "hidden"
                            }
                          >
                            <RiErrorWarningFill />
                            Invalid Job Title
                          </span>
                        </div>
                      </Col>
                      <Col>
                        <div className="input-block">
                          <Form.Control
                            id="division"
                            name="division"
                            disabled={!this.state.verifiedEditable}
                            defaultValue={this.state.division}
                            type="text"
                            ref={this.divisionRef}
                            onChange={this.onEditChange}
                          ></Form.Control>
                          <span className="placeholder">Division</span>
                          <span
                            className={
                              this.state.divisionValid
                                ? "invalid-feedback input-invalid"
                                : "hidden"
                            }
                          >
                            <RiErrorWarningFill />
                            Invalid Division
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <div className="input-block">
                          <Form.Control
                            id="department"
                            name="department"
                            disabled={!this.state.verifiedEditable}
                            defaultValue={this.state.department}
                            onChange={this.onEditChange}
                            type="text"
                            ref={this.departmentRef}
                          ></Form.Control>
                          <span className="placeholder">Department</span>
                          <span
                            className={
                              this.state.departmentValid
                                ? "invalid-feedback input-invalid"
                                : "hidden"
                            }
                          >
                            <RiErrorWarningFill />
                            Invalid Department
                          </span>
                        </div>
                      </Col>
                      <Col>
                        <div className="view-user-modal-datepicker-div">
                          <CustomDatePicker
                            className="form-control"
                            id="employmentStartDate"
                            title="Employee Start Date"
                            ref={this.employmentRef}
                            dateFormat="MMMM dd, yyyy"
                            selected={this.state.employmentStartDate}
                            disabled={!this.state.verifiedEditable}
                            onChange={(date) => {
                              this.setEmploymentDate(date);
                            }}
                          />
                          <span className="placeholder date-lbl">
                            Employment Start Date
                          </span>
                          <span
                            className={
                              this.state.employment_start_dateValid
                                ? "invalid-feedback input-invalid"
                                : "hidden"
                            }
                          >
                            <RiErrorWarningFill />
                            Invalid Value
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </>
                ) : null}
              </Form>
              <div className="user-logs">
                <h6 className="log-header">Last 5 Actions</h6>
                <AuditPreview {...tableProps} />
              </div>
            </Modal.Body>
            <Modal.Footer className="user-footer">
              {!this.state.isEditMode ? (
                <Button
                  className="user-btn close-btn"
                  style={{ width: "140px" }}
                  onClick={this.toggleModal}
                >
                  Close
                </Button>
              ) : (
                <div>
                  <Button
                    className="user-btn"
                    style={{ width: "140px" }}
                    onClick={()=> this.changeEditMode({ clearMobileEmailErrField: true })}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="user-btn close-btn"
                    style={{ width: "140px" }}
                    onClick={this.toggleConfirmModal}
                    disabled={this.state.isSaveEnabled || !Object.keys(this.state.editDetails).length}
                  >
                    Save
                  </Button>
                </div>
              )}
            </Modal.Footer>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
                alignItems: "center",
              }}
            >
              <Alert
                show={this.state.alertDisplay.show}
                className="edit-user-alert"
                id={this.state.alertDisplay.style}
                onClose={this.toggleAlert}
              >
                <div>
                  {this.state.alertDisplay.message}
                  <span style={{ float: "right" }}>
                    <BsX onClick={this.toggleAlert} />
                  </span>
                </div>
              </Alert>
            </div>
          </Modal>
        </div>
        <Modal show={this.state.showConfirm} keyboard={false} centered>
          <ModalHeader style={{ border: "0 none" }}>
            <ModalTitle>Update User Details</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <p>This action will update the user details.</p>
            <p>Do you want to proceed?</p>
          </ModalBody>
          <Modal.Footer style={{ border: "0 none" }}>
            <Button
              className="user-btn"
              style={{ width: "140px" }}
              onClick={this.toggleConfirmModal}
            >
              Cancel
            </Button>
            <Button
              className="user-btn close-btn"
              style={{ width: "140px" }}
              onClick={this.saveEdit}
            >
              Update
            </Button>
          </Modal.Footer>
        </Modal>

        <UserRoleListModal
          show={this.state.showRoleList}
          onClose={() => this.setState({ showRoleList: false })}
          onSelect={this.handleRoleSelect}
        />

        <Modal
          show={this.state.showResetPass}
          keyboard={false}
          centered
          contentClassName="reset-modal-content"
        >
          <ModalHeader style={{ border: "0 none" }}>
            <ModalTitle>
              {this.state.firstname +
                " " +
                this.state.lastname +
                "  (" +
                this.state.username +
                ") "}
              {this.renderStatus()}
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            <div>
              <div>
                <h5>Password Reset</h5>
              </div>
              <div className="reset-div">
                <label>Reason:</label>
                <DropdownButton
                  id="dropdown-basic-button"
                  className="reset-dropdown"
                  title={
                    this.state.resetReason !== ""
                      ? this.state.resetReason
                      : "Select Reason"
                  }
                >
                  {dropdownResetReason
                    ? dropdownResetReason.map((item) => {
                        return (
                          <Dropdown.Item
                            onClick={() => {
                              this.setState({
                                resetReason: item.label,
                                resetValue: item.value,
                              });
                            }}
                          >
                            {item.label}
                          </Dropdown.Item>
                        );
                      })
                    : ""}
                </DropdownButton>
              </div>
              <div className="reset-message-div">
                {`A verification link will be sent to ` +
                  this.state.firstname +
                  `'s email (` +
                  this.state.email +
                  `) to reset his/her password.` +
                  ` Do you want to proceed?`}
              </div>
            </div>
          </ModalBody>
          <Modal.Footer className="reset-footer">
            <Button
              className="user-btn"
              style={{ width: "140px" }}
              onClick={this.toggleResetPassModal}
            >
              Cancel
            </Button>
            <Button
              className="user-btn close-btn"
              style={{ width: "140px" }}
              onClick={this.resetPass}
            >
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

class MultiSelectSearchField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      branches: [],
      error: undefined,
      boxError: undefined,
      isSeeAll: false,
    };
    this.setActive = this.setActive.bind(this);
    this.branchSearch = this.branchSearch.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.showMoreBranch = this.showMoreBranch.bind(this);
    this.viewBranchesRef = React.createRef();
  }

  handleChange(selectedOptions) {
    this.props.setBranches(selectedOptions);
    if (selectedOptions) {
      this.setState({ error: undefined });
    }
  }

  setActive(e) {
    if (this.props.branches.length === 0) {
      this.setState({
        active: !this.state.active,
      });
      if (e._reactName === "onBlur") {
        this.setState({
          boxError: true,
          error: {
            message: "You've entered an invalid value",
          },
        });
      }
    } else {
      this.setState({ error: undefined, boxError: undefined });
    }

    this.setState({ isSeeAll: false });
  }

  branchSearch = (inputValue, callback) => {
    if (
      inputValue.length > 2 &&
      inputValue.trim() !== null &&
      inputValue.trim() !== ""
    ) {
      let params = {
        search: inputValue,
      };
      instanceBfa
        .get("/v2/branches", { params: params })
        .then((resp) => {
          let branches = resp.data.data.map((b) => {
            return {
              value: b.id,
              label: b.name,
            };
          });
          if (branches.length > 10 + this.props.branches.length) {
            branches = branches.slice(0, 10 + this.props.branches.length);
            this.setState({ isSeeAll: !this.state.isSeeAll });
          }

          callback(branches);
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      callback([]);
    }
  };

  showMoreBranch() {
    this.viewBranchesRef.current.toggleModal();
  }

  viewBranchesRef = ({ toggleModal }) => {
    this.showModal = toggleModal;
  };
  render() {
    const { placeholder } = this.props;
    const CustomIndicator = () => {
      return <RiSearchLine className="branch-search-icon" />;
    };

    const DropdownIndicator = (props) => {
      return (
        <components.DropdownIndicator {...props}>
          <CustomIndicator />
        </components.DropdownIndicator>
      );
    };

    const MenuListFooter = ({ onClick }) => (
      <div>
        <center>
          <Button className="show-more-branches" onClick={onClick}>
            See all results
          </Button>
        </center>
      </div>
    );

    const MenuList = (props) => {
      const { MenuListFooter = null } = props.selectProps.components;
      if (this.state.isSeeAll == true) {
        return (
          <>
            <components.MenuList {...props}>
              {props.children}
              {MenuListFooter}
            </components.MenuList>
          </>
        );
      } else {
        return (
          <>
            <components.MenuList {...props}>
              {props.children}
            </components.MenuList>
          </>
        );
      }
    };

    const customStyle = {
      control: (styles, state) => ({
        ...styles,
        backgroundColor: "white",
        height: "auto",
        minHeight: 46,
        borderRadius: "4px",
        border: state.isFocused
          ? "1px solid #0077C0 !important"
          : "1px solid #DADCE0 !important" && state.isDisabled
          ? "1px solid #DADCE0 !important"
          : "1px solid black !important",
        boxShadow:
          state.isFocused || state.isActive ? "1px solid #0077C0" : "none",
      }),
      multiValue: (styles) => ({
        ...styles,
        background: "#E6F5FE 0% 0% no-repeat padding-box",
        border: "1px solid #0077C0",
        borderRadius: "4px",
        textAlign: "left",
        font: "normal normal normal 12px/18px Poppins-Regular",
        letterSpacing: "0px",
        color: "#3E3F42",
        opacity: 1,
      }),
      container: (styles) => ({ ...styles, border: "none", flex: 1 }),
      indicatorSeparator: () => ({ display: "none" }),
      indicatorsContainer: (provided) => ({ ...provided, height: "46px" }),
      input: (provided) => ({
        ...provided,
        style: { height: "20px", minHeight: "20px" },
      }),
      valueContainer: (provided, state) => ({
        ...provided,
        height: "auto",
        padding: "10px",
      }),
    };
    let errorPlaceholderStyle = this.state.error
      ? { color: "#D93025" }
      : { height: "auto" };
    let errorFieldStyle = this.state.boxError
      ? { border: "1px solid #D93025" }
      : {};

    return (
      <div
        className="input-block add-user-inputs add-user-select-branch"
        style={{ height: "4.5rem" }}
      >
        <AsyncSelect
          name={this.props.name}
          id="select-branch-id"
          placeholder=""
          components={{
            MenuList,
            DropdownIndicator,
            MenuListFooter: <MenuListFooter onClick={this.showMoreBranch} />,
          }}
          className={
            this.state.boxError
              ? "branch-select-error"
              : "branch-select-container"
          }
          classNamePrefix="branch-select"
          isMulti
          isClearable={false}
          style={errorFieldStyle}
          styles={customStyle}
          loadOptions={this.branchSearch}
          value={this.props.branches}
          onChange={this.handleChange}
          onFocus={this.setActive}
          onBlur={this.setActive}
          isDisabled={this.props.isDisabled}
        />
        <ViewBranches
          ref={this.viewBranchesRef}
          branches={this.props.branches}
          setBranches={this.props.setBranches}
          setActive={this.setActive}
        />
        <span
          className={
            !this.state.active ? "activeplaceholder placeholder" : "placeholder"
          }
          style={errorPlaceholderStyle}
        >
          {placeholder}
        </span>
        {this.state.error ? (
          <span style={{ fontSize: "0.7rem", color: "#D93025" }}>
            <RiErrorWarningFill /> {this.state.error.message}
          </span>
        ) : null}
      </div>
    );
  }
}

export default UserDetails;
