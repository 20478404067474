import { Modal, Button, ListGroup, Spinner } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import Pagination from '../../shared/components/Pagination';
import instance from '../../shared/utility/http';

function UserRoleListModal({ show = false, onClose, onSelect }) {
	const [page, setPage] = useState(1);
	const [roles, setRoles] = useState([]);
	const [count, setCount] = useState(0);
	const [loading, setLoading] = useState(false);

	const handleClose = () => {
		onClose && onClose();
	};

	const handleSelect = (value) => {
		onSelect && onSelect(value);
		handleClose();
	};

	const handlePageSelect = async (p) => {
		await fetchRoles(p);
		setPage(p);
	};

	const fetchRoles = async (page = 1) => {
		setLoading(true);
		const response = await instance.get('/v1/roles', {
			params: { sort: 'ASC', page },
		});

		const {
			data: {
				data,
				meta: { total_count },
			},
		} = response;

		setCount(total_count);
		setRoles(data);
		setLoading(false);
	};

	const reset = () => {
		setPage(1);
		setRoles([]);
		setCount(0);
		setLoading(false);
	};

	useEffect(() => {
		if (show) {
			fetchRoles();
		} else {
			reset();
		}
	}, [show]);

	return (
		<Modal show={show} backdrop='static' keyboard={false} centered scrollable>
			<Modal.Header>User Roles</Modal.Header>
			<Modal.Body className='user-body'>
				{loading ? (
					<div className='roleSpinnerContainer'>
						<Spinner
							animation='border'
							variant='warning'
							className='roleSpinner'
							size='lg'
						/>
					</div>
				) : (
					<>
						<ListGroup>
							{roles.map((role) => (
								<ListGroup.Item
									action
									key={role.name}
									onClick={() => handleSelect(role)}
								>
									{role.name}
								</ListGroup.Item>
							))}
						</ListGroup>
						<Pagination
							page={page}
							count={count}
							onPageSelect={handlePageSelect}
							className='paginationContainer'
						/>
					</>
				)}
			</Modal.Body>
			<Modal.Footer className='user-footer'>
				<Button className='user-btn close-btn' onClick={handleClose}>
					Close
				</Button>
			</Modal.Footer>
		</Modal>
	);
}

export default UserRoleListModal;
