import { Component } from "react";
import { Image, Row } from "react-bootstrap";
import "react-bootstrap";
import { Link, useRouteMatch } from "react-router-dom";
import "./SideBar.css";

import backArrow from "../../images/ic-arrowback1-black.svg";

class SideBar extends Component {
  render() {
    return (
      <div className="sidebar-main">
        <Row className="justify-content-md-center">
          <Image className="logo img-fluid" src={this.props.logo} />
        </Row>
        {this.props.uppernavExtras}
        <Row>
          <ul className="sidebar-list">
            {this.props.routes.map((val) => {
              return (
                <Link
                  key={val.key}
                  to={val.link}
                  style={{ textDecoration: "none" }}
                >
                  <LinkActive key={val.key} {...val} />
                </Link>
              );
            })}
          </ul>
        </Row>
        <div className="welcome">
          <Row>
            <ul className="sidebar-list">
              <Link to="/" style={{ textDecoration: "none" }}>
                <li className="sidebar-item">
                  <div id="icon">
                    <Image src={backArrow} />
                  </div>
                  <div id="title">Welcome Screen</div>
                </li>
              </Link>
            </ul>
          </Row>
        </div>
      </div>
    );
  }
}

function LinkActive({ link, icon, title }) {
  let match = useRouteMatch({
    path: link,
    exact: true,
  });
  return (
    <li className={match ? "sidebar-item active" : "sidebar-item"}>
      <div id="icon">
        <Image src={icon} />
      </div>
      <div id="title">{title}</div>
    </li>
  );
}

export default SideBar;
