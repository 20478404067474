export const hasSpecialCharacter = (v) => {
  return {
    value: new RegExp(/[/;=<>]/).test(v || ""),
    message: "Invalid Character(s)",
  };
};

export const validateMaxLength = (v, x) => {
  return {
    value: v.length <= x,
    message: `You can only input a max of ${x} character${x === 1 ? "" : "s"}`,
  };
};
