import React, { Component } from 'react';
import qs from 'qs';
import HTTP from '../../shared/utility/http';
import { Redirect } from 'react-router-dom';
import { TokenUtil } from '../../shared/utility/session';
import { clearSessionStorage } from '../../shared/utility/storage';

class Logout extends Component {
	constructor() {
		super();
		this.logOut = this.logOut.bind(this);
	}

	logOut() {
		const req = qs.stringify({ token: TokenUtil.get() });

		const logOutUrl = process.env.REACT_APP_ACCOUNTS_API + '/v1/oauth/logout';
		HTTP.post(logOutUrl, req)
			.then((data) => {
				clearSessionStorage();
				<Redirect to='/loginPage' />;
				window.location.reload();
				console.log('successfully removed');
			})
			.catch((error) => {
				console.error(error);
			});
	}

	render() {
		return <></>;
	}
}

export default Logout;
