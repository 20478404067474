import axios from 'axios';
import { logOut } from './logout';
import { TokenUtil } from './session';

if (window.apiCall == null) {
	window.apiCall = 0;
}

const BASE_URL = process.env.REACT_APP_BFA_API;

const instance = axios.create({
	baseURL: BASE_URL,
	headers: {
		'Content-Type': 'application/json',
	},
});

instance.interceptors.request.use(
	function (config) {
		const token = TokenUtil.get();
		if (token) {
			config.headers.Authorization = 'Bearer ' + token;
		}
		window.apiCall++;
		return config;
	},
	function (error) {
		return Promise.reject(error);
	}
);

instance.interceptors.response.use(
	(response) => {
		// Any status code that lie within the range of 2xx cause this function to trigger
		// Do something with response data
		return response;
	},
	async (error) => {
		// Any status codes that falls outside the range of 2xx cause this function to trigger
		// Do something with response error
		const e = { ...error };
		console.log('ERROR API-CLIENT', e.response);
		if (error.response.status === 401) {
			console.log('401 Logout');
			await logOut();
		}

		if (error.response.status === 403) {
			window.isAccessDenied = true;
		}
		return Promise.reject(error);
	}
);

export default instance;
