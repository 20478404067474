import React, {Component, useMemo} from "react";
import {Button, Col, Modal, ModalFooter} from "react-bootstrap";
import httpBFA from "../../shared/utility/httpBFA";
import './ViewBranches.css'
import {usePagination, useSortBy, useTable} from "react-table";
import Row from "react-bootstrap/Row";

class ViewBranches extends Component {

    constructor(props) {
        super(props);
        this.state = {
            showModal: false,
            data: [],
            controlledPageCount: undefined,
            pagination: {
                totalPages: undefined,
                page: 1,
                total: undefined
            },
            btnPages: []

        }
        this.queryData = this.queryData.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
        this.getNextPage = this.getNextPage.bind(this)
        this.getPreviousPage = this.getPreviousPage.bind(this)
        this.getPage = this.getPage.bind(this);
        this.getButtonPages = this.getButtonPages.bind(this);
        this.selectBranch = this.selectBranch.bind(this);

        this.columns = [
            {
                Header: "Branch Name",
                width: "75px",
                accessor: "name"
            },
            {
                Header: "Branch Code",
                width: "75px",
                accessor: "code"
            }
        ]
    }

    getNextPage() {
        let nextPage = this.state.pagination.page + 1
        this.queryData(nextPage)
    }

    getPreviousPage() {
        let nextPage = this.state.pagination.page - 1
        this.queryData(nextPage)
    }

    getPage(page) {
        if (this.state.pagination.page !== page) {
            this.queryData(page)
        }
    }

    getButtonPages(page, totalPages) {
        let pageButtons = []
        let start = page === 1 ? page : page - 1
        for (let x = start; x <= totalPages; x++) {
            pageButtons.push(x)
            if (pageButtons.length === 3) {
                break
            }
        }
        return pageButtons
    }

    queryData(page) {
        let exclude = this.props.branches.map((b) => {return b.value })
        let params = {
            page: page,
            search: this.props.searchValue,
            exclude: exclude.length > 0 ? exclude.toString() : undefined
        }
        httpBFA.get('/v2/branches', {params: params}).then(resp => {
            let branchResp = resp.data
            let meta = branchResp.metadata
            let btnPages = this.getButtonPages(meta.page, meta.totalPages)
            this.setState({
                data: branchResp.data,
                pagination: {
                    totalPages: meta.totalPages,
                    page: meta.page,
                    total: meta.total
                },
                btnPages: btnPages
            })
        }).catch((err) => {
            console.log(err)
        })
    }

    toggleModal() {
        this.setState({showModal: !this.state.showModal})
        if (!this.state.showModal) {
            this.queryData(0)
        }
    }

    selectBranch = (e, row) => {
        this.props.setActive(e)
        const selectedBranch = row.allCells[0].value
        let match = this.state.data.filter(branch => selectedBranch === branch.name)[0]
        let branches = this.props.branches
        branches.push({value: match.id, label: match.name})
        this.props.setBranches(branches)
        this.toggleModal()
    }

    render() {
        const tableProps = {
            columns: this.columns,
            data: this.state.data,
            selectBranch: this.selectBranch
        };
        return (
            <div>
                <Modal
                    id="view-all-branch"
                    keyboard={false}
                    scrollable={true}
                    onHide={this.toggleModal}
                    show={this.state.showModal}>
                    <Modal.Body>
                        <CustomTable {...tableProps} />
                    </Modal.Body>
                    <ModalFooter>
                        <div className="container-fluid" style={{display: 'flex', flexDirection: 'column'}}>
                            <Row>
                                <Col>
                                    <div
                                        style={{width: 'fit-content'}}>Showing {this.state.data.length} of {this.state.pagination.total}</div>
                                </Col>
                                <Col>
                                    <div style={{float: 'right'}}>
                                        <Button className="btn-nav-word branch-btn-left"
                                                disabled={this.state.pagination.page === 1}
                                                style={{borderRadius: '4px 0 0 4px'}}
                                                onClick={this.getPreviousPage}>Previous</Button>
                                        {this.state.btnPages.map((num) => {
                                            return (
                                                <Button className="btn-branch-nav"
                                                        key={num}
                                                        active={this.state.pagination.page === num}
                                                        onClick={() => {this.getPage(num)}}>{num}</Button>
                                            )
                                        })
                                        }
                                        <Button className="btn-nav-word branch-btn-right"
                                                disabled={this.state.data.length < 25 || this.state.pagination.page === this.state.pagination.totalPages}
                                                onClick={this.getNextPage}
                                                style={{borderRadius: '0 4px 4px 0'}}>Next</Button>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </ModalFooter>
                </Modal>
            </div>

        )
    }
}

function CustomTable(props) {
    let columns = useMemo(() => props.columns, [props.columns]);
    let data = useMemo(() => props.data, [props.data]);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
    } = useTable(
        {
            columns,
            data,
            initialState: {
                pageIndex: 0,
            },
            manualSortBy: true,
            manualPagination: true,
            pageCount: props.controlledPageCount,
        },
        useSortBy,
        usePagination
    );

    return (
        <div style={{overflow: 'auto'}}>
            <table
                className="table table-striped rounded"
                id="branches-table"
                style={{overflow: "hidden"}}
                {...getTableProps()}
            >
                <colgroup>
                    <col span="1" width="50%"/>
                    <col span="1" width="50%"/>
                </colgroup>
                {/*Table header block*/}
                <thead className="text-white" style={{
                    backgroundColor: "#0176C0",
                    height: "48px", position: 'sticky', top: 0
                }}>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column, i) => (
                            <th
                                {...column.getHeaderProps()}
                                className={
                                    i + 1 === 4 ? "text-center fw-normal" : "fw-normal"
                                }
                                style={{paddingLeft: "19px", paddingTop: "14px", paddingBottom: "15px"}}
                            >
                                <div className="d-flex justify-content-start">
                                    <div style={{marginRight: "10px"}}>
                                        {column.render("Header")}
                                    </div>
                                </div>
                            </th>
                        ))}
                    </tr>
                ))}
                </thead>
                {/*Table Body*/}
                <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                    prepareRow(row);
                    return (
                        <tr {...row.getRowProps({onClick: (e) => props.selectBranch(e, row)})}>
                            {row.cells.map((cell, i) => {
                                return (
                                    <td {...cell.getCellProps()}
                                        style={{paddingLeft: "19px"}}>{cell.render("Cell")}</td>
                                );
                            })}
                        </tr>
                    );
                })}
                </tbody>
            </table>
        </div>
    );
}

export default ViewBranches