import { Component } from "react";
import { Row } from "react-bootstrap";

import SideBar from "../SideBar/SideBar";
import "./Dashboard.css";

class Dashboard extends Component {
  render() {
    return (
      <div className="parent container-fluid">
        <Row>
          <div className="col-2 d-flex flex-column vh-100">
            <SideBar {...this.props.sideBarProps} />
          </div>
          <div
            className="col-10 d-flex flex-column"
            style={{ backgroundColor: "rgb(245,245,245)" }}
          >
            {this.props.children}
          </div>
        </Row>
      </div>
    );
  }
}

export default Dashboard;
