import React, {Component, useEffect} from "react";
import 'react-bootstrap';

import {Alert} from "react-bootstrap";
import styles from "./CustomModal.module.css";
import { BsX } from "react-icons/bs";

class CustomModal extends Component {
    constructor() {
        super();
        this.state = {
            showModal: false
        };
        this.timer = null;
        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidMount() {
        this.setState({showModal: this.props.display});
        this.timer = setTimeout(() => {
            this.toggleModal();
        }, 8000);
    }

    toggleModal() {
        this.setState({ showModal: !this.state.showModal }, () => {
            if(!this.state.showModal){
                clearTimeout(this.timer);
                this.props.setDisplayModal(false);
            }
        });
    }

    checkModalType(type) {
        switch (type) {
            case "warning":
                return "alerts-warning";
            case "failed":
                return "alerts-failed";
            default:
                return "alerts-success";
        }
    }

    render() {
        const modalProps = {
            show: this.state.showModal,
            modalType: this.checkModalType(this.props.type),
            message: this.props.message,
            toggleModal: this.toggleModal
        };

        return(
            <>
            <DisplayModal {...modalProps}/>
            </>
        );
    }
}

function DisplayModal(props) {
    const { showModal, modalType, message, toggleModal } = props;
    return(
        <div className={`${styles.alerts}`}>
            <Alert
                show={showModal}
                className={`${styles['alert']} ${styles[modalType]}`}>
                <div>
                    <span style={{'whiteSpace': 'normal', 'wordBreak': 'break-all'}}>{message}</span>
                    <span style={{float: "right"}}>
                                <BsX onClick={toggleModal}/>
                            </span>
                </div>
            </Alert>
        </div>
    );
}


export default CustomModal;
