import React, { Component, useMemo, useState } from 'react';
import { Button, Col, Form as BsForm, Modal, Row, Form } from 'react-bootstrap';
import './AddUserModal.css';
import HTTP from '../../shared/utility/http';
import httpBFA from '../../shared/utility/httpBFA';
import CustomDatePicker from '../../shared/components/CustomDatePicker';
import { RiErrorWarningFill } from 'react-icons/ri';
import InputMask from 'react-input-mask';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import validator from 'validator/es';
import AsyncSelect from 'react-select/async';
import { components } from 'react-select';
import ViewBranches from './ViewBranches';
import ViewPlatforms from './ViewPlatforms';
import { RiSearchLine, RiArrowDropDownFill } from 'react-icons/ri';
import { getSessionStorage } from '../../shared/utility/storage';
import UserRoleListModal from './UserRoleListModal';
import { property } from 'underscore';
import AddByBatch from './AddByBatch';

const ROLE_COUNT_THRESHOLD = 25;
class AddUserModal extends Component {
	constructor() {
		super();
		this.state = {
			userRoles: [],
			platformNames: [],
			display: false,
			isBFA: false,
			isUAM: false,
			platformSearch: null,
			uploadType: 'Single',
			isLoadingBatch: false,
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.toggleModal = this.toggleModal.bind(this);
		this.toggleLoadingModal = this.toggleLoadingModal.bind(this);
		this.toggleLoadingBatchOn = this.toggleLoadingBatchOn.bind(this);
		this.toggleLoadingBatchOff = this.toggleLoadingBatchOff.bind(this);
		this.handleRoleSelect = this.handleRoleSelect.bind(this);
		this.handleUploadType = this.handleUploadType.bind(this);
	}

	componentDidMount() {
		const platformName = getSessionStorage('platformName', '').toUpperCase();
		if (platformName === 'Bayad FA'.toUpperCase()) {
			this.setState({ isBFA: true });
		}
		if (platformName === 'Bayad UAM'.toUpperCase()) {
			this.setState({ isUAM: true });
		}
		HTTP.get('/v1/roles/?sort=ASC')
			.then((response) => {
				this.setState({
					userRoles: response.data.data.map((role) => {
						return { id: role.id, name: role.name };
					}),
					totalRoleCount: response.data.meta.total_count,
				});
			})
			.catch((error) => {
				console.log(error);
			});
		let params = {
			sort_by: 'name',
			sort: 'ASC',
		};
		HTTP.get('/v2/platforms', { params: params })
			.then((resp) => {
				this.setState({
					platformNames: resp.data.data.map((platform) => {
						return {
							value: platform.key,
							label: platform.name,
						};
					}),
				});
			})
			.catch((error) => {
				console.log(error);
			});
	}

	handleSubmit(data, e, setError, setIsSubmitting, branches, platforms) {
		let platformList = [];
		platforms.map((p) => {
			platformList.push(p.label);
		});
		let body = {
			email: data.email,
			lastname: data.lastname,
			firstname: data.firstname,
			middlename: data.middlename,
			mobileNumber: '63' + data.mobileNumber.replace('+63', '').split(' ').join('').trim(),
			type: data.type || 'INTERNAL',
			role: data.role,
			branches: platformList,
			allowDuplicate: data.allowDuplicate,
		};
		if (body.type === 'INTERNAL') {
			body = {
				...body,
				employmentDetails: {
					jobTitle: data.jobTitle,
					division: data.division,
					department: data.department,
					employmentStartDate: moment(data.employmentStartDate).format('YYYY-MM-DD HH:mm:ss'),
				},
			};
		} else {
			body = {
				...body,
				employmentDetails: {
					jobTitle: '',
					division: '',
					department: '',
					employmentStartDate: null,
				},
			};
		}
		if (branches.length > 0) {
			body.employmentDetails.branches = branches.map((b) => {
				return b.label;
			});
		}
		let user = {};
		HTTP.post('/v1/users/', JSON.stringify(body))
			.then((response) => {
				user = response.data.data;
				if (branches.length > 0) {
					const userBranches = branches.map((b) => {
						return b.value;
					});
					const body = {
						userHash: user.hash,
						branches: userBranches,
					};
					httpBFA
						.post('/v2/users', JSON.stringify(body))
						.then(() => {})
						.catch((error) => {
							console.log(error);
						});
				}
				this.props.parentCallback(`User "${user.username}" has been added successfully`, 'success');
				this.toggleModal();
			})
			.catch((error) => {
				if (error.response.status === 422) {
					if (Object.keys(error.response.data.errors).length !== 0) {
						const errors = error.response.data.errors;
						Object.keys(errors).forEach((key) => {
							setError(key, { type: 'manual', message: errors[key][0] });
						});
						return;
					}
				}
				this.toggleModal();
				this.props.parentCallback('User is not successfully added.', 'failed');
			})
			.then(() => {
				setIsSubmitting(false);
			});
	}

	toggleModal() {
		this.setState({ display: !this.state.display, uploadType: 'Single' });
	}

	toggleLoadingBatchOn(value = () => {}) {
		this.props.onViewAddBatchResultOpen(value);
	}

	toggleLoadingBatchOff() {
		this.setState({ isLoadingBatch: false });
	}

	toggleLoadingModal() {
		this.setState({ display: !this.state.display, uploadType: 'Single' });
		this.setState({ isLoadingBatch: true });
		this.props.onViewAddBatchResultOpen();
	}

	handleRoleSelect(role, cb = () => {}) {
		const inList = this.state.userRoles.some((r) => r.name === role.name);
		console.log({ inList });
		if (!inList) {
			this.setState(({ userRoles }) => ({ userRoles: userRoles.concat(role) }), cb);
		} else {
			cb();
		}
	}

	handleUploadType(e) {
		e == 'Single' ? this.setState({ uploadType: 'Single' }) : this.setState({ uploadType: 'Multi' });
	}

	render() {
		const userTypeOptions = ['Internal', 'External'];
		const userStatus = ['Active', 'Inactive', 'Temporary Blocked', 'Blocked'];

		return (
			<>
				<Modal size='lg' show={this.state.display} backdrop='static'>
					<Modal.Body>
						<h3 className='modal-title' style={{ display: 'inline-block', paddingRight: '400px' }} hidden={this.state.isLoadingBatch}>
							Add New User
						</h3>
						<RadioButton handleUploadType={this.handleUploadType} {...this.state} />
						<AddUserForm
							userRoles={this.state.userRoles}
							showSeeAllRoles={this.state.totalRoleCount > ROLE_COUNT_THRESHOLD}
							userTypeOptions={userTypeOptions}
							userStatus={userStatus}
							toggleModal={this.toggleModal}
							onSubmit={this.handleSubmit}
							isBFA={this.state.isBFA}
							isUAM={this.state.isUAM}
							loadPlatforms={this.state.platformNames}
							onRoleSelect={this.handleRoleSelect}
							uploadType={this.state.uploadType}
						/>
						<AddByBatch toggleModal={this.toggleModal} toggleLoadingBatchOn={this.toggleLoadingBatchOn} {...this.state} />
					</Modal.Body>
				</Modal>
			</>
		);
	}
}

function AddUserForm(props) {
	const {
		register,
		formState: { errors, touchedFields },
		control,
		watch,
		unregister,
		handleSubmit,
		setError,
		setValue,
		clearErrors,
	} = useForm({
		mode: 'onTouched',
		defaultValues: { status: 'INACTIVE' },
	});

	const allowDuplicate = watch('allowDuplicate');
	const userType = watch('type');
	const role = watch('role');
	const selectedRole = props.userRoles.find((x) => x.id === Number(role));
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [branches, setBranches] = useState([]);
	const [platforms, setPlatforms] = useState([]);
	const [platformHeight, setPlatformHeight] = useState(['76']);
	const [maxPlatform, setMaxPlatform] = useState(1);
	const [maxHeight, setMaxHeight] = useState(76);
	const [showRoleList, setShowRoleList] = useState(false);

	React.useEffect(() => {
		if (platforms.length === maxPlatform) {
			setMaxHeight(maxHeight + 20);
			setMaxPlatform(maxPlatform + 1);
			setPlatformHeight(maxHeight);
		}
	}, [platforms]);

	React.useEffect(() => {
		if (allowDuplicate) {
			['email', 'mobileNumber'].forEach((field) => {
				if (errors[field]?.type === 'manual') {
					clearErrors(field);
				}
			});
		}
	}, [allowDuplicate]);

	React.useEffect(() => {
		if (userType === 'EXTERNAL') {
			unregister('jobTitle');
			unregister('employmentStartDate');
			unregister('division');
			unregister('department');
			unregister('branches');
			unregister('platform');
		}
	}, [userType, unregister]);

	const userRoleRef = React.useRef(null);

	const userRoles = useMemo(() => {
		const roles = props.userRoles.slice();
		if (props.showSeeAllRoles) {
			roles.push({
				id: '__SEEALL',
				name: 'See All',
				onSelect: () => setShowRoleList(true),
				ignore: true,
			});
		}
		return roles;
	}, [props.showSeeAllRoles, props.userRoles]);

	const handleRoleSelect = (role) => {
		const cb = () => {
			setValue('role', role.id);
			if (userRoleRef && userRoleRef.current) {
				userRoleRef.current.value = role.id;
			}
		};

		if (props.onRoleSelect) {
			props.onRoleSelect(role, cb);
		} else {
			cb();
		}
	};

	return (
		<form
			noValidate
			onSubmit={handleSubmit((data, e) => {
				setIsSubmitting(true);
				props.onSubmit(data, e, setError, setIsSubmitting, branches, platforms);
			})}
			hidden={!(props.uploadType == 'Single')}
		>
			<Row style={{ marginTop: '20px' }}>
				<Col>
					<SelectField
						options={userRoles}
						placeholder='User Role'
						{...register('role', {
							required: { value: true, message: 'Invalid User Role' },
						})}
						ref={userRoleRef}
						error={errors?.role}
					/>
					<div style={selectedRole == null || selectedRole.name === 'Super Admin' ? {} : { height: platformHeight - 76, marginBottom: '5px' }}></div>
					<SelectField
						placeholder='User Type'
						options={props.userTypeOptions.map((data) => {
							return { id: data.toUpperCase(), name: data };
						})}
						{...register('type')}
						error={errors?.type}
						defaultValue='INTERNAL'
					/>
					<TextField
						placeholder='First name'
						{...register('firstname', {
							required: { value: true, message: 'Invalid First Name' },
							maxLength: { value: 255, message: 'Invalid First Name' },
						})}
						error={errors?.firstname}
					/>
					<TextField
						placeholder='Last name'
						{...register('lastname', {
							required: { value: true, message: 'Invalid Last Name' },
							maxLength: { value: 255, message: 'Invalid Last Name' },
						})}
						error={errors?.lastname}
					/>
					<NumberField
						placeholder='Contact Number (+63 xxx xxxxxxxxx)'
						{...register('mobileNumber', {
							required: { value: true, message: 'Invalid Contact No.' },
							validate: (value) => {
								return value.replace(/\D/g, '').length === 12 || 'Invalid Contact No.';
							},
						})}
						error={errors?.mobileNumber}
					/>
				</Col>
				<Col style={!props.isBFA && !props.isUAM && role === 'undefined' ? { paddingTop: '76px' } : null}>
					{props.isBFA && <MultiSelectSearchField placeholder='Branch Name' branches={branches} setBranches={setBranches} />}
					<div style={props.isBFA ? null : selectedRole == null || selectedRole.name === 'Super Admin' ? { height: '76px' } : { height: platformHeight, marginBottom: '5px' }}>
						{props.isUAM && selectedRole != null && selectedRole.name !== 'Super Admin' && (
							<MultiSearchPlatform placeholder='Platform' platforms={platforms} setPlatforms={setPlatforms} loadPlatforms={props.loadPlatforms} platformHeight={platformHeight} />
						)}
					</div>
					<SelectField
						options={props.userStatus.map((data) => {
							return { id: data.toUpperCase(), name: data };
						})}
						placeholder='User Status'
						{...register('status')}
						error={errors?.status}
						disabled={true}
						defaultValue='INACTIVE'
					/>
					<TextField
						placeholder='Middle name'
						{...register('middlename', {
							maxLength: { value: 255, message: 'Invalid Middle Name' },
						})}
						error={errors?.middlename}
					/>
					<TextField
						placeholder='Email Address'
						{...register('email', {
							required: { value: true, message: 'Invalid Email' },
							validate: (value) => {
								return validator.isEmail(value) || 'Invalid Email';
							},
						})}
						error={errors?.email}
					/>
					{props.isBFA && (
						<Form.Check style={{ marginLeft: '12px', marginTop: '12px' }} type='checkbox' label='Allow duplicate email and contact no.' {...register('allowDuplicate')} />
					)}
				</Col>
			</Row>
			{(userType == null || userType === 'INTERNAL' || userType === '') && (
				<Row>
					<Col>
						<TextField
							placeholder='Job Title'
							{...register('jobTitle', {
								required: { value: true, message: 'Invalid Job Title' },
								maxLength: { value: 255, message: 'Invalid Job Title' },
							})}
							error={errors?.jobTitle}
						/>
						<TextField
							placeholder='Department'
							{...register('department', {
								required: { value: true, message: 'Invalid Department' },
								maxLength: { value: 255, message: 'Invalid Department' },
							})}
							error={errors?.department}
						/>
					</Col>
					<Col>
						<TextField
							placeholder='Division'
							{...register('division', {
								required: { value: true, message: 'Invalid Division' },
								maxLength: { value: 255, message: 'Invalid Division' },
							})}
							error={errors?.division}
						/>
						<CustomDatePickerWrapper control={control} name='employmentStartDate' setValue={setValue} />
					</Col>
				</Row>
			)}
			<Row>
				<Col>
					<div className='input-block pt-2' style={{ fontSize: '0.7rem', fontWeight: 'bold' }}>
						<div>Note:</div>
						Username will be auto generated
					</div>
				</Col>
				<Col style={{ '--bs-gutter-x': '1.5rem' }}>
					<Row style={{ '--bs-gutter-x': '1.5rem' }}>
						<Col>
							<Button
								bsPrefix='platform-btn platform-btn-cancel'
								className='m-2'
								style={{
									display: 'block',
									width: '100%',
								}}
								onClick={() => {
									props.toggleModal();
								}}
							>
								Cancel
							</Button>
						</Col>
						<Col>
							<Button
								type='submit'
								bsPrefix='platform-btn platform-btn-add'
								className='m-2'
								block
								style={{ display: 'block', width: '100%' }}
								disabled={!canSubmit(touchedFields, errors, userType, platforms, selectedRole, props.isUAM) || isSubmitting}
							>
								Create
							</Button>
						</Col>
					</Row>
				</Col>
			</Row>
			<UserRoleListModal show={showRoleList} onClose={() => setShowRoleList(false)} onSelect={handleRoleSelect} />
		</form>
	);
}

function RadioButton(props) {
	function handleUploadType(e) {
		props.handleUploadType(e);
	}

	return (
		<Form style={{ display: 'inline-block' }} hidden={props.isLoadingBatch}>
			<Form.Check inline label='Single' name='savedType' type='radio' value='Single' defaultChecked='true' onChange={(e) => handleUploadType(e.target.value)} />
			<Form.Check inline label='Batch' name='savedType' type='radio' value='Multi' onChange={(e) => handleUploadType(e.target.value)} />
		</Form>
	);
}

function canSubmit(touchedFields, errors, userType, platforms, selectedRole, isUam) {
	if (selectedRole == undefined) {
		return false;
	}

	if (Object.keys(touchedFields).length === 0) {
		return false;
	}
	if (isUam && selectedRole.name === 'Admin' && platforms.length === 0) {
		return false;
	}
	const fields = Object.keys(touchedFields).filter((x) => x !== 'middlename' && x !== 'status' && x !== 'type');
	const employmentStartDateInput = document.querySelector('div.add-user-modal-datepicker input');
	const branches = document.querySelector('div.branch-select__value-container.branch-select__value-container--is-multi.branch-select__value-container--has-value');
	if (!fields.includes('branches') && branches !== null && branches.innerText !== null && branches.innerText !== '') {
		fields.push('branches');
	}
	if (!fields.includes('employmentStartDate') && employmentStartDateInput != null && employmentStartDateInput.value !== '') {
		fields.push('employmentStartDate');
	}

	const missing = [];

	if (userType === '' || userType === 'INTERNAL' || userType == null) {
		['role', 'firstname', 'lastname', 'email', 'mobileNumber', 'jobTitle', 'division', 'department', 'employmentStartDate'].forEach((x) => {
			if (!fields.includes(x)) {
				missing.push(x);
			}
		});
	} else {
		['role', 'firstname', 'lastname', 'email', 'mobileNumber'].forEach((x) => {
			if (!fields.includes(x)) {
				missing.push(x);
			}
		});
	}
	return missing.length === 0 && Object.keys(errors).length === 0;
}

class MultiSelectSearchField extends Component {
	constructor(props) {
		super(props);
		this.state = {
			active: false,
			branches: [],
			error: undefined,
			boxError: undefined,
			outFocus: true,
			searchValue: null,
			searchResults: false,
		};
		this.onBranchFocus = this.onBranchFocus.bind(this);
		this.onBranchBlur = this.onBranchBlur.bind(this);
		this.setActive = this.setActive.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.showMoreBranch = this.showMoreBranch.bind(this);
		this.branchSearch = this.branchSearch.bind(this);
		this.viewBranchesRef = React.createRef();
	}

	handleChange(selectedOptions) {
		this.props.setBranches(selectedOptions);
		this.setState({ branches: [] });
		if (selectedOptions) {
			this.setState({ error: undefined });
		}
	}

	onBranchBlur() {
		this.setState({
			outFocus: !this.state.outFocus,
			branches: [],
			searchResults: false,
		});
		if (this.props.branches.length === 0) {
			this.setState({
				boxError: true,
				error: { message: "You've entered an invalid value" },
				active: false,
			});
		} else {
			this.setState({ error: undefined, boxError: undefined });
		}
	}

	onBranchFocus() {
		this.setState({ outFocus: !this.state.outFocus });
		if (this.props.branches.length === 0) {
			this.setState({ active: !this.state.active, boxError: undefined });
		}
	}

	setActive() {
		if (this.props.branches.length > 0) {
			this.setState({ error: undefined, boxError: undefined, active: true });
		} else {
			this.setState({
				active: !this.state.active,
				error: undefined,
				boxError: undefined,
			});
		}
	}

	branchSearch = (inputValue, callback) => {
		this.setState({ searchValue: null });
		this.setState({ branches: [] });
		if (inputValue.length > 2 && inputValue.trim() !== null && inputValue.trim() !== '') {
			this.setState({ searchValue: inputValue });
			let params = {
				search: inputValue,
			};
			httpBFA
				.get('/v2/branches', { params: params })
				.then((resp) => {
					let data = resp.data.data;
					this.setState({ branches: data });
					let branches = data.map((b) => {
						return {
							value: b.id,
							label: b.name,
						};
					});
					if (branches.length > 10 + this.props.branches.length) {
						branches = branches.slice(0, 10 + this.props.branches.length);
					}
					if (data.length === 0) {
						this.setState({
							boxError: true,
							error: { message: "You've entered an invalid value" },
							searchResults: false,
						});
					} else {
						this.setState({
							boxError: false,
							error: undefined,
							searchResults: true,
						});
					}
					callback(branches);
				})
				.catch((e) => {
					console.log(e);
				});
		} else {
			callback([]);
		}
	};

	showMoreBranch() {
		this.viewBranchesRef.current.toggleModal();
	}

	viewBranchesRef = ({ toggleModal }) => {
		this.showModal = toggleModal;
	};

	render() {
		const { placeholder } = this.props;

		const CustomIndicator = () => {
			return <RiSearchLine className='branch-search-icon' />;
		};

		const DropdownIndicator = (props) => {
			return (
				<components.DropdownIndicator {...props}>
					<CustomIndicator />
				</components.DropdownIndicator>
			);
		};

		const MenuListFooter = ({ onClick }) =>
			this.state.branches.length > 10 && (
				<div>
					<center>
						<Button className='show-more-branches' onClick={onClick}>
							See all results
						</Button>
					</center>
				</div>
			);

		const MenuList = (props) => {
			const { MenuListFooter = null } = props.selectProps.components;
			return (
				<>
					<components.MenuList {...props}>
						{props.children}
						{MenuListFooter}
					</components.MenuList>
				</>
			);
		};
		const customStyle = {
			control: (provided, state) => ({
				...provided,
				backgroundColor: 'white',
				height: 46,
				minHeight: 46,
				borderRadius: '4px',
				width: '100%',
				border: 'none',
				boxShadow: state.isFocused || state.isActive ? '1px solid #0077C0' : '',
				tableLayout: 'fixed',
			}),
			multiValue: (provided) => ({
				...provided,
				background: '#E6F5FE 0% 0% no-repeat padding-box',
				border: '1px solid #0077C0',
				borderRadius: '4px',
				textAlign: 'left',
				font: 'normal norfalsemal normal 12px/18px Poppins-Regular',
				letterSpacing: '0px',
				color: '#3E3F42',
				opacity: 1,
			}),
			container: (provided) => ({ ...provided, border: 'none', flex: 1 }),
			indicatorSeparator: () => ({ display: 'none' }),
			indicatorsContainer: (provided) => ({ ...provided, height: '46px' }),
			input: (provided) => ({
				...provided,
				style: {
					height: '20px',
					minHeight: '20px',
					overflow: 'hidden',
					maxWidth: '100%',
				},
			}),
			valueContainer: (provided) => ({
				...provided,
				height: 'inherit',
				padding: '10px',
			}),
			menuList: (provided) => ({
				...provided,
				color: '#3B3B3B',
				font: 'normal normal normal 12px/24px Poppins-Regular',
			}),
			menu: (provided) => ({ ...provided, padding: '4px 12' }),
			option: (provided) => ({
				...provided,
				'&:hover': { color: '#F26122', cursor: 'pointer' },
			}),
		};
		let errorPlaceholderStyle = this.state.error ? { color: '#D93025' } : {};
		return (
			<div className='input-block add-user-inputs add-user-select-branch' style={{ height: '4.5rem' }}>
				<AsyncSelect
					name={this.props.name}
					id='select-branch-id'
					placeholder=''
					components={{
						MenuList,
						DropdownIndicator,
						MenuListFooter: <MenuListFooter onClick={this.showMoreBranch} />,
					}}
					className={
						this.state.boxError
							? 'branch-select-error'
							: this.state.active && !this.state.outFocus
							? 'branch-select-active'
							: this.state.outFocus && this.props.branches.length > 0
							? 'branch-select-has-value'
							: 'branch-select'
					}
					classNamePrefix='branch-select'
					isMulti
					menuIsOpen={this.state.searchResults}
					noOptionsMessage={(e) => (e.inputValue ? 'No options' : null)}
					isClearable={false}
					styles={customStyle}
					loadOptions={this.branchSearch}
					value={this.props.branches}
					onChange={this.handleChange}
					onFocus={this.onBranchFocus}
					onBlur={this.onBranchBlur}
				/>
				<ViewBranches
					ref={this.viewBranchesRef}
					branches={this.props.branches}
					setBranches={this.props.setBranches}
					setActive={this.setActive}
					searchValue={this.state.searchValue}
				/>
				<span className={this.state.active ? 'activeplaceholder placeholder' : 'placeholder'} style={errorPlaceholderStyle}>
					{placeholder}
				</span>
				{this.state.error ? (
					<span style={{ fontSize: '0.7rem', color: '#D93025' }}>
						<RiErrorWarningFill /> {this.state.error.message}
					</span>
				) : null}
			</div>
		);
	}
}

/**
 * MultiSearchPlatform
 *
 * For platform field
 */
class MultiSearchPlatform extends Component {
	constructor(props) {
		super(props);
		this.state = {
			active: false,
			platforms: [],
			error: undefined,
			boxError: undefined,
			searchValue: null,
			isSeeAll: false,
		};
		this.setActive = this.setActive.bind(this);
		this.loadPlatforms = this.loadPlatforms.bind(this);
		this.handleChange = this.handleChange.bind(this);
		this.showMorePlatforms = this.showMorePlatforms.bind(this);

		this.viewPlatformsRef = React.createRef();
	}

	handleChange(selectedOptions) {
		this.props.setPlatforms(selectedOptions);
		if (selectedOptions) {
			this.setState({ error: undefined });
		}
	}

	setActive(e) {
		if (this.props.platforms.length === 0) {
			this.setState({
				active: !this.state.active,
			});
			if (e._reactName === 'onBlur') {
				this.setState({
					boxError: true,
					error: {
						message: 'Please select a platform.',
					},
				});
			}
		} else {
			this.setState({ error: undefined, boxError: undefined });
		}

		if (this.props.loadPlatforms.length > 10) {
			this.setState({ platforms: this.props.loadPlatforms.slice(0, 10) });
			this.setState({ isSeeAll: true });
		} else {
			this.setState({ isSeeAll: false });
		}
	}

	loadPlatforms = (inputValue, callback) => {
		this.setState({
			searchValue: null,
			platforms: [],
			isSeeAll: false,
		});
		if (inputValue.length > 2 && inputValue.trim() !== null && inputValue.trim() !== '') {
			this.setState({ searchValue: inputValue });
			let params = {
				search: inputValue,
			};
			HTTP.get('/v2/platforms', { params: params })
				.then((resp) => {
					const data = resp.data.data;
					this.setState({ platforms: data });
					let platforms = data.map((b) => {
						return {
							value: b.key,
							label: b.name,
						};
					});
					if (platforms.length > 10 + this.state.platforms.length) {
						platforms = platforms.slice(0, 10 + this.state.platforms.length);
						this.setState({ isSeeAll: false });
					}
					this.setState({ platforms: platforms });
					callback(platforms);
				})
				.catch((e) => {
					console.log(e);
				});
		} else {
			callback([]);
		}
	};

	showMorePlatforms() {
		this.viewPlatformsRef.current.toggleModal();
	}

	viewPlatformsRef = ({ toggleModal }) => {
		this.showModal = toggleModal;
	};

	render() {
		const { placeholder } = this.props;
		const CustomIndicator = () => {
			return <RiArrowDropDownFill className='branch-search-icon' />;
		};

		const DropdownIndicator = (props) => {
			return (
				<components.DropdownIndicator {...props}>
					<CustomIndicator />
				</components.DropdownIndicator>
			);
		};

		const MenuListFooter = ({ onClick }) => (
			<div>
				<center>
					<Button className='show-more-platcforms' onClick={onClick}>
						See all results
					</Button>
				</center>
			</div>
		);
		const MenuList = (props) => {
			const { MenuListFooter = null } = props.selectProps.components;
			if (this.state.isSeeAll == true) {
				return (
					<>
						<components.MenuList {...props}>
							{props.children}
							{MenuListFooter}
						</components.MenuList>
					</>
				);
			} else {
				return (
					<>
						<components.MenuList {...props}>{props.children}</components.MenuList>
					</>
				);
			}
		};

		const customStyle = {
			control: (styles, state) => ({
				...styles,
				backgroundColor: 'white',
				borderRadius: '4px',
				border: state.isFocused ? '1px solid #0077C0 !important' : '1px solid #DADCE0 !important',
				boxShadow: state.isFocused || state.isActive ? '1px solid #0077C0' : 'none',
				height: 'auto',
			}),
			multiValue: (styles) => ({
				...styles,
				background: '#E6F5FE 0% 0% no-repeat padding-box',
				border: '1px solid #0077C0',
				borderRadius: '4px',
				textAlign: 'left',
				font: 'normal normal normal 12px/18px Poppins-Regular',
				letterSpacing: '0px',
				opacity: 1,
			}),
			container: (styles) => ({ ...styles, border: 'none', flex: 1 }),
			indicatorSeparator: () => ({ display: 'none' }),
			indicatorsContainer: (provided) => ({ ...provided, height: '46px' }),
			input: (provided) => ({
				...provided,
				style: { height: '20px', minHeight: 'auto' },
			}),
			valueContainer: (provided) => ({
				...provided,
				height: this.props.platforms.length < 2 ? '45px' : 'auto',
				marginBottom: '5px',
				padding: '10px',
			}),
			// style inside the dropdown
			option: (styles, state) => ({
				...styles,
				background: 'var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box',
				textAlign: 'left',
				letterSpacing: '0.11px',
				color: state.isSelected ? '#F26122 !important' : 'none',
				opacity: '1',
			}),
		};
		let errorPlaceholderStyle = this.state.error ? { color: '#D93025' } : {};
		let errorFieldStyle = this.state.boxError ? { border: '1px solid #D93025' } : {};

		return (
			<div className='input-block add-user-inputs add-user-select-platform' style={{ height: '4.5rem' }}>
				<AsyncSelect
					name={this.props.name}
					id='select-platform-id'
					placeholder=''
					components={{
						MenuList,
						DropdownIndicator,
						MenuListFooter: <MenuListFooter onClick={this.showMorePlatforms} />,
					}}
					className={this.state.boxError ? 'platform-select-error' : 'platform-select-container'}
					classNamePrefix='platform-select'
					isMulti
					hideSelectedOptions={false}
					defaultOptions={this.state.platforms}
					isClearable={false}
					value={this.props.platforms}
					style={errorFieldStyle}
					styles={customStyle}
					loadOptions={this.loadPlatforms}
					onChange={this.handleChange}
					onFocus={this.setActive}
					onBlur={this.setActive}
				/>
				<ViewPlatforms ref={this.viewPlatformsRef} platforms={this.props.platforms} setPlatforms={this.props.setPlatforms} setActive={this.setActive} />
				<span className={this.state.active ? 'activeplaceholder placeholder' : 'placeholder'} style={errorPlaceholderStyle}>
					{placeholder}
				</span>
				{this.state.error ? (
					<span style={{ fontSize: '0.7rem', color: '#D93025' }}>
						<RiErrorWarningFill /> {this.state.error.message}
					</span>
				) : null}
			</div>
		);
	}
}

class TextField extends Component {
	render() {
		const { error, placeholder, ...register } = this.props;
		const errorFieldStyle = error ? { border: '1px solid #D93025' } : {};
		const errorPlaceholderStyle = error ? { color: '#D93025' } : {};
		return (
			<div className='input-block add-user-inputs' style={{ height: '4.5rem' }}>
				<BsForm.Control {...register} type='text' required style={errorFieldStyle} />
				<span className='placeholder' style={errorPlaceholderStyle}>
					{placeholder}
				</span>
				{error ? (
					<span style={{ fontSize: '0.7rem', color: '#D93025' }}>
						<RiErrorWarningFill /> {error.message}
					</span>
				) : null}
			</div>
		);
	}
}

const SelectField = React.forwardRef(({ error, placeholder, disabled, options, defaultValue, ...register }, ref) => {
	const errorFieldStyle = error ? { border: '1px solid #D93025' } : {};
	const errorPlaceholderStyle = error ? { color: '#D93025' } : {};

	const handleChange = (event) => {
		const match = options.find((o) => o.id === event.target.value);
		if (match && match.ignore) {
			match.onSelect && match.onSelect(register);
			if (ref) {
				ref.current.value = '';
			}
			return;
		}
		register.onChange(event);
	};

	return (
		<div className='input-block add-user-inputs' style={{ height: '4.5rem' }}>
			<select
				{...register}
				onChange={handleChange}
				required
				className='platform-select add-user-modal-select'
				style={errorFieldStyle}
				disabled={disabled}
				defaultValue={defaultValue}
				ref={ref}
			>
				<option value=''></option>
				{options.map((option) => {
					return (
						<option key={option.id} value={option.id}>
							{option.name}
						</option>
					);
				})}
			</select>
			<span className='placeholder' style={errorPlaceholderStyle}>
				{placeholder}
			</span>
			{error ? (
				<span style={{ fontSize: '0.7rem', color: '#D93025' }}>
					<RiErrorWarningFill /> {error.message}
				</span>
			) : null}
		</div>
	);
});

function CustomDatePickerWrapper({ control, name, setValue }) {
	let [active, setActive] = React.useState(false);
	return (
		<div className='input-block add-user-modal-datepicker add-user-inputs'>
			<Controller
				name={name}
				control={control}
				render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => {
					const errorPlaceholderStyle = error ? { color: '#D93025' } : {};
					return (
						<>
							<CustomDatePicker
								className={error ? 'datepicker-error form-control' : 'form-control'}
								title='Employee Start Date'
								required
								onBlur={onBlur}
								selected={value}
								onChange={(date) => {
									setValue(name, date, {
										shouldValidate: true,
										shouldDirty: true,
									});
									onChange(date);

									if (date && date.length !== 0) {
										setActive(true);
									} else {
										setActive(false);
									}
								}}
							/>
							{error ? (
								<span style={{ fontSize: '0.7rem', color: '#D93025' }}>
									<RiErrorWarningFill /> Invalid Value
								</span>
							) : null}
							<span className={active ? 'activeplaceholder placeholder' : 'placeholder'} style={errorPlaceholderStyle}>
								Employment Start Date
							</span>
						</>
					);
				}}
				rules={{ required: true }}
			/>
		</div>
	);
}

class NumberField extends Component {
	render() {
		const { error, placeholder, ...register } = this.props;
		const errorFieldStyle = error ? { border: '1px solid #D93025' } : {};
		const errorPlaceholderStyle = error ? { color: '#D93025' } : {};
		return (
			<div className='input-block add-user-inputs' style={{ height: '4.5rem' }}>
				<InputMask className='form-control' required style={errorFieldStyle} mask='+6\3 999 999 9999' {...register} />
				<span className='placeholder' style={errorPlaceholderStyle}>
					{placeholder}
				</span>
				{error ? (
					<span style={{ fontSize: '0.7rem', color: '#D93025' }}>
						<RiErrorWarningFill /> {error.message}
					</span>
				) : null}
			</div>
		);
	}
}

export default AddUserModal;
