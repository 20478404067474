import React from "react";
import {Modal} from "react-bootstrap";

import './policy.css';

function Policy() {
    return (
        <>
            {/* see React Bootstrap Modal */}
            <div className="modalMain">
                <Modal.Header closeButton>
                    <Modal.Title>
                        <div className="modalTitle">
                            USER ACCESS MANAGEMENT POLICY
                        </div>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body style={{
                    maxHeight: 'calc(100% - 100px)',
                    overflowY: 'auto',
                    paddingTop: "1rem",
                    paddingRight: "31px",
                    paddingBottom: "1rem",
                    paddingLeft: "1rem"
                }}>
                    <div className="modalBody">
                        {/*page 1 */}
                        <>
                            <p className="modalHeader1">
                                3. General Requirements
                            </p>
                            <p className="modalHeader2">
                                3.1 User Registration
                            </p>
                            <p className="modalSection" id="registrationSection">
                                For new CBCI employees, access to CBCI systems and services shall be controlled through a
                                formal user registration process beginning with a formal notification from HR or from a
                                user’s line manager, with below initial user information:<br/>
                                • New user (employee) name <br/>
                                • Job title of the new employee and department/division <br/>
                                • Start date <br/>
                                • Services required (e.g. default services are: Email access, Internet access, etc.) <br/>
                            </p>
                            <p className="modalHeader2 newModalSection">
                                3.2 Account Creation
                            </p>
                            <div className="modalSection numberedItems" id="creationSection">
                                <table>
                                    <tbody>
                                    <tr>
                                        <td className="numBullet">
                                            3.2.1
                                        </td>
                                        <td>
                                            Account shall be created only for authorized users with legitimate business need
                                            to access CBCI computing resources, which include, but are not limited to,
                                            systems, applications, and devices.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="numBullet">
                                            3.2.2
                                        </td>
                                        <td>
                                            Access to CBCI systems and services shall only be given through the provision of
                                            a unique user account and complex password following the Password Standards
                                            described in Section 3.5
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="numBullet">
                                            3.2.3
                                        </td>
                                        <td>
                                            The designated Custodian shall review the access request based on the approved
                                            and defined access control matrix.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="numBullet">
                                            3.2.4
                                        </td>
                                        <td>
                                            Once the access request is reviewed and validated, the Custodian shall provide
                                            the requested access.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="numBullet">
                                            3.2.5
                                        </td>
                                        <td>
                                            Where possible, the CBCI Cybersecurity Department shall set an expiration date
                                            for user accounts to be monitored and enforced. More specifically,
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="modalSection modalSubSection">
                                <table>
                                    <tbody>
                                    <tr>
                                        <td className="numBullet">
                                            3.2.5.1
                                        </td>
                                        <td>
                                            User accounts assigned to vendors, contractors or any third party shall be
                                            granted with a maximum period of one (1) year. As such, twice (2x) a year access
                                            certification shall be performed to review and attest the vendor access.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="numBullet">
                                            3.2.5.2
                                        </td>
                                        <td>
                                            When temporary access is required, such as but not limited to, visitors, guests,
                                            auditors, where access is seasonal in nature, user accounts shall be set to
                                            expire according to the validity period, but with a maximum period of one (1)
                                            year.
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <p className="modalHeader2 newModalSection">
                                3.3 Authentication
                            </p>
                            <div className="modalSection numberedItems">
                                <table>
                                    <tbody>
                                    <tr>
                                        <td className="numBullet">
                                            3.3.1
                                        </td>
                                        <td>
                                            Where applicable, user authentication shall be via integration to, but not
                                            limited to the, Active Directory, single password implementation, Radius or
                                            TACACS+ for network devices, and or other centralized user authentication
                                            solution3.2.2 Access to CBCI systems and services shall only be given through
                                            the provision of a unique user account and complex password following the
                                            Password Standards described in Section 3.5
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="numBullet">
                                            3.3.2
                                        </td>
                                        <td>
                                            Authentication of users shall use multi-factor authentication (MFA), where
                                            applicable, especially for administrator or console level access, and a
                                            risk-based MFA for systems processing personal data.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="numBullet">
                                            3.3.3
                                        </td>
                                        <td>
                                            Alternative authentication mechanisms that do not rely on a unique User ID and
                                            password shall be formally approved by both CBCI Cybersecurity Department and
                                            CBCI IT Operations
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <p className="modalHeader2 newModalSection">
                                3.4 Authorization
                            </p>
                            <div className="modalSection numberedItems">
                                <table>
                                    <tbody>
                                    <tr>
                                        <td className="numBullet">
                                            3.4.1
                                        </td>
                                        <td>
                                            The designated Custodian shall establish an Access Control (Profile) Matrix for
                                            new systems and applications before it is put or deployed or commissioned to the
                                            production environment. Access control matrix shall be approved by the Head of
                                            the Department or his designate. Any exceptions to the defined access matrix
                                            shall require corresponding approval.3.3.2 Authentication of users shall use
                                            multi-factor authentication (MFA), where applicable, especially for
                                            administrator or console level access, and a risk-based MFA for systems
                                            processing personal data.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="numBullet">
                                            3.4.2
                                        </td>
                                        <td>
                                            The profile matrix shall be based on a Role-Based Access Control (RBAC)
                                            requirement where access rights or privileges are based on user’s job role and
                                            function.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="numBullet">
                                            {/* left empty */}
                                        </td>
                                        <td>
                                            <i>Example of Access Control (Profile) Matrix:</i>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="numBullet">
                                            {/* left empty */}
                                        </td>
                                        <td>
                                            <table className="policyTable" id="accessMatrix">
                                                <thead>
                                                <tr>
                                                    <th>Roles</th>
                                                    <th>Administrators</th>
                                                    <th>Marketing</th>
                                                    <th>HR</th>
                                                    <th>Customer Service</th>
                                                    <th>Finance</th>
                                                    <th>Audit</th>
                                                </tr>
                                                </thead>
                                                <thead>
                                                <tr>
                                                    <th>
                                                        Functionalities of System/ Application
                                                    </th>
                                                    <th colSpan="6">
                                                        Access Rights to the System/Application per Module or Functionality
                                                    </th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td>
                                                        Customer <br/>Details
                                                    </td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>View</td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Employee<br/> Details
                                                    </td>
                                                    <td>Edit</td>
                                                    <td></td>
                                                    <td>Edit</td>
                                                    <td>View</td>
                                                    <td>View</td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Audit Logs
                                                    </td>
                                                    <td>View</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td>View</td>
                                                    <td></td>
                                                    <td>View</td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        System Logs
                                                    </td>
                                                    <td>View</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                </tbody>

                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="numBullet">
                                            {/* left empty */}
                                        </td>
                                        <td>
                                            <i>This table shall serve as a reference only. Format, content, and details may
                                                vary as applicable to systems, applications, and or network
                                                devices.</i><br/><br/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="numBullet">
                                            3.4.3
                                        </td>
                                        <td>
                                            The CBCI Cybersecurity Department shall review the access control matrix to
                                            identify possible conflict in the segregation of duties (SoD).3.2.2 Access to
                                            CBCI systems and services shall only be given through the provision of a unique
                                            user account and complex password following the Password Standards described in
                                            Section 3.5
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="numBullet">
                                            3.4.4
                                        </td>
                                        <td>
                                            The designated Custodian shall review and update the access control matrix every
                                            time there is a major change in system or application to ensure functionalities
                                            on each profile and rights are still aligned with business objectives and
                                            current employee roles. If there is no change, the review and update shall be
                                            done at least once (1x) a year.
                                        </td>
                                    </tr>

                                    </tbody>
                                </table>
                            </div>
                            <p className="modalHeader2 newModalSection">
                                3.5 Password Standards
                            </p>
                            <div className="modalSection numberedItems">
                                <table>
                                    <tbody>
                                    <tr>
                                        <td className="numBullet">
                                            3.5.1
                                        </td>
                                        <td>
                                            All passwords assigned to user accounts shall follow the Password
                                            Standards described in the table below
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="numBullet">
                                            {/* left empty */}
                                        </td>
                                        <td>
                                            <table className="policyTable" id="passwordMatrix">
                                                <thead>
                                                <tr>
                                                    <th>Description</th>
                                                    <th>Standard</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td>
                                                        Password length
                                                    </td>
                                                    <td>
                                                        a. Regular or Standard user accounts (e.g. NT Logon) – <b>9
                                                        characters</b> <br/>
                                                        b. Administrators or Privileged user accounts (e.g. system
                                                        administrators, network administrators, etc.) – <b>14 characters</b><br/> Both
                                                        shall follow the password complexity rule
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Password complexity
                                                    </td>
                                                    <td>
                                                        a. Shall use mixed character sets following below complexity:<br/>
                                                        &nbsp;&nbsp;&nbsp;- Must have upper case alphabet characters (A, B,
                                                        C, ...)<br/>
                                                        &nbsp;&nbsp;&nbsp;- Must have lower case alphabet characters (a, b,
                                                        c, ...)<br/>
                                                        &nbsp;&nbsp;&nbsp;- Must have at least one special character (!, @,
                                                        #, $, ...) <br/>
                                                        &nbsp;&nbsp;&nbsp;- Must have at least one numerical character (0,
                                                        1, 2, ...)
                                                        b. Shall not contain username <br/>
                                                        c. Shall not have repetitive characters <br/>
                                                        d. Shall not accept blank or null as password <br/>
                                                        e. Shall not form any pattern or commonly used words
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Password expiration
                                                    </td>
                                                    <td>
                                                        a. Regular or Standard user accounts (e.g. NT Logon) – <b>every 30
                                                        days</b> <br/>
                                                        b. Administrators or Privileged user accounts (e.g. system
                                                        administrators, network administrators, etc.) – <b>every 60
                                                        days </b><br/>
                                                        The system shall require the user to enter a new valid password.
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Password history
                                                    </td>
                                                    <td>
                                                        There shall be no repetition of the last five (5) passwords
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Password/Account lockout
                                                    </td>
                                                    <td>
                                                        a. Shall be locked out for thirty (30) minutes after five (5)
                                                        consecutive failed logon attempts. <br/>
                                                        b. Three (3) consecutive five (5) failed logon attempts shall
                                                        disable the user.<br/>
                                                        c. Locked accounts shall be subject for enabling by respective
                                                        administrators upon endorsement of the user’s immediate supervisor
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Idle time out
                                                    </td>
                                                    <td>
                                                        Idle session timeout shall be configurable on the system for all
                                                        sessions that have been inactive (e.g. 15 minutes at a minimum) and
                                                        sessions must expire, or accounts must log out. The system shall
                                                        require the user to login again to continue session.
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="numBullet">
                                            3.5.2
                                        </td>
                                        <td>
                                            All passwords shall be masked during login and upon storage.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="numBullet">
                                            3.5.3
                                        </td>
                                        <td>
                                            Password changes shall be enforced upon first login and upon reset of password.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="numBullet">
                                            3.5.4
                                        </td>
                                        <td>
                                            Request for password reset with Service Desk shall be formally documented and a
                                            verification of the user’s identity shall be performed before granting a new
                                            password. The Service Desk shall issue a temporary password which will require
                                            the user to change upon login.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="numBullet">
                                            3.5.5
                                        </td>
                                        <td>
                                            If password expiration cannot be set due to any performance, administrative and
                                            or operational concern, it shall undergo the risk acceptance process.
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="modalSection modalSubSection">
                                <table>
                                    <tbody>
                                    <tr>
                                        <td className="numBullet">
                                            3.5.5.1
                                        </td>
                                        <td>
                                            The Project Proponent or Information Asset Owner shall accomplish the Risk
                                            Acceptance Form (RAF) and provide the necessary details such as risk scenario,
                                            risk mitigation, risk action plan, and have it signed and approved by his/her
                                            Department and submit to the Cyber Security Department.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="numBullet">
                                            {/* left empty*/}
                                        </td>
                                        <td>
                                            <i>Please refer to Appendix B for Risk Acceptance Form template.</i>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <p className="modalHeader2 newModalSection">
                                3.5.6 Change of User Account Requirements
                            </p>
                            <div className="modalSection numberedItems">
                                <table>
                                    <tbody>
                                    <tr>
                                        <td className="numBullet">
                                            3.5.7
                                        </td>
                                        <td>
                                            Where there is an organizational movement of a user, such as, but not limited to, a change
                                            in job function or role, transfer to another organization, among others, access rights or
                                            privileges shall be reviewed and updated by the designated Custodian before the user
                                            changes job role or responsibility, and or move or transfer to a different organization.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="numBullet">
                                            3.5.8
                                        </td>
                                        <td>
                                            Immediate manager will send the request to HR to update the employee’s job title and user
                                            access.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="numBullet">
                                            3.5.9
                                        </td>
                                        <td>
                                            Change in user account rights or privileges shall still be based on the defined access
                                            control matrix.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="numBullet">
                                            3.5.10
                                        </td>
                                        <td>
                                            CBCI Cyber Security Department shall perform a monthly review of the employee
                                            movement access modifications and submit the results of the review to the
                                            designated Custodian for their action to ensure that no excessive extensions and or
                                            provisions are enabled.
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <p className="modalHeader2 newModalSection">
                                3.6 Privilege Management
                            </p>
                            <div className="modalSection numberedItems">
                                <table>
                                    <tbody>
                                    <tr>
                                        <td className="numBullet">
                                            3.6.1
                                        </td>
                                        <td>
                                            The allocation of privileged accounts shall be restricted and controlled to limited
                                            number of authorized users, with a need for such access to carry out their tasks. It shall
                                            be created specific to an individual user and not generic user accounts.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="numBullet">
                                            3.6.2
                                        </td>
                                        <td>
                                            Authorization for use of privileged accounts shall only be provided upon explicit
                                            approval from the Head of the department and shall be documented by the
                                            Custodian.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="numBullet">
                                            3.6.3
                                        </td>
                                        <td>
                                            Privileged accounts shall not be used for standard day to day activities.
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <p className="modalHeader2 newModalSection">
                                3.7 Account Management
                            </p>
                            <div className="modalSection modalSubSection">
                                <p className="modalHeader2">
                                    3.7.1 Default System Accounts
                                </p>
                                <div className="modalSection numberedItems">
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td className="numBullet">
                                                3.7.1.1
                                            </td>
                                            <td>
                                                Where possible, default user accounts shall be disabled or have their
                                                default passwords changed. These accounts include “guest”, “temp”,
                                                “admin”, Administrator”, and any other commonly known or used default
                                                accounts, as well as related default passwords used by vendors on
                                                “commercial off-the shelf” systems and applications.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="numBullet">
                                                3.7.1.2
                                            </td>
                                            <td>
                                                Password of root accounts, admin, administrator, SA, shall be kept under the dual
                                                custodianship of the CBCI Cybersecurity Department and IT Operations.
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <p className="modalHeader2">
                                    3.7.2 Generic User Accounts
                                </p>
                                <div className="modalSection numberedItems">
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td className="numBullet">
                                                3.7.2.1
                                            </td>
                                            <td>
                                                The use of shared, anonymous and other such generic user accounts shall be
                                                prohibited where possible.
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <p className="modalHeader2">
                                    3.7.3 Emergency Accounts
                                </p>
                                <div className="modalSection numberedItems">
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td className="numBullet">
                                                3.7.3.1
                                            </td>
                                            <td>
                                                Where possible, emergency access accounts shall be established for critical systems
                                                and applications. Otherwise, the current administrator account shall be used as the
                                                emergency account.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="numBullet">
                                                3.7.3.2
                                            </td>
                                            <td>
                                                Emergency accounts shall only be used when normal processes are insufficient or in
                                                exceptional cases or during unforeseen circumstances e.g. natural disaster
                                                emergency, network unavailability, etc. The CBCI CIRC team shall be notified
                                                prior activation of emergency access accounts.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="numBullet">
                                                3.7.3.3
                                            </td>
                                            <td>
                                                Emergency access accounts shall only be for the requested time duration and shall be
                                                disabled immediately after the user has completed the task for which the access was
                                                granted.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="numBullet">
                                                3.7.3.4
                                            </td>
                                            <td>
                                                3.7.3.4 Credentials for emergency access accounts shall be kept secure and known only to
                                                restricted individuals, e.g. IT Head, CBCI Cybersecurity Head or his designate.
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <p className="modalHeader2">
                                    3.7.4 Application and Service Accounts
                                </p>
                                <div className="modalSection numberedItems">
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td className="numBullet">
                                                3.7.4.1
                                            </td>
                                            <td>
                                                Application and service accounts shall only be used by the application components
                                                requiring authentication. Access to the passwords shall be restricted to the authorized
                                                IT administrators or the application developers only.
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <p className="modalHeader2">
                                    3.7.5 Test Accounts
                                </p>
                                <div className="modalSection numberedItems">
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td className="numBullet">
                                                3.7.5.1
                                            </td>
                                            <td>
                                                3.7.5.1 Test accounts must only be used on test environments and only for testing purposes.                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <p className="modalHeader2 newModalSection">
                                3.8 Logical Access Review
                            </p>
                            <div className="modalSection numberedItems">
                                <table>
                                    <tbody>
                                    <tr>
                                        <td className="numBullet">
                                            3.8.1
                                        </td>
                                        <td>
                                            A Logical Access Review (LAR) process, shall be regularly conducted, based on the
                                            frequency set in 3.9.3, by the designated Custodian. The LAR process shall apply for
                                            regular or standards users and administrators or users with privileged access.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="numBullet">
                                            3.8.2
                                        </td>
                                        <td>
                                            The LAR shall cover the following:
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="modalSection modalSubSection">
                                <table>
                                    <tbody>
                                    <tr>
                                        <td className="numBullet">
                                            3.8.2.1
                                        </td>
                                        <td>
                                            Obtaining the HR master list of all the active CBCI employees including the
                                            current job role or function, and all the regular separated employees
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="numBullet">
                                            3.8.2.2
                                        </td>
                                        <td>
                                            Obtaining the list of vendor or contractual accounts who are current and
                                            actively deployed in CBCI
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="numBullet">
                                            3.8.2.3
                                        </td>
                                        <td>
                                            Extracting the users and the corresponding access rights or privileges and
                                            roles, including the privileged accounts
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="numBullet">
                                            3.8.2.4
                                        </td>
                                        <td>
                                            Reviewing the access rights or privileges against the user profiles and the
                                            required access rights or privileges
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="numBullet">
                                            3.8.2.5
                                        </td>
                                        <td>
                                            Identification of excessive and or unnecessary privileged accounts
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="numBullet">
                                            3.8.2.6
                                        </td>
                                        <td>
                                            Identification of generic accounts, dormant accounts and handling of disabled
                                            accounts
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="numBullet">
                                            3.8.2.7
                                        </td>
                                        <td>
                                            Revocation, disabling, or removal of resigned employee accounts, transferred
                                            employee accounts, terminated accounts, and vendor or contractual accounts
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="numBullet">
                                            3.8.2.8
                                        </td>
                                        <td>
                                            Reviewing the audit trails and the user access logs against the user roles and
                                            job functions
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="numBullet">
                                            3.8.2.9
                                        </td>
                                        <td>
                                            Obtaining the audit trails of usage of privileged access and the access logs for
                                            sensitive data or resources
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="numBullet">
                                            3.8.2.10
                                        </td>
                                        <td>
                                            3.8.2.10 Identifying anomalous or unauthorized access to data or functions
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div className="modalSection numberedItems">
                                <table>
                                    <tbody>
                                    <tr>
                                        <td className="numBullet">
                                            3.8.3
                                        </td>
                                        <td>
                                            The LAR shall be conducted by the designated Custodian with the following
                                            frequency, depending on the criticality of systems and or data classification:
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="numBullet">
                                            {/* left empty */}
                                        </td>
                                        <td>
                                            <table className="policyTable" id="larTable">
                                                <thead>
                                                <tr>
                                                    <th rowSpan="2">Asset Criticality,<br/> Data Classification,<br/> Type of Accounts</th>
                                                    <th className="lar"/>
                                                    <th className="lar" colSpan="2">Frequency of LAR</th>
                                                </tr>
                                                <tr id="lar">
                                                    <th/>
                                                    <th>From</th>
                                                    <th>To</th>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                <tr>
                                                    <td>
                                                        Critical systems processing <br/>or storing Highly Confidential <br/>information such as MECOM,<br/> iBayad, NOBP, and Bayad <br/>Mobile
                                                    </td>
                                                    <td>
                                                        Every other<br/> month
                                                    </td>
                                                    <td>
                                                        First week of the<br/> first month<br/> i.e. Jan 1st
                                                    </td>
                                                    <td>
                                                        End of the<br/> second month<br/> i.e. Feb 28th
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Critical systems other <br/>than those mentioned <br/>above
                                                    </td>
                                                    <td>
                                                        Quarterly
                                                    </td>
                                                    <td>
                                                        First week of <br/>the quarter <br/>i.e. Jan 1st
                                                    </td>
                                                    <td>
                                                        End of the quarter<br/> i.e. Mar 31st
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        All other systems, including <br/>third party accounts (e.g. <br/>vendors & contractors)
                                                    </td>
                                                    <td>
                                                        Semiannually
                                                    </td>
                                                    <td>
                                                        Every first week<br/> of the month,<br/> i.e. Jan and July
                                                    </td>
                                                    <td>
                                                        Every end of the<br/> month i.e. June<br/> and Dec
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        For privileged accounts on<br/> Critical systems
                                                    </td>
                                                    <td>
                                                        Monthly
                                                    </td>
                                                    <td>
                                                        First week of <br/>the month<br/>i.e. Jan 1st
                                                    </td>
                                                    <td>
                                                        End of the month <br/>i.e. Jan 31st
                                                    </td>
                                                </tr>
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <p className="modalHeader2 newModalSection">
                                3.9 Audit Logging
                            </p>
                            <div className="modalSection numberedItems">
                                <table>
                                    <tbody>
                                    <tr>
                                        <td className="numBullet">
                                            3.9.1
                                        </td>
                                        <td>
                                            User audit logs, classified as event logs in the CBCI CIRC team, shall be enabled and
                                            or activated on the systems or applications to capture user-management activities
                                            detailing the username, logins, logouts, successful and unsuccessful logon attempts,
                                            time stamps, source IP or MAC addresses, hostnames, user activities, and last login date.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="numBullet">
                                            3.9.2
                                        </td>
                                        <td>
                                            User audit logs shall be retained and maintained at least 120 days on the system. Logs
                                            with more than 120 days old shall be backed up and archived up to 3 years.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="numBullet">
                                            3.9.3
                                        </td>
                                        <td>
                                            All changes to user access rights and privileged access shall be logged and be readily 
                                            available to management when needed.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="numBullet">
                                            3.9.4
                                        </td>
                                        <td>
                                            Access to the monitoring logs shall be protected and restricted. User audit logs shall be
                                            made tamperproof or immutable.
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <p className="modalHeader2 newModalSection">
                                3.10 Access De-Provisioning
                            </p>
                            <div className="modalSection numberedItems">
                                <p>
                                    User accounts and access rights shall be revoked and or removed upon the following circumstances:
                                </p>
                                <ul className="dashed">
                                    <li> Access is no longer needed such as, but is not limited to, change in business processes, change in role or responsibility, employee movement or transfer of organization, employee separation, or termination of employment</li>
                                    <li> Access is used to violate company policies, guidelines, and procedures</li>
                                    <li> Account has been abused, misused, or compromised resulting to a security incident and or data breach</li>
                                </ul>
                                <p className="modalHeader2">
                                    3.10.1 Employee Separation/Retirement
                                </p>
                                <div className="modalSection numberedItems">
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td className="numBullet">
                                                3.10.1.1
                                            </td>
                                            <td>
                                                For employee separation/retirement, immediate supervisors must request removal
                                                of access on specific systems or applications to the designated Custodian.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="numBullet">
                                                3.10.1.2
                                            </td>
                                            <td>
                                                Access rights or privileges should be removed on the last working day in the
                                                Company.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="numBullet">
                                                3.10.1.3
                                            </td>
                                            <td>
                                                If the employee will still need to use any CBCI resource, a justification vetted by the
                                                immediate supervisor in charge shall be provided to the CBCI Cybersecurity
                                                Department for approval.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="numBullet">
                                                3.10.1.4
                                            </td>
                                            <td>
                                                The Custodian shall then implement the access rights extension in concurrence with
                                                his immediate superior.
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <p className="modalHeader2">
                                    3.10.2 Employee Termination
                                </p>
                                <div className="modalSection numberedItems">
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td className="numBullet">
                                                3.10.2.1
                                            </td>
                                            <td>
                                                For employee termination in lieu of a violation cases, access rights or privileges shall
                                                be immediately disabled or revoked on systems or applications and company assets
                                                where user has access to.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="numBullet">
                                                3.10.2.2
                                            </td>
                                            <td>
                                                The Custodian shall implement the disabling and revocation of access the same day
                                                upon receipt of notification from CBCI Human Resources Department.
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <p className="modalHeader2">
                                    3.10.3 Account Disabling
                                </p>
                                <div className="modalSection numberedItems">
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td className="numBullet">
                                                3.10.3.1
                                            </td>
                                            <td>
                                                All dormant accounts for more than 60 calendar days of inactivity are candidates for
                                                disabling. Verification shall be sent to the account owner’s immediate supervisors.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="numBullet">
                                                3.10.3.2
                                            </td>
                                            <td>
                                                The immediate supervisors shall provide a justification within 1 week upon receipt of
                                                notification if there is a need to reactivate, otherwise, the account shall be disabled.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="numBullet">
                                                3.10.3.3
                                            </td>
                                            <td>
                                                All unknown active accounts or accounts not associated to a specific owner shall be
                                                immediately disabled upon proper validation and approval of the designated
                                                Custodian.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="numBullet">
                                                3.10.3.4
                                            </td>
                                            <td>
                                                Accounts suspected to be compromised or has been used for any unusual activities
                                                shall be disabled immediately. Compromised accounts reported by the user or other
                                                party shall be vetted or confirmed with CBCI Cyber Security Department before
                                                disabling.
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <p className="modalHeader2">
                                    3.10.4 Account Deletion
                                </p>
                                <div className="modalSection numberedItems">
                                    <table>
                                        <tbody>
                                        <tr>
                                            <td className="numBullet">
                                                3.10.4.1
                                            </td>
                                            <td>
                                                No accounts shall be deleted on the system or application without proper validation
                                                and approval of the designated Custodian.
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className="numBullet">
                                                3.10.4.2
                                            </td>
                                            <td>
                                                No accounts shall be deleted for historical, audit, and investigation purposes unless
                                                otherwise requested, validated, and approved for such purpose.
                                            </td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <p className="modalHeader1 newModalSection">
                                4. Compliance and Review
                            </p>
                            <div className="modalSection numberedItems">
                                <table>
                                    <tbody>
                                    <tr>
                                        <td className="numBullet">
                                            4.1
                                        </td>
                                        <td>
                                            This policy shall be effective for compliance as part of CBCI company policies, rules, and 
                                            procedures.
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="numBullet">
                                            4.2
                                        </td>
                                        <td>
                                            This policy shall be enforced through regular audit or random sampling carried out by
                                            individuals or 3rd party consultants independent from CBCI Cybersecurity Department, e.g. 
                                            Internal Audit, Data Privacy Office
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className="numBullet">
                                            4.3
                                        </td>
                                        <td>
                                            CBCI Cybersecurity Department shall perform an annual review of this document and
                                            update, as needed or when changes are implemented.
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>
                            <p className="modalHeader1 newModalSection">
                                5. Non-Compliance
                            </p>
                            <div className="modalSection numberedItems">
                                <p>
                                    Non-compliance of this UAM Policy may lead to disciplinary actions and/or legal liability, depends
                                    on the gravity of the matter.
                                </p>
                            </div>
                        </>
                    </div>
                </Modal.Body>
            </div>
        </>
    );
}

export default Policy;