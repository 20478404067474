import React, {Component} from "react";
import Title from "../title/Title";
import Nametag from "../nametag/Nametag"

class Header extends Component {
    render() {
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-5">
                        <Title name={this.props.title}/>
                    </div>
                    <div className="col">
                        <Nametag name={this.props.fullname} username={this.props.username}/>
                    </div>
                </div>
            </div>
        );
    }

}

export default Header;