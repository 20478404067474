import { Image, Modal, Row, Table, Button } from 'react-bootstrap';
import { gql } from '@apollo/client';
import { useQuery, useSubscription } from '@apollo/client';
import './AddByBatchResult.css';
import { ON_CREATE_BATCHUSER } from '../../shared/utility/queries';
import React, { Component, useEffect, useMemo, useState } from 'react';
import LoadingModal from './LoadingModal';

class AddByBatchResult extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showModal: false,
			ack_id: '',
		};
		this.toggleModal = this.toggleModal.bind(this);
		this.closeModal = this.closeModal.bind(this);
	}

	toggleModal(value) {
		this.setState({ showModal: !this.state.showModal, ack_id: value });
	}

	closeModal() {
		this.setState({ showModal: false });
	}

	render() {
		const modalProps = {
			showModal: this.state.showModal,
			ackId: this.state.ack_id,
			closeModal: this.closeModal,
		};

		return <>{<AddByBatchResultModal {...modalProps} />}</>;
	}
}

function AddByBatchResultModal(props) {
	const { showModal, closeModal, ackId } = props;
	const [errorMsg, setErrorMsg] = useState([]);
	const [data, setData] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	const options = {
		variables: { ack_id: ackId },
		shouldResubscribe: true,
	};

	function onClickClose() {
		setIsLoading(true);
		setErrorMsg([]);
		setData([]);
		closeModal();
	}

	const { data: subData, error: subDataError } = useSubscription(ON_CREATE_BATCHUSER, options);

	useEffect(() => {
		if (subData) {
			const error = JSON.parse(subData.onCreateBatchUser.error_details);

			if (subData.onCreateBatchUser.progress == 'DONE') {
				let errArr = [];

				if (error[0] != undefined) {
					const errObj = {
						row: 0,
						errMsg: error[0],
					};
					errArr.push(errObj);
				} else {
					for (let x = 1; x <= 200; x++) {
						if (error[x] != undefined) {
							const errObj = {
								row: x,
								errMsg: error[x],
							};
							errArr.push(errObj);
						}
					}
				}
				setData(subData.onCreateBatchUser);
				setErrorMsg(errArr);
				setIsLoading(false);
			}
		}
	}, [subData]);

	useEffect(() => {
		if (subDataError) {
			console.log(subDataError);
		}
	}, [subDataError]);

	return (
		<>
			<Modal centered className='modal-batch-main' show={showModal}>
				{!isLoading && (
					<div className='modal-batch-header'>
						<Modal.Body>
							<Row className='justify-content-md-center'>
								<div className='div-header'>
									{data.success_records_count == data.total_records ? '' : data.success_records_count + ' out of '}
									{data.total_records + ' '}
									{data.total_records > 1 ? 'Users ' : 'User '}
									Created Successfully!
								</div>
								<div className='div-message'>{data.success_records_count == data.total_records ? 'No errors were found' : 'The following errors were found:'}</div>

								<tr style={{ backgroundColor: 'white' }} hidden={data.success_records_count == data.total_records}>
									<th className='headerRow'>Row</th>
									<th className='headerDetails'>Error Description</th>
								</tr>

								<div className='modal-batch-body' hidden={data.success_records_count == data.total_records}>
									{errorMsg != [] &&
										errorMsg.map((err) => {
											return (
												<tr>
													<td className='rowData'>{err.row}</td>
													<td className='rowDetails'>
														{err.errMsg.map((obj) => (
															<>
																{obj}
																<br />
															</>
														))}
													</td>
												</tr>
											);
										})}
								</div>
							</Row>
						</Modal.Body>
						<Modal.Footer className='modal-batch-footer'>
							<Button bsPrefix='platform-btn platform-btn-add' className='m-2' onClick={onClickClose}>
								Close
							</Button>
						</Modal.Footer>
					</div>
				)}
				{isLoading && <LoadingModal />}
			</Modal>
		</>
	);
}

export default AddByBatchResult;
