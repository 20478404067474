export function getLocalStorage(key, defaultValue = undefined) {
	const value = localStorage.getItem(key);

	if (!value) return defaultValue;

	return JSON.parse(value);
}

export function setLocalStorage(key, value) {
	if (!key) return;

	localStorage.setItem(key, value);
}

export function deleteLocalStorage(key) {
	localStorage.removeItem(key);
}

export function clearLocalStorage() {
	localStorage.clear();
}

export function getSessionStorage(key, defaultValue = undefined) {
	const value = sessionStorage.getItem(key);

	if (!value) return defaultValue;

	return JSON.parse(value);
}

export function setSessionStorage(key, value) {
	if (!key) return;

	const v = value ? JSON.stringify(value) : value;

	sessionStorage.setItem(key, v);
}

export function deleteSessionStorage(key) {
	sessionStorage.removeItem(key);
}

export function clearSessionStorage() {
	sessionStorage.clear();
}

export function clearAllStorage() {
	clearLocalStorage();
	clearSessionStorage();
}
