import React, {useState} from "react";

import './footer.css';
import {Col, Container, Modal, Row} from "react-bootstrap";
import Policy from "../policy/Policy";

function Footer() {
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    return (
        <>
            <Container fluid id="footer">
                <Row>
                    <Col>
                        <div id="disclaimer">
                            © 2021 Bayad. All rights reserved.
                        </div>
                    </Col>
                    <Col>
                        <div id="uamPolicy">
                            <a onClick={handleShow}>
                                UAM Policy
                            </a>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Modal size="lg" show={show} dialogClassName="policy-modal" centered onHide={handleClose}>
                <Policy/>
            </Modal>
        </>
    );
}

export default Footer;