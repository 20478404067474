import React, { Component } from 'react';
import { Button, Col, Container, Image, Row } from 'react-bootstrap';

import './Landingpage.css';
import { BrowserRouter as Router, Link, Redirect, Route, Switch } from 'react-router-dom';
import Integration from '../Integration/Integration';
import Nametag from '../nametag/Nametag';
import Footer from '../footer/Footer';
import logoFA from '../../images/bayad-fa.png';
import logoIP from '../../images/bayad-ip.png';
import logoUAM from '../../images/bayad-uam.png';
import logoBrand from '../../images/logo-MasterBrand.png';
import { createRandomString, getChallenge } from '../../shared/utility/codeGenerator';
import UserDashboard from '../User/UserDashboard';
import PrivateRoute from '../../components/route/PrivateRoute';
import qs from 'qs';
import HTTP from '../../shared/utility/http';
import { clearSessionStorage, getSessionStorage, setSessionStorage } from '../../shared/utility/storage';
import { TokenUtil, UserInfoUtil } from '../../shared/utility/session';
import { isUserHasAccess } from '../../shared/utility/utils';

const LIST_PLATFORM_PERMISSION = {
	scope: 'uam.platforms.list',
	resource: '/v2/platforms',
};

class Landingpage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			appKey: process.env.REACT_APP_APP_KEY,
			redirectUri: process.env.REACT_APP_CALLBACK_URL,
			state: null,
			codeVerifier: null,
			codeChallenge: null,
			hasState: !!getSessionStorage('state') && !!getSessionStorage('codeVerifier'),
			hasToken: !!TokenUtil.get(),
			userInfo: null,
			isExpired: false,
			isLogout: false,
			isBipDisabled: true,
			isBfaDisabled: true,
			isUAMDisabled: true,
			systemIntegrationAccess: false,
			isLoading: true,
		};
		this.isSessionExpired = this.isSessionExpired.bind(this);
		this.setPlatform = this.setPlatform.bind(this);
		this.bfaKey = null;
		this.bipKey = null;
		this.uamKey = null;
	}

	componentDidMount() {
		this.setState(
			{
				hasState: !!getSessionStorage('state') && !!getSessionStorage('codeVerifier'),
				hasToken: !!TokenUtil.get(),
				userInfo: UserInfoUtil.get(null),
			},
			async () => {
				this.getPlatforms();

				try {
					const systemIntegrationAccess = await isUserHasAccess(LIST_PLATFORM_PERMISSION);
					this.setState({ systemIntegrationAccess, isLoading: false });
				} catch (error) {
					this.setState({ isLoading: false });
				}
			}
		);
		if (!this.state.hasToken) {
			const codeVerifier = createRandomString(128);
			const state = createRandomString(40);
			const codeChallenge = getChallenge(codeVerifier);
			this.setState({
				codeChallenge: codeChallenge,
				state: state,
			});
			setSessionStorage('state', state);
			setSessionStorage('codeVerifier', codeVerifier);
		}
	}

	loginRedirect() {
		if (!this.state.hasToken) {
			return <Redirect to='/login' />;
		}
	}

	logoutRedirect() {
		if (this.state.isLogout) {
			const req = qs.stringify({ token: TokenUtil.get() });

			const logOutUrl = process.env.REACT_APP_ACCOUNTS_API + '/v1/oauth/logout';
			HTTP.post(logOutUrl, req)
				.then((data) => {
					clearSessionStorage();

					const codeVerifier = createRandomString(128);
					const state = createRandomString(40);
					const codeChallenge = getChallenge(codeVerifier);

					setSessionStorage('state', state);
					setSessionStorage('codeVerifier', codeVerifier);
					let loginUrl = process.env.REACT_APP_LOGIN_URI;
					const redirectLoginUrl =
						loginUrl +
						'?client_id=' +
						this.state.appKey +
						'&code_challenge=' +
						codeChallenge +
						'&code_challenge_method=S256' +
						'&response_type=code' +
						'&scope=' +
						'&state=' +
						state +
						'&redirect_uri=' +
						encodeURIComponent(this.state.redirectUri) +
						(this.state.isExpired ? '&session=expired' : '');
					window.location = redirectLoginUrl;
				})
				.catch((error) => {
					console.error(error);
				});
		}
	}

	isSessionExpired(expired) {
		this.setState({ isExpired: expired, isLogout: true });
	}

	getPlatforms() {
		if (this.state.userInfo) {
			var uuid = this.state.userInfo.uuid;
			HTTP.get('/v2/users/platforms/' + uuid)
				.then((data) => {
					var adminResult = data.data.data;
					let bfa = adminResult.find((data) => data.name === 'Bayad FA');
					let bip = adminResult.find((data) => data.name === 'Bayad IP');
					let uam = adminResult.find((data) => data.name === 'Bayad UAM');

					if (bfa) {
						this.bfaKey = bfa.key;
						this.setState({ isBfaDisabled: false });
					}
					if (bip) {
						this.bipKey = bip.key;
						this.setState({ isBipDisabled: false });
					}

					if (uam) {
						this.uamKey = uam.key;
						this.setState({ isUAMDisabled: false });
					}
				})
				.catch((error) => {
					console.log(`getPlatforms`);
					if (error.response.status === 403) {
						window.isAccessDenied = true;
					}
					console.log(error);
				});
		}
	}

	setPlatform(platformName) {
		if (platformName == 'BIP') {
			this.setState({
				appKey: this.bipKey,
			});

			setSessionStorage('platformName', 'Bayad IP');
			setSessionStorage('platformHeaders', this.bipKey);
		} else if (platformName == 'BFA') {
			this.setState({
				appKey: this.bfaKey,
			});

			setSessionStorage('platformName', 'Bayad FA');
			setSessionStorage('platformHeaders', this.bfaKey);
		} else if (platformName == 'UAM') {
			this.setState({
				appKey: this.uamKey,
			});

			setSessionStorage('platformName', 'Bayad UAM');
			setSessionStorage('platformHeaders', this.uamKey);
		}
	}

	render() {
		return (
			<Router>
				{this.loginRedirect()}
				{this.logoutRedirect()}
				<Switch>
					<Route exact path='/'>
						{this.state.hasToken ? (
							<>
								<div className='container-fluid'>
									<div className='row'>
										<div className='col'>
											<Image className='brand' src={logoBrand} />
										</div>
										<div className='col' style={{ paddingRight: '28px' }}>
											{this.state.userInfo ? (
												<Nametag name={this.state.userInfo.firstname + ' ' + this.state.userInfo.lastname} username={this.state.userInfo.username} />
											) : (
												<Nametag name='Juan Dela Cruz' username='jude8' />
											)}
										</div>
									</div>
								</div>
								<Container fluid className='home'>
									<Row>
										<div className='pageTitle'>Welcome to User Access Management</div>
										<div className='pageSubTitle'>Select a platform you want to manage</div>
									</Row>
									<Row className='justify-content-md-center'>
										<Col md='auto'>
											<Link to={this.state.isBipDisabled ? '#' : '/user-dashboard'}>
												<Button className='btn btns btnMng' type='button' disabled={this.state.isBipDisabled} onClick={() => this.setPlatform('BIP')}>
													<Image src={logoIP} className='btnImg' />
													<div className='text lbl-platform'>Integrated Platform</div>
												</Button>
											</Link>
										</Col>
										<Col md='auto'>
											<Link to={this.state.isBfaDisabled ? '#' : '/user-dashboard'}>
												<Button className='btn btns btnMng' type='button' disabled={this.state.isBfaDisabled} onClick={() => this.setPlatform('BFA')}>
													<Image src={logoFA} className='btnImg' />
													<div className='text lbl-platform'>Front-end Application</div>
												</Button>
											</Link>
										</Col>
										<Col md='auto'>
											<Link to={this.state.isUAMDisabled ? '#' : '/user-dashboard'}>
												<Button className='btn btns btnMng' type='button' disabled={this.state.isUAMDisabled} onClick={() => this.setPlatform('UAM')}>
													<Image src={logoUAM} className='btnImg' />
													<div className='text lbl-platform'>User Access Management</div>
												</Button>
											</Link>
										</Col>
									</Row>
									<Row>
										<Col>
											{this.state.systemIntegrationAccess && (
												<Link to='/integration'>
													<Button className='btn btns' id='sysIntegBtn' type='button'>
														<div className='text'>System Integration</div>
													</Button>
												</Link>
											)}
										</Col>
									</Row>
								</Container>
								<Footer className='footer' />
							</>
						) : null}
					</Route>
					<PrivateRoute exact path='/integration' component={Integration} hasAccess={this.state.systemIntegrationAccess} isLoading={this.state.isLoading} />
					<Route path='/user-dashboard' render={() => <UserDashboard />} />
					<Route
						exact
						path='/login'
						component={() => {
							let loginUrl = process.env.REACT_APP_LOGIN_URI;
							const redirectLoginUrl =
								loginUrl +
								'?client_id=' +
								this.state.appKey +
								'&code_challenge=' +
								this.state.codeChallenge +
								'&code_challenge_method=S256' +
								'&response_type=code' +
								'&scope=' +
								'&state=' +
								this.state.state +
								'&redirect_uri=' +
								encodeURIComponent(this.state.redirectUri) +
								(this.state.isExpired ? '&session=expired' : '');
							window.location = redirectLoginUrl;
							return null;
						}}
					/>
				</Switch>
			</Router>
		);
	}
}

export default Landingpage;
