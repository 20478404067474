import React, { Component, useState } from "react";
import {
  Alert,
  Button,
  Modal,
  ModalBody,
  ModalTitle,
  Dropdown,
  DropdownButton,
} from "react-bootstrap";
import "../../shared/styles/floating-label.css";
import "./userDetails.css";
import instance from "../../shared/utility/http";
import ModalHeader from "react-bootstrap/ModalHeader";
import moment from "moment-timezone";
import validator from "validator/es";
import _ from "underscore";
import { BsX } from "react-icons/bs";
import { UserInfoUtil } from "../../shared/utility/session";
import { getSessionStorage } from "../../shared/utility/storage";

class ResetPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showConfirm: false,
      showResetPass: false,
      isEditMode: false,
      verifiedEditable: false,
      unverifiedEditable: false,
      isSaveDisabled: false,
      isBFA: false,
      multiSelectDisabled: true,
      id: "",
      hash: "",
      username: "",
      email: "",
      lastname: "",
      firstname: "",
      middlename: "",
      mobileNumber: "",
      status: "",
      isOtp: 0,
      type: "",
      emailVerifiedAt: "",
      mobileNumberVerifiedAt: "",
      createdAt: "",
      roleId: 0,
      roleName: "",
      branches: [],
      branchNames: [],
      jobTitle: "",
      division: "",
      department: "",
      employmentStartDate: "",
      userLogs: [],
      editDetails: {},
      otpEdit: 0,
      firstnameValid: true,
      lastnameValid: true,
      middlenameValid: true,
      emailValid: true,
      mobile_numberValid: true,
      divisionValid: true,
      job_titleValid: true,
      departmentValid: true,
      employment_start_dateValid: true,
      roleList: [],
      userBranch: [],
      showBranch: true,
      uamPlatform: "",
      faPlatform: "Bayad FA",
      ipPlatform: "Bayad IP",
      userInfo: UserInfoUtil.get(),
      updateEnabled: false,
      alertDisplay: {
        show: false,
        message: "",
        style: "",
      },
      resetReason: "",
      resetValue: "",
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.resetPass = this.resetPass.bind(this);
    this.queryData = this.queryData.bind(this);
    this.validateField = this.validateFields.bind(this);
    this.showAlert = this.showAlert.bind(this);
    this.showResetPassAlert = this.showResetPassAlert.bind(this);
    this.toggleAlert = this.toggleAlert.bind(this);
    this.reload = this.reload.bind(this);
    this.loadAfterChange = this.loadAfterChange.bind(this);

    this.roleRef = React.createRef();
    this.firstNameRef = React.createRef();
    this.lastNameRef = React.createRef();
    this.middleNameRef = React.createRef();
    this.emailRef = React.createRef();
    this.contactNumberRef = React.createRef();
    this.jobTitleRef = React.createRef();
    this.divisionRef = React.createRef();
    this.departmentRef = React.createRef();
    this.employmentRef = React.createRef();
    this.multiselectRef = React.createRef();
  }

  toggleModal() {
    this.setState({ showModal: !this.state.showModal }, () => {
      if (this.state.showModal) {
        this.queryData();
      } else {
        this.reload();
      }
    });
  }

  reload() {
    this.setState({
      showConfirm: false,
      isEditMode: false,
      verifiedEditable: false,
      unverifiedEditable: false,
      isSaveDisabled: false,
      id: "",
      hash: "",
      username: "",
      email: "",
      lastname: "",
      firstname: "",
      middlename: "",
      mobileNumber: "",
      status: "",
      isOtp: 0,
      type: "",
      emailVerifiedAt: "",
      mobileNumberVerifiedAt: "",
      createdAt: "",
      roleId: 0,
      roleName: "",
      branchNames: [],
      jobTitle: "",
      division: "",
      department: "",
      employmentStartDate: "",
      userLogs: [],
      editDetails: {},
      otpEdit: 0,
      firstnameValid: true,
      lastnameValid: true,
      middlenameValid: true,
      emailValid: true,
      mobile_numberValid: true,
      divisionValid: true,
      job_titleValid: true,
      departmentValid: true,
      employment_start_dateValid: true,
      roleList: [],
      userBranch: [],
      updateEnabled: false,
      alertDisplay: {
        show: false,
        message: "",
        style: "",
      },
      resetReason: "",
      resetValue: "",
    });
  }

  queryData() {
    const userDetail = this.props.data.find((item)=>
      item.id === this.props.userId
    );
    this.setState({
      firstname: userDetail.firstname,
      lastname: userDetail.lastname,
      username: userDetail.username,
      email: userDetail.email,
      emailVerifiedAt: userDetail.emailVerifiedAt,
      firstnameValid: true,
      lastnameValid: true,
      emailValid: true,
    });
  }

  renderStatus() {
    if (this.state.emailVerifiedAt) {
      return <span className="user-verified">Verified User</span>;
    } else {
      return (
        <span className="user-not-verified">User is not yet verified</span>
      );
    }
  }

  resetValues() {
    this.multiselectRef.current.resetSelectedValues();
  }

  componentDidMount() {
    if (
      getSessionStorage("platformName", "").toUpperCase() ===
      "Bayad FA".toUpperCase()
    ) {
      this.setState({ isBFA: true });
    }
  }

  loadAfterChange() {
    this.roleRef.current.value = this.state.roleName;
    this.firstNameRef.current.value = this.state.firstname;
    this.lastNameRef.current.value = this.state.lastname;
    this.middleNameRef.current.value = this.state.middlename;
    this.emailRef.current.value = this.state.email;
    this.contactNumberRef.current.value = this.state.mobileNumber;
    if (this.jobTitleRef.current) {
      this.jobTitleRef.current.value = this.state.jobTitle;
      this.divisionRef.current.value = this.state.division;
      this.departmentRef.current.value = this.state.department;
      this.employmentRef.current.value = this.state.employmentStartDate;
    }
  }

  validateFields(name, value) {
    switch (name) {
      case "firstname":
        this.setState({
          firstnameValid: validator.isLength(value, { min: 1, max: 255 }),
        });
        break;
      case "lastname":
        this.setState({
          lastnameValid: validator.isLength(value, { min: 1, max: 255 }),
        });
        break;
      case "job_title":
        this.setState({
          job_titleValid: validator.isLength(value, { min: 1, max: 255 }),
        });
        break;
      case "department":
        this.setState({
          departmentValid: validator.isLength(value, { min: 1, max: 255 }),
        });
        break;
      case "division":
        this.setState({
          divisionValid: validator.isLength(value, { min: 1, max: 255 }),
        });
        break;
      case "middlename":
        this.setState({
          middlenameValid: validator.isLength(value, { min: 0, max: 255 }),
        });
        break;
      case "email":
        this.setState({ emailValid: validator.isEmail(value) });
        break;
      case "mobile_number":
        this.setState({
          mobile_numberValid:
            validator.isInt(value) &&
            validator.isLength(value, { max: 10, min: 10 }),
        });
        break;
      case "employment_start_date":
        this.setState({
          employment_start_dateValid: validator.isDate(value, {
            format: "YYYY/DD/MM",
          }),
        });
        break;
      default:
        break;
    }
  }

  async resetPass(event) {
    event.preventDefault();

    if (this.state.resetReason != "") {
      const body = {
        platform: getSessionStorage("platformHeaders"),

        passExpireDate: moment(new Date()).format("MM/DD/YYYY hh:mm:ss"),

        templateName: this.state.resetValue,

        reason: this.state.resetReason,

        recipientUserId: this.props.userId,
      };

      const response = await instance.post("/v2/email ", body);
      this.showResetPassAlert(response.status);
      this.toggleModal();
    }
  }

  showAlert(s) {
    if (s === 200) {
      let alertState = { ...this.state.alertDisplay };
      alertState.show = !alertState.show;
      alertState.message = "Your changes have been saved!";
      alertState.style = "alert-success";
      this.setState({ alertDisplay: alertState });
    } else {
      let alertState = { ...this.state.alertDisplay };
      alertState.show = !alertState.show;
      alertState.message = "Your changes were unsuccessful";
      alertState.style = "alert-error";
      this.setState({ alertDisplay: alertState });
    }
  }

  showResetPassAlert(s) {
    if (s === 200) {
      let alertState = { ...this.state.alertDisplay };
      alertState.message =
        "Email for reset password has been sent to your email";
      alertState.style = "success";
      this.props.parentCallback(alertState.message, alertState.style);
    } else {
      let alertState = { ...this.state.alertDisplay };
      alertState.message = "Failed to send password reset";
      alertState.style = "failed";
      this.props.parentCallback(alertState.message, alertState.style);
    }
  }

  toggleAlert() {
    let alertState = { ...this.state.alertDisplay };
    alertState.show = !alertState.show;
    this.setState({ alertDisplay: alertState });
  }

  render() {
    const dropdownResetReason = [
      {
        label: "New User",
        value: "new_user_reset_template",
      },
      {
        label: "Forgot Password",
        value: "forgot_password_reset_template",
      },
      {
        label: "Expired Password",
        value: "expired_password_reset_template",
      },
      {
        label: "Unblocked User Account",
        value: "unblocked_account_template",
      },
    ];

    return (
      <>
        <Modal show={this.state.showModal} keyboard={false} centered contentClassName="reset-modal-content">
          <ModalHeader style={{ border: "0 none" }}>
            <ModalTitle>
              {this.state.firstname +
                " " +
                this.state.lastname +
                "  (" +
                this.state.username +
                ") "}
              {this.renderStatus()}
            </ModalTitle>
          </ModalHeader>
          <ModalBody>
            <div>
              <div>
                <h5>Password Reset</h5>
              </div>
              <div className="reset-div">
                <label>Reason:</label>
                <DropdownButton
                  id="dropdown-basic-button"
                  className="reset-dropdown"
                  title={
                    this.state.resetReason != ""
                      ? this.state.resetReason
                      : "Select Reason"
                  }
                >
                  {dropdownResetReason
                    ? dropdownResetReason.map((item) => {
                        return (
                          <Dropdown.Item
                            onClick={() => {
                              this.setState({
                                resetReason: item.label,
                                resetValue: item.value,
                              });
                            }}
                          >
                            {item.label}
                          </Dropdown.Item>
                        );
                      })
                    : ""}
                </DropdownButton>
              </div>
              <div className="reset-message-div">
                {`A verification link will be sent to ` +
                  this.state.firstname +
                  `'s email (` +
                  this.state.email +
                  `) to reset his/her password.` +
                  ` Do you want to proceed?`}
              </div>
            </div>
          </ModalBody>
          <Modal.Footer className="reset-footer">
            <Button
              className="user-btn"
              style={{ width: "140px" }}
              onClick={this.toggleModal}
            >
              Cancel
            </Button>
            <Button
              className="user-btn close-btn"
              style={{ width: "140px" }}
              onClick={this.resetPass}
            >
              Confirm
            </Button>
          </Modal.Footer>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              alignItems: "center",
            }}
          >
            <Alert
              show={this.state.alertDisplay.show}
              className="edit-user-alert"
              id={this.state.alertDisplay.style}
              onClose={this.toggleAlert}
            >
              <div>
                {this.state.alertDisplay.message}
                <span style={{ float: "right" }}>
                  <BsX onClick={this.toggleAlert} />
                </span>
              </div>
            </Alert>
          </div>
        </Modal>
      </>
    );
  }
}

export default ResetPassword;
