import React, { Component } from "react";
import { Form, Row } from "react-bootstrap";
import CustomDatePicker from "../../shared/components/CustomDatePicker";
import { Button, InputGroup, FormControl } from "react-bootstrap";
import { FiSearch } from "react-icons/fi";
import { BsFileEarmarkArrowDown } from "react-icons/bs";

class RoleListFilter extends Component {
  render() {
    return (
      <div className="contianer-fluid">
        <Row>
          <div className="search-grp col-8">
            <div>
              <Form.Label
                className="search-grp-text header-item"
                style={{ marginRight: "0px" }}
              >
                Filter:
              </Form.Label>
            </div>
            <div style={{ margin: "0 14px" }}>
              <CustomDatePicker
                selected={this.props.dateFrom}
                onChange={(date) => this.props.setDate(date, "dateFrom")}
                className="form-control role-list-datepicker"
                placeholder="Date From"
                title="Select Date From"
              />
            </div>
            <div style={{ marginRight: "19px" }}>
              <CustomDatePicker
                selected={this.props.dateTo}
                onChange={(date) => this.props.setDate(date, "dateTo")}
                className="form-control role-list-datepicker"
                placeholder="Date To"
                style={{ marginLeft: "10px" }}
                title="Select Date To"
              />
            </div>
            <div
              className="col-3"
              style={{
                height: "36px",
                width: "356px",
              }}
            >
              <InputGroup style = {{width: "321px" }}>
                <InputGroup.Prepend>
                  <span className="search-button-role-list input-group-text">
                    <FiSearch className="icon" />
                  </span>
                </InputGroup.Prepend>
                <FormControl
                  style={{ borderLeft: 0 }}
                  value={this.props.search}
                  onChange={this.props.searchOnChange}
                  placeholder="Search Role Name"
                  className="role-list-filter-inputs"
                />
              </InputGroup>
            </div>
          </div>
          <div
            className="col d-flex flex-row-reverse"
            style={{ paddingRight: "25px" }}
          >
            {/*<Button
              variant="secondary"
              className="d-flex justify-content-evenly role-list-download-btn"
            >
              <BsFileEarmarkArrowDown
                style={{ width: "1.3rem", height: "1.3rem" }}
              />
              Download
            </Button>*/}
          </div>
        </Row>
      </div>
    );
  }
}

export default RoleListFilter;
