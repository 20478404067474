import React, { useState } from "react";
import { Button, Col, Form as BsForm, Row, Form, Image } from "react-bootstrap";
import HTTP from "../../shared/utility/http";
import axios from "axios";
import exclamation from "../../images/ic-exclamation.svg";
import "./AddByBatchResult.css";

const AddByBatch = (props) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedFileName, setSelectedFileName] = useState("No file selected");
  const [isLoading, setIsLoading] = useState(false);
  const [fileError, setFileError] = useState("");
  const [enableBtn, setEnableBtn] = useState(false);

  const onFileChange = (event) => {
    if (event.target.files[0]) {
      setSelectedFile(event.target.files[0]);
      setSelectedFileName(event.target.files[0].name);
      setEnableBtn(true);
    } else {
      setEnableBtn(false);
    }
  };

  const uploadFile = () => {
    const file = selectedFile;
    const url = "v2/users/batch-upload/url";
    
    if (checkFile()) {
      setEnableBtn(false);
      HTTP.get(url, {
        params: {
          fileName: file?.name,
          contentType: file?.type,
        },
      })
        .then((response) => {
          if (response.status == 200) {
            putUploadFile(response.data.data.url, response.data.data.file_name);
          }
        })
        .catch((error) => {
          setFileError("Upload Error");
          console.log(error);
          setEnableBtn(true);
        })
    }
  };

  const putUploadFile = (url, fileName) => {
    axios
      .put(url, selectedFile, {
        headers: {
          "Content-Type": selectedFile.type,
        },
      })
      .then(() => {
        getAckId(fileName);
      })
      .catch((error) => {
        setFileError("Upload Error");
        console.log(error);
        setEnableBtn(true);
      });
  };

  const getAckId = (file_name) => {
    const body = {
      file_name: file_name,
    };
    HTTP.post("/v2/users/batch-upload", JSON.stringify(body))
      .then((data) => {
        if (props.toggleLoadingBatchOn)
          props.toggleLoadingBatchOn(data.data.ack_id);

        props.toggleModal();
      })
      .catch((error) => {
        console.log(error.response.data);
        setFileError(error.response.data.message);
        setIsLoading(false);
      }).finally(() => {
        setEnableBtn(true);
      });
  };

  const checkFile = () => {
    const file = selectedFile;
    setFileError("");

    const acceptableTypes = [
      "application/vnd.ms-excel",
      "text/plain",
      "text/csv",
      "text/tsv",
    ];
    const fileName = file?.name;

    if (file?.size > 15000000) {
      setFileError(
        "Maximum allowed file size per attachement exceeded. Only 15mb per attachment is allowed"
      );
      return false;
    }

    if (!fileName.includes(".csv")) {
      setFileError("Invalid file type");
      return false;
    }

    if (!acceptableTypes.includes(file?.type)) {
      console.log(file?.type);
      setFileError("Invalid file type");
      return false;
    }
    return true;
  };

  const hiddenFileInput = React.useRef(null);

  const handleClick = () => {
    hiddenFileInput.current?.click();
  };

  return (
    <>
      {!isLoading && (
        <Form
          hidden={!(props.uploadType == "Multi")}
          style={{ paddingLeft: "10px" }}
        >
          <Form.Group controlId="formFileSm" className="mb-3">
            <Form.Label style={{ paddingLeft: "7px" }}>
              You can batch import a number of users with this feature using a
              CSV file.
            </Form.Label>

            <div class="fileInput">
              <div style={{ paddingLeft: "5px" }}>
                <input
                  id={
                    fileError == ""
                      ? "disabled-input-id"
                      : "disabled-input-id-err"
                  }
                  value={selectedFileName}
                />
              </div>

              <Button
                bsPrefix="browse-btn"
                className="m-2"
                style={{
                  display: "block",
                  width: "100%",
                }}
                onClick={handleClick}
              >
                Browse
              </Button>
            </div>

            <div>
              {fileError != "" ? (
                <span className="file-error-batch">
                  <Image src={exclamation} style={{ paddingRight: "5px" }} />
                  {fileError}
                </span>
              ) : (
                ""
              )}
            </div>
            <input
              type="file"
              onChange={onFileChange}
              style={{ display: "none" }}
              ref={hiddenFileInput}
              accept=".csv"
            />
          </Form.Group>

          <Row>
            <Col>
              <div
                className="input-block pt-2"
                style={{ fontSize: "0.7rem", fontWeight: "bold" }}
              ></div>
            </Col>
            <Col style={{ "--bs-gutter-x": "1.5rem" }}>
              <Row style={{ "--bs-gutter-x": "1.5rem" }}>
                <Col>
                  <Button
                    bsPrefix="platform-btn platform-btn-cancel"
                    className="m-2"
                    style={{
                      display: "block",
                      width: "100%",
                    }}
                    onClick={props.toggleModal}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col>
                  <Button
                    bsPrefix="platform-btn platform-btn-add"
                    className="m-2"
                    style={{ display: "block", width: "100%" }}
                    onClick={uploadFile}
                    disabled={!enableBtn}
                  >
                    Upload
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      )}
    </>
  );
};
export default AddByBatch;
