import React, { useEffect, useState } from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import HTTP from '../../shared/utility/http';
import qs from 'qs';
import Loading from '../loading/Loading';
import { getSessionStorage } from '../../shared/utility/storage';
import {
	RefreshTokenUtil,
	TokenUtil,
	UserInfoUtil,
} from '../../shared/utility/session';

function useQuery() {
	return new URLSearchParams(useLocation().search);
}

function Callback() {
	const [isLoading, setLoading] = useState(true);
	const [token, setToken] = useState();
	const [refreshToken, setRefreshToken] = useState();
	const [userInfo, setUserInfo] = useState({
		uuid: '',
		username: '',
		emailAddress: '',
		lastname: '',
		firstname: '',
		middlename: '',
		mobileNumber: '',
		emailVerifiedAt: '',
		mobileNumberVerifiedAt: '',
		roles: [],
		uamRole: '',
	});
	let query = useQuery();
	let code = query.get('code');
	let state = query.get('state');
	const req = qs.stringify({
		client_id: process.env.REACT_APP_APP_KEY,
		redirect_uri: '',
		code_verifier: getSessionStorage('codeVerifier'),
		grant_type: 'authorization_code',
		code: code,
	});
	const headers = {
		'Content-Type': 'application/x-www-form-urlencoded',
	};
	useEffect(() => {
		HTTP.post(process.env.REACT_APP_ACCESS_TOKEN_URL, req, { headers })
			.then((data) => {
				setToken(data['data']['access_token']);
				setRefreshToken(data['data']['refresh_token']);

				const authHeader = {
					Authorization: 'Bearer ' + data['data']['access_token'],
				};

				return HTTP.get(process.env.REACT_APP_ACCOUNTS_API + '/v1/oauth/user', {
					headers: authHeader,
				});
			})
			.then((data) => {
				const uamRoleData = data['data']['data']['roles'].find(
					(role) => role.platform.id === process.env.REACT_APP_APP_KEY
				);
				const uamRole = uamRoleData ? uamRoleData.name : 'None';
				setUserInfo({
					uuid: data['data']['data']['uuid'],
					username: data['data']['data']['username'],
					emailAddress: data['data']['data']['emailAddress'],
					lastname: data['data']['data']['lastname'],
					firstname: data['data']['data']['firstname'],
					middlename: data['data']['data']['middlename'],
					mobileNumber: data['data']['data']['mobileNumber'],
					emailVerifiedAt: data['data']['data']['emailVerifiedAt'],
					mobileNumberVerifiedAt:
						data['data']['data']['mobileNumberVerifiedAt'],
					roles: data['data']['data']['roles'],
					uamRole: uamRole,
				});
				setLoading(false);
			})
			.catch((error) => {
				console.error(error);
			});
	}, []);
	if (!(state && state.length > 0 && state === getSessionStorage('state'))) {
		return <h1>State did not match.</h1>;
	}
	if (isLoading) {
		return <Loading text='Logging in' />;
	} else {
		UserInfoUtil.set(userInfo);
		TokenUtil.set(token);
		RefreshTokenUtil.set(refreshToken);
		return window.location.replace('/');
	}
}

export default Callback;
