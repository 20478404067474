import React, { Component } from "react";
import DatePicker, { CalendarContainer } from "react-datepicker";
import { getYear, getMonth } from "date-fns";
import Moment from "moment";
import { extendMoment } from "moment-range";
import { Card } from "react-bootstrap";

class CustomDatePicker extends Component {
  render() {
    return (
      <DatePicker
        dateFormat={this.props.dateFormat || "MM/dd/yyyy"}
        disabled={this.props.disabled || false}
        selected={this.props.selected}
        onChange={this.props.onChange}
        onFocus={this.props.onFocus}
        onBlur={this.props.onBlur}
        className={this.props.className}
        placeholderText={this.props.placeholder}
        calendarContainer={(props) => {
          return <CustomContainer {...props} label={this.props.title} />;
        }}
        renderCustomHeader={CustomHeader}
        required={this.props.required || false}
      />
    );
  }
}

function CustomContainer(props) {
  return (
    <Card>
      <Card.Body>
        <CalendarContainer>
          <div className="text-center" style={{ marginBottom: "10px" }}>
            <b>{props.label}</b>
          </div>
          <div style={{ position: "relative" }}>{props.children}</div>
        </CalendarContainer>
      </Card.Body>
    </Card>
  );
}

function CustomHeader(props) {
  const moment = extendMoment(Moment);
  const years = Array.from(
    moment
      .range(
        new Date(1990, 0, 1),
        new Date().setFullYear(new Date().getFullYear() + 20)
      )
      .by("year")
  );
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  return (
    <div className="d-flex justify-content-evenly">
      <div>
        <select
          className="form-select form-select-sm"
          value={months[getMonth(props.date)]}
          onChange={({ target: { value } }) =>
            props.changeMonth(months.indexOf(value))
          }
        >
          <option value={0}>Month</option>
          {months.map((month) => {
            return (
              <option key={month} value={month}>
                {month}
              </option>
            );
          })}
        </select>
      </div>
      <div>
        <select
          className="form-select form-select-sm"
          value={getYear(props.date)}
          onChange={({ target: { value } }) => props.changeYear(value)}
        >
          <option value={0}>Year</option>
          {years.map((year) => {
            return (
              <option key={year.format("YYYY")} value={year.format("YYYY")}>
                {year.format("YYYY")}
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
}

export default CustomDatePicker;
