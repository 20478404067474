import React, {Component} from "react";
import Callback from "./callback/Callback";
import {BrowserRouter as Router} from "react-router-dom";
import {Route, Switch} from "react-router-dom";
import SessionTimeout from "./SessionHandler/SessionTimeout";
import Landingpage from "./landingpage/Landingpage";

class App extends Component {

    constructor(props){
        super(props)
    }

    render(){
        return(
            <Router>
                <Switch>
                    <Route exact path="/callback" component={Callback}/>
                    <Route path='/' render={(props) => <SessionTimeout {...props} /> } />
                    <Route path='/loginPage' render={ <Landingpage /> } />
                </Switch>
            </Router>
        )
    }
}


export default App
