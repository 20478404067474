import React, { Component, useState } from "react";
import { Image, Row, Modal, Button } from "react-bootstrap";
import "../../shared/styles/floating-label.css";
import "./userDetails.css";
import _ from "underscore";

import spinner from "../../images/ic-loading.svg";
class LoadingModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: props.showLoading,
    };

    this.toggleModal = this.toggleModal.bind(this);
  }

  toggleModal() {
    this.setState({ showModal: !this.state.showModal });
  }

  render() {
    return (
      <>
        <div className="loading-body-batch">
          <Row className="justify-content-md-center">
            <Image src={spinner} className="spinner-logo" />
          </Row>
          <Row className="justify-content-md-center">
            <div className="loading-text">User is being added</div>
            <div className="loading-subtext">It will take a few seconds</div>
          </Row>
        </div>
      </>
    );
  }
}

export default LoadingModal;
