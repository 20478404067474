import React, { Component } from "react";
import { Form, Row, Col, Dropdown, DropdownButton } from "react-bootstrap";
import CustomDatePicker from "../../shared/components/CustomDatePicker";
import { Button, InputGroup, FormControl } from "react-bootstrap";
import { FiSearch } from "react-icons/fi";
import { BsFileEarmarkArrowDown } from "react-icons/bs";
import "./UserList.css";
import instance from "../../shared/utility/http";
import moment from "moment";

class UserListFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.fetchRoles = this.fetchRoles.bind(this);
  }

  componentDidMount() {
    this.fetchRoles();
  }

  fetchRoles() {
    instance
      .get("/v1/roles/?sort=ASC")
      .then((response) => {
        this.setState({ roles: response.data.data.map((x) => x.name) });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  render() {
    return (
      <div className="contianer-fluid">
        <Row>
          <div className="search-grp col-8">
            <div>
              <Form.Label
                className="search-grp-text header-item"
                style={{ marginRight: "0px" }}
              >
                Filter:
              </Form.Label>
            </div>
            <div style={{ margin: "0 14px" }}>
              <DropdownButton
                id="dropdown-basic-button"
                className="user-dropdown"
                title={this.state.role ? this.state.role : "User Role"}
              >
                {this.state.roles
                  ? this.state.roles.map((role) => {
                      return (
                        <Dropdown.Item
                          onClick={async () => {
                            await this.setState({ role: role });
                            this.props.queryData({
                              search: this.state.search,
                              role: this.state.role,
                              type: this.state.type,
                              status: this.state.status,
                            });
                          }}
                        >
                          {role}
                        </Dropdown.Item>
                      );
                    })
                  : ""}
              </DropdownButton>
            </div>
            <div style={{ marginRight: "19px" }}>
              <DropdownButton
                id="dropdown-basic-button"
                className="user-dropdown"
                title={this.state.type ? this.state.type : "User Type"}
              >
                {["INTERNAL", "EXTERNAL"].map((type) => {
                  return (
                    <Dropdown.Item
                      onClick={async () => {
                        await this.setState({ type: type });
                        this.props.queryData({
                          search: this.state.search,
                          role: this.state.role,
                          type: this.state.type,
                          status: this.state.status,
                        });
                      }}
                    >
                      {type}
                    </Dropdown.Item>
                  );
                })}
              </DropdownButton>
            </div>
            <div style={{ marginRight: "24px" }}>
              <DropdownButton
                id="dropdown-basic-button"
                className="user-dropdown"
                title={this.state.status ? this.state.status : "Status"}
              >
                {["Blocked", "Temporary Blocked", "Inactive", "Active"].map(
                  (status) => {
                    return (
                      <Dropdown.Item
                        onClick={async () => {
                          await this.setState({ status: status });
                          this.props.queryData({
                            search: this.state.search,
                            role: this.state.role,
                            type: this.state.type,
                            status:
                              this.state.status === "Temporary Blocked"
                                ? "TEMPORARY_BLOCKED"
                                : this.state.status,
                          });
                        }}
                      >
                        {status}
                      </Dropdown.Item>
                    );
                  }
                )}
              </DropdownButton>
            </div>
            <div
              className="col-3"
              style={{
                height: "36px",
              }}
            >
              <InputGroup style={{ width: "356px" }}>
                <InputGroup.Prepend>
                  <span className="search-button-role-list input-group-text">
                    <FiSearch className="icon" className="search-icon" />
                  </span>
                </InputGroup.Prepend>
                <FormControl
                  style={{ borderLeft: 0 }}
                  value={this.state.search ? this.state.search : ""}
                  onChange={async (event) => {
                    await this.setState({ search: event.target.value });
                    if (
                      this.state.search.length > 2 ||
                      this.state.search === ""
                    ) {
                      this.props.queryData({
                        search: this.state.search,
                        role: this.state.role,
                        type: this.state.type,
                        status: this.state.status,
                      });
                    }
                  }}
                  placeholder="Search Username, Firstname, Lastname, Email"
                  className="role-list-filter-inputs"
                />
              </InputGroup>
            </div>
          </div>
          {/* <div
            className="col d-flex flex-row-reverse"
            style={{ paddingRight: "25px" }}
          >
            {/*<Button
                variant="secondary"
                className="d-flex justify-content-evenly role-list-download-btn"
            >
              <BsFileEarmarkArrowDown
                  style={{ position: "relative", top: "5px" }}
                  style={{ width: "1.3rem", height: "1.3rem" }}
              />
              Download
            </Button>
                </div> */}
        </Row>
      </div>
    );
  }
}

export default UserListFilter;
