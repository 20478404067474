import HTTP from './http';
import qs from 'qs';
import { TokenUtil } from './session';
import { clearSessionStorage } from './storage';

async function logOut() {
	const req = qs.stringify({ token: TokenUtil.get() });

	const logOutUrl = process.env.REACT_APP_ACCOUNTS_API + '/v1/oauth/logout';
	HTTP.post(logOutUrl, req)
		.then((data) => {
			clearSessionStorage();
			window.location.reload();
			console.log('successfully removed');
		})
		.catch((error) => {
			console.error(error);
		});
}

export { logOut };
