/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { Component, useMemo } from "react";
import { useTable, useSortBy, usePagination } from "react-table";
import { MdEdit, MdRemoveCircle } from "react-icons/md";
import { FaList } from "react-icons/fa";
import instance from "../../shared/utility/http";
import { Card } from "react-bootstrap";
import RoleListFilter from "./RoleListFilter";
import "./RoleList.css";
import moment from "moment";
import ViewRole from "./ViewRole";
import CustomModal from "../../shared/modal/CustomModal";
import ReactToolTip from "react-tooltip";
import { isUserHasAccess } from "../../shared/utility/utils";
import Loading from "../loading/Loading";

function formatDate(date) {
  const plusMonth = date.getMonth() + 1;
  const month = plusMonth < 10 ? "0" + plusMonth : plusMonth;
  return `${date.getFullYear()}-${month}-${date.getDate()}`;
}

const VIEW_ROLE_PERMISSION = {
  scope: "uam.roles.permissions.list",
  resource: "/v3/roles/{role_id}/permissions",
};

const EDIT_ROLE_PERMISSION = {
  scope: "uam.roles.update",
  resource: "/roles/{role_id}",
};

class RoleList extends Component {
  constructor() {
    super();
    this.state = {
      showModal: true,
      activeModal: undefined,
      data: [],
      controlledPageCount: 0,
      totalCount: 0,
      dateTo: "",
      dateFrom: "",
      search: "",
    };
    this.viewRoleModalRef = React.createRef();
    this.editRoleModalRef = React.createRef();
    this.callbackFunction = this.callbackFunction.bind(this);
    this.displayAlertNotification = this.displayAlertNotification.bind(this);
    this.setDisplayModal = this.setDisplayModal.bind(this);
    this.pagination = this.pagination.bind(this);
    this.sort = this.sort.bind(this);
    this.setDate = this.setDate.bind(this);
    this.searchOnChange = this.searchOnChange.bind(this);
    this.properties = {
      pageIndex: 0,
      pageSize: 25,
      sort: undefined,
      column: undefined,
    };
    this.columns = [
      {
        Header: "Date and Time",
        accessor: ({ createdAt, isActive }) => {
          return { createdAt, isActive };
        },
        Cell: ({ value }) => {
          const color = value.isActive ? "#000" : "#F81C2C";
          return <span style={{ color }}>{value.createdAt}</span>;
        },
        sortInverted: true,
        id: "createdAt",
      },
      {
        Header: "Role Name",
        accessor: ({ name, isActive }) => {
          return { name, isActive };
        },
        Cell: ({ value }) => {
          const color = value.isActive ? "#000" : "#F81C2C";
          return (
            <span
              style={{ color, whiteSpace: "normal", wordBreak: "break-all" }}
            >
              {value.name}
            </span>
          );
        },
        id: "name",
      },
      {
        Header: "Created By",
        accessor: ({ user, isActive }) => {
          return { createdBy: user.username, isActive };
        },
        Cell: ({ value }) => {
          const color = value.isActive ? "#000" : "#F81C2C";
          return <span style={{ color }}>{value.createdBy}</span>;
        },
        disableSortBy: true,
        id: "createdBy",
      },
      {
        Header: "Action",
        accessor: ({ id, isActive, name, connectedUser }) => {
          return { id, isActive, name, connectedUser };
        },
        disableSortBy: true,
        Cell: ({ value }) => {
          const colorEditMore = value.isActive ? "#F26122" : "#6c757d";
          const enableDisableColor = value.isActive ? "#F81C2C" : "#00AF00";
          return (
            <div
              className="d-flex justify-content-between"
              style={{ marginRight: "10px" }}
            >
              <MdEdit
                data-tip="Edit"
                data-for="edit"
                onClick={() => this.onEditRoleClick(value)}
                style={{ fill: colorEditMore, cursor: "pointer" }}
              />
              <MdRemoveCircle
                data-tip={value.isActive ? "Disable" : "Enable"}
                data-for="disEn"
                style={{ fill: enableDisableColor, cursor: "pointer" }}
              />
              <FaList
                data-tip="View"
                data-for="details"
                onClick={() => this.onViewRoleClick(value)}
                style={{ fill: colorEditMore, cursor: "pointer" }}
              />
              <ReactToolTip id="edit" place="bottom" type="light" />
              <ReactToolTip id="disEn" place="bottom" type="light" />
              <ReactToolTip id="details" place="bottom" type="light" />
            </div>
          );
        },
        id: "action",
      },
    ];

    this.queryData = this.queryData.bind(this);
  }

  async onViewRoleClick(value) {
    if (await isUserHasAccess(VIEW_ROLE_PERMISSION)) {
      await this.setState({ activeModal: value });
      this.viewRoleModalRef.current.toggleModal();
    } else {
      window.isAccessDenied = true;
    }
  }

  async onEditRoleClick(value) {
    if (await isUserHasAccess(EDIT_ROLE_PERMISSION)) {
      await this.setState({ activeModal: value });
      this.editRoleModalRef.current.toggleModal();
    } else {
      window.isAccessDenied = true;
    }
  }

  callbackFunction(message, type, roleName) {
    this.setState({
      message: message,
      type: type,
      display: true,
      updatedRoleName: roleName,
    });
    this.displayAlertNotification();
  }

  displayAlertNotification() {
    if (this.state && this.state.display) {
      let data = [...this.state.data];
      const roleNameToBeUpdated = this.state.activeModal.name;
      const index = data.findIndex((val) => val.name === roleNameToBeUpdated);
      let dataEntry = { ...data[index] };
      dataEntry["name"] = this.state.updatedRoleName;
      data[index] = dataEntry;

      this.setState({ data: data });
    }
  }

  setDisplayModal(display) {
    this.setState({ display: display });
  }

  editRoleModalRef = ({ toggleModal }) => {
    this.showModal = toggleModal;
  };

  viewRoleModalRef = ({ toggleModal }) => {
    this.showModal = toggleModal;
  };

  componentDidMount() {
    this.props.setTitle("Role List");
    this.props.setButtonTitle("Add New Role");
    this.props.setFlag("Role");
  }

  componentWillUnmount() {
    this.props.setTitle("User List");
    this.props.setButtonTitle("Add New User");
    this.props.setFlag("User");
  }

  setDate(date, key) {
    this.setState({ [key]: date }, () => {
      if (this.state.dateFrom !== "" && this.state.dateTo !== "") {
        this.queryData();
      }
    });
  }

  searchOnChange(event) {
    this.setState({ search: event.target.value }, () => {
      if (this.state.search.length > 2 || this.state.search === "") {
        this.queryData();
      }
    });
  }

  queryData() {
    let params = { page: this.properties.pageIndex + 1 };
    const self = this;

    if (this.properties.sort && this.properties.column) {
      params["sort"] = this.properties.sort;
      params["sort_by"] = this.properties.column;
    }

    if (
      this.state.dateFrom !== "" &&
      this.state.dateFrom != null &&
      this.state.dateTo !== "" &&
      this.state.dateTo != null
    ) {
      params["dateFrom"] = formatDate(this.state.dateFrom);
      params["dateTo"] = formatDate(this.state.dateTo);
    }

    if (this.state.search.length > 2 || this.state.search === "") {
      params["search"] = this.state.search;
    }

    instance
      .get("/v1/roles/", {
        params,
      })
      .then(function (response) {
        self.setState({
          data: response.data.data.map((x) => {
            return {
              ...x,
              createdAt: moment(new Date(x.createdAt)).format(
                "MM/DD/YYYY h:mm:ss a"
              ),
            };
          }),
          totalCount: response.data.meta.total_count,
          controlledPageCount: Math.ceil(response.data.meta.total_count / 25),
        });
      })
      .catch(function (error) {
        console.log(error);
      });
  }

  sort(desc, column) {
    const isDesc = column === "createdAt" ? !desc : desc;
    this.properties.sort = isDesc ? "DESC" : "ASC";
    this.properties.column = column;
    this.queryData();
  }

  pagination({ pageIndex, pageSize }) {
    this.properties.pageIndex = pageIndex;
    this.properties.pageSize = pageSize;
    this.queryData();
  }

  render() {
    const tableProps = {
      columns: this.columns,
      data: this.state.data,
      controlledPageCount: this.state.controlledPageCount,
      pagination: this.pagination,
      count: this.state.totalCount,
      sort: this.sort,
    };

    const filterProps = {
      dateFrom: this.state.dateFrom,
      dateTo: this.state.dateTo,
      setDate: this.setDate,
      search: this.state.search,
      searchOnChange: this.searchOnChange,
    };

    return (
      <>
        {this.state.display && (
          <CustomModal {...this.state} setDisplayModal={this.setDisplayModal} />
        )}
        <RoleListFilter {...filterProps} />
        <div className="p-3">
          <Card>
            <Table {...tableProps} />
          </Card>
        </div>
        <ViewRole
          {...{ activeModal: this.state.activeModal }}
          ref={this.editRoleModalRef}
          isEditRole={true}
          parentCallback={this.callbackFunction}
        />
        <ViewRole
          {...{ activeModal: this.state.activeModal }}
          ref={this.viewRoleModalRef}
          parentCallback={this.callbackFunction}
        />
      </>
    );
  }
}

function Table(props) {
  let columns = useMemo(() => props.columns, [props.columns]);
  let data = useMemo(() => props.data, [props.data]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    // Get the state from the instance
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        sortBy: useMemo(() => [{ id: "createdAt", desc: false }], []),
      },
      manualSortBy: true,
      manualPagination: true,
      pageCount: props.controlledPageCount,
    },
    useSortBy,
    usePagination
  );

  const { pagination } = props;
  React.useEffect(() => {
    pagination({ pageIndex, pageSize });
  }, [pagination, pageIndex, pageSize]);

  return (
    <>
      <table
        className="table table-striped rounded"
        style={{ overflow: "hidden" }}
        {...getTableProps()}
      >
        <colgroup>
          <col span="1" width="30%"></col>
          <col span="1" width="30%"></col>
          <col span="1" width="30%"></col>
          <col span="1" width="10%"></col>
        </colgroup>
        <thead className="text-white" style={{ backgroundColor: "#0176C0" }}>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => (
                <th
                  {...column.getHeaderProps()}
                  className={
                    i + 1 === 4 ? "text-center fw-normal" : "fw-normal"
                  }
                  style={{
                    paddingLeft: "19px",
                    paddingTop: "14px",
                    paddingBottom: "15px",
                  }}
                >
                  {column.canSort ? (
                    <div className="d-flex justify-content-start">
                      <div style={{ marginRight: "10px" }}>
                        {column.render("Header")}
                      </div>

                      <div style={{ fontSize: "8px", marginTop: "3px" }}>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <div
                              className="lh-1"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                column.toggleSortBy(false, false);
                                props.sort(false, column.id);
                              }}
                            >
                              &#9650;
                            </div>
                          ) : (
                            <div
                              style={{ marginTop: "7px", cursor: "pointer" }}
                              onClick={() => {
                                column.toggleSortBy(true, false);
                                props.sort(true, column.id);
                              }}
                            >
                              &#9660;
                            </div>
                          )
                        ) : (
                          <>
                            <div
                              className="lh-1"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                column.toggleSortBy(false, false);
                                props.sort(false, column.id);
                              }}
                            >
                              &#9650;
                            </div>
                            <div
                              className="lh-1"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                column.toggleSortBy(true, false);
                                props.sort(true, column.id);
                              }}
                            >
                              &#9660;
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  ) : (
                    <div style={{ marginRight: "10px" }}>
                      {column.render("Header")}
                    </div>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell, i) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      style={{ paddingLeft: "19px" }}
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="d-flex justify-content-between w-100 p-4">
        <div>
          Showing {page.length} of {props.count}
        </div>
        <nav aria-label="Page navigation example">
          <ul className="pagination">
            <li
              className={!canPreviousPage ? "page-item disabled" : "page-item"}
            >
              <a
                onClick={() => gotoPage(0)}
                className="page-link"
                href="#"
                style={{ color: "#000" }}
                aria-label="Previous"
              >
                <span aria-hidden="true">&lt;</span>
              </a>
            </li>
            <li
              className={!canPreviousPage ? "page-item disabled" : "page-item"}
            >
              <a
                onClick={() => previousPage()}
                className="page-link"
                style={{ color: "#000" }}
                href="#"
                aria-label="Previous"
              >
                <span aria-hidden="true">&lt;</span>
              </a>
            </li>
            {pageOptions.map((num) => {
              const disabled = pageIndex === num;
              return (
                <li
                  key={num}
                  className={disabled ? "page-item disabled" : "page-item"}
                >
                  <a
                    className="page-link"
                    style={{ color: "#000" }}
                    onClick={() => gotoPage(num)}
                    href="#"
                  >
                    {num + 1}
                  </a>
                </li>
              );
            })}
            <li className={!canNextPage ? "page-item disabled" : "page-item"}>
              <a
                onClick={() => nextPage()}
                className="page-link"
                style={{ color: "#000" }}
                href="#"
                aria-label="Next"
              >
                <span aria-hidden="true">&gt;</span>
              </a>
            </li>
            <li className={!canNextPage ? "page-item disabled" : "page-item"}>
              <a
                onClick={() => gotoPage(pageCount - 1)}
                className="page-link"
                style={{ color: "#000" }}
                href="#"
                aria-label="Next"
              >
                <span aria-hidden="true">&gt;</span>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </>
  );
}

export default RoleList;
