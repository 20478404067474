import React, {Component} from "react";
import 'react-bootstrap';

import {Form, Modal, Button} from "react-bootstrap";
import {Row, Col} from "react-bootstrap";
import "./AddPlatform.css";
import "../../shared/styles/floating-label.css";
import "./swal-custom-css.css"
import swal from "sweetalert";

import HTTP from "../../shared/utility/http";


class AddPlatform extends Component {
    constructor(props){
        super(props);
        this.state = {
            showModal: false,
            platformName: "",
            grantType: "",
            callbackUrl: "",
            blockingSetting: "",
            isCallbackUrlValid: true
        };

        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onInputChange = this.onInputChange.bind(this);
        this.toggleModal = this.toggleModal.bind(this);
    }

    componentDidMount() {
        this.setState({
            showModal: false,
            isCallbackUrlValid: true,
            grantType: "authorization_code",
            blockingSetting: "centralized"
        });
    }

    onInputChange(e) {
        let name = e.target.name;
        let val = e.target.value;
        this.setState({[name]: val});

        if(name === "callbackUrl" && !this.isCallbackUrlValid) {
            this.setState({isCallbackUrlValid: true});
        }
    }

    isDisabled() {
        if (this.state.platformName !== "" && this.state.grantType !== "" && this.state.callbackUrl !== ""
            && this.state.isCallbackUrlValid) {
            return false
        }
        return true
    }

    toggleModal() {
        this.setState({ showModal: !this.state.showModal }, () => {
            this.resetForm();
        })
    }

    resetForm(){
        this.setState({
            platformName: "",
            callbackUrl: "",
            isCallbackUrlValid: true
        });
    }

    async onFormSubmit(e) {
        e.preventDefault();
        await HTTP.post("/v2/platforms", JSON.stringify(this.state))
            .then((data) => {
                this.toggleModal();
                swal({
                    text: `Platform ${ data["data"]["data"]["platformName"]} has been added!`,
                    button: "Done",
                    closeOnClickOutside: false
                });
                this.props.addedRoleSuccessfully(true);
            }).catch((error) => {
                const errResponseMessage = error.response.data;
                if (errResponseMessage.message === "callbackUrl is not a valid url.") {
                    this.setState({isCallbackUrlValid: false});
                }
            });
    }

    showAddPlatformModal(){
        const grantTypeOptions = [
            {
                "key": "auth_code",
                "grantType": "authorization_code",
                "grantTypeName": "Authorization Code"
            }
        ]

        return(
            <Modal size="lg" centered show={this.state.showModal} onHide={this.toggleModal}>
                <Modal.Body>
                    <h3 className="modal-title">Add Platform</h3>
                    <Form onSubmit={this.onFormSubmit}>
                        <Form.Group>
                            <Row className="p-2">
                                <Col>
                                    <div className="input-block">
                                        <Form.Control
                                            id="platformName"
                                            name="platformName"
                                            type="text"
                                            required
                                            onChange={this.onInputChange}>
                                        </Form.Control>
                                        <span className="placeholder">
                                            Platform Name
                                          </span>
                                    </div>
                                </Col>
                                <Col>
                                    <div className="input-block">
                                        <Form.Control
                                            id="grantType"
                                            name="grantType"
                                            as="select"
                                            className="platform-select"
                                            custom
                                            disabled
                                            required
                                            defaultValue={this.state.grantType}
                                            onChange={this.onInputChange}>
                                            {grantTypeOptions.map(grantType => (
                                                <option key={grantType.key} value={grantType.grantType}>{grantType.grantTypeName}</option>
                                            ))}
                                        </Form.Control>
                                        <span className="placeholder">
                                            Grant Type
                                          </span>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="p-2">
                                <Col>
                                    <Form.Control type="hidden" name="blockingSetting" value="centralized">
                                    </Form.Control>
                                    <div className="input-block">
                                        <Form.Control
                                            id="callbackUrl"
                                            name="callbackUrl"
                                            type="text"
                                            required
                                            onChange={this.onInputChange}>
                                        </Form.Control>
                                        <span className="placeholder">
                                            Callback URL
                                          </span>
                                    </div>
                                    { !this.state.isCallbackUrlValid && <small style={{color: "Red", padding: "10px"}}>Callback URL is not a valid url.</small> }
                                </Col>
                            </Row>
                        </Form.Group>
                        <Row className="p-2">
                            <Col className="text-center">
                                <div>{this.isDisabled()}</div>
                                <Button bsPrefix="platform-btn platform-btn-cancel" className="m-2" onClick={this.toggleModal}>Cancel</Button>
                                <Button type="submit" bsPrefix="platform-btn platform-btn-add" className="m-2" disabled={this.isDisabled()}>Add</Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
        );
    }

    render() {
        return (
            <>
                { this.showAddPlatformModal()}
            </>
        );
    }
}

export default AddPlatform;
