import React, {Component} from "react";
import 'react-bootstrap';

import { Modal, Container, Col, Row, Pagination} from "react-bootstrap";
import { MdClose } from "react-icons/md";
import "./AuditRole.css";
import "../../shared/styles/floating-label.css";
import instance from "../../shared/utility/http";

class AuditRole extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showAuditRole: false,
        };
        this.table = this.table.bind(this);
    }

    async fetchLogs(page = 1) {
        instance
            .get(`/v1/roles/${this.state.activeModal.id}/logs?page=${page}&limit=25`)
            .then(resp => {
                this.setState({logs: resp.data.data});
                this.setState({pagination: resp.data.meta});
                const {limit, total_count} = resp.data.meta;
                this.setState({totalPage: Math.ceil(total_count/limit)});
                console.log(this.state)
            })
            .catch(err => {
                console.log(err);
            });
    }

    async toggleModal() {
        if (!this.state.showAuditRole) {
            await this.setState({
                showAuditRole: true,
                activeModal: this.props.activeModal,
                currentPage: 1,
            });
            await this.fetchLogs();
        } else {
            await this.setState({logs: undefined, pagination: undefined});
            this.setState({showAuditRole: false});
        }
    }

    showAuditRoleModal() {
        return (
            <Modal size="xl" centered show={this.state.showAuditRole} animation={false}>
                <Modal.Body style={{minHeight: '100vh'}}>
                    <MdClose onClick={() => this.toggleModal()}
                             style={{ fontSize: '24px', cursor: "pointer", float: 'right' }}
                             className="mr-2"/>
                    <h2 className="modal-title m-2">Audit Trail: {this.state.activeModal ? this.state.activeModal.name :''}</h2>
                    <Container fluid>
                        <Row className="p-4">
                            {this.table()}
                        </Row>
                        <Row lg="12" className="px-4">
                            <Col lg="8">
                                {
                                    this.state.pagination ?
                                        <span>Showing {this.state.logs && this.state.pagination.limit > this.state.logs.length
                                            ? this.state.logs.length :  this.state.pagination.limit} of {this.state.pagination.total_count}</span> :
                                        ''
                                }

                            </Col>
                            <Col lg="4">
                                <div style={{float: 'right'}} className="my-pagination">
                                    <Pagination>
                                        <Pagination.Prev onClick={() => {
                                            const currentPage = this.state.currentPage;
                                            if (currentPage > 1) {
                                                this.setState({logs: undefined});
                                                this.setState({currentPage: currentPage - 1});
                                                this.fetchLogs(currentPage - 1);
                                            }
                                        }}/>
                                        {
                                            Array.from({length: this.state.totalPage}, (_, i) => i + 1).map(x => {
                                                return <Pagination.Item
                                                    active={x === this.state.currentPage}
                                                    onClick={() => {
                                                        if (x !== this.state.currentPage) {
                                                            this.setState({logs: undefined});
                                                            this.setState({currentPage: x});
                                                            this.fetchLogs(x);
                                                    }
                                                }}>{x}</Pagination.Item>
                                            })
                                        }
                                        <Pagination.Next onClick={() => {
                                            const currentPage = this.state.currentPage;
                                            const finalPage = this.state.totalPage;
                                            if (currentPage < finalPage) {
                                                this.setState({logs: undefined});
                                                this.setState({currentPage: currentPage + 1});
                                                this.fetchLogs(currentPage + 1);
                                            }
                                        }}/>
                                    </Pagination>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        );
    }

    render() {
        return (
            <>
                {this.showAuditRoleModal()}
            </>
        );
    }

    table() {
        return (
                <>
                    <table
                        className="table table-striped rounded"
                        style={{ overflow: "hidden", borderTop: "1px solid black" }}
                    >
                        <colgroup>
                            <col span="1" width="20%"></col>
                            <col span="1" width="10%"></col>
                            <col span="1" width="20%"></col>
                            <col span="1" width="20%"></col>
                            <col span="1" width="10%"></col>
                            <col span="1" width="10%"></col>
                            <col span="1" width="10%"></col>
                        </colgroup>
                        <thead style={{ backgroundColor: "#FFFFFF" }}>
                        <tr>
                            <th>Date and Time</th>
                            <th>Action</th>
                            <th>Old Values</th>
                            <th>New Values</th>
                            <th>Logged By</th>
                            <th>Source IP</th>
                            <th>Reason</th>
                        </tr>
                        </thead>
                        <tbody>
                        {this.state.logs ? this.state.logs.map((row, i) => {
                            return (
                                <tr key={i}>
                                    <td>{row.created_at}</td>
                                    <td>{row.action}</td>
                                    <td style={{wordBreak: 'break-all'}}>{row.old_values}</td>
                                    <td style={{wordBreak: 'break-all'}}>{row.new_values}</td>
                                    <td>{row.name}</td>
                                    <td>{row.source_ip}</td>
                                    <td>{row.note}</td>
                                </tr>
                            );
                        }) : ''}
                        </tbody>
                    </table>
                </>
        );
    }
}

export default AuditRole;
