import axios from "axios";
import { logOut } from "./logout";
import { TokenUtil } from "./session";
import { getSessionStorage } from "./storage";

if (window.apiCall == null) {
  window.apiCall = 0;
}

const BASE_URL = process.env.REACT_APP_API_ENDPOINT;
const platformId = process.env.REACT_APP_APP_KEY;

const instance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
    "x-bayad-platform-id": platformId,
  },
});

// Add a request interceptor
instance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    const token = TokenUtil.get();
    if (token) {
      config.headers.Authorization = "Bearer " + token;
    }

    const platformHeaders = getSessionStorage("platformHeaders", null);
    if (platformHeaders) {
      config.headers["x-bayad-platform-id"] = platformHeaders;
    } else {
      config.headers["x-bayad-platform-id"] = process.env.REACT_APP_APP_KEY;
    }

    window.apiCall++;
    return config;
  },
  function (error) {
    // Do something with request error
    console.log("ERROR REQUEST", error);
    return Promise.reject(error);
  }
);

// Add a response interceptor
instance.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  async (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const e = { ...error };
    console.log("ERROR API-CLIENT", error.response);
    if (error.response.status === 401) {
      await logOut();
    }

    if (error.response.status === 403) {
      window.isAccessDenied = true;
    }
    return Promise.reject(error);
  }
);

export default instance;
