import crypto from "crypto-js";

export function createRandomString(num) {
    return [...Array(num)].map(() => Math.random().toString(36)[2]).join('');
}

function base64Url(string) {
    return string.toString(crypto.enc.Base64)
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=/g, '');
}

export function getChallenge(verifier) {
    return base64Url(crypto.SHA256(verifier));
}
