import UserListIcon from "../../images/ic-nav-userlist-black.svg";
import UserRoleIcon from "../../images/ic-nav-rolemgt-black.svg";

export const routes = [
  {
    key: "user-list",
    title: "User List",
    icon: UserListIcon,
    link: "/user-dashboard",
  },
  {
    key: "user-role",
    title: "Role Management",
    icon: UserRoleIcon,
    link: "/user-dashboard/role-management",
  },
];
