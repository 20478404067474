import React, {Component} from "react"
import {Route, Switch} from "react-router-dom";

import Dashboard from "../../containers/Dashboard/Dashboard"
import AuditTrailSettings from "./AuditTrailSettings"
import PlatformList from "./PlatformList"
import {routes} from "./routes"
import uamLogo from "../../images/logo-UAM.png";
import Header from "../header/Header"

import './Integration.css';

class Integration extends Component {

    render() {

        const sideBarProps = {routes: routes, logo: uamLogo};
        //data should be fetch from user logged in

        return(
            <Dashboard sideBarProps={sideBarProps}>
                <div className="parent container-fluid">
                    <div className="header">
                        <Header title="Platform List" />
                    </div>
                    <div className="content container-fluid">
                        <Switch>
                            <Route exact path="/integration" component={PlatformList} />
                            <Route path="/integration/audit" component={AuditTrailSettings} />
                        </Switch>
                    </div>
                </div>
            </Dashboard>
        );
    }
}

export default Integration;