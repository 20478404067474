import {useTable} from "react-table";
import React, {useMemo} from "react";
import {Table} from "react-bootstrap";
import "./AuditPreview.css"

function AuditPreview(props) {
    let columns = useMemo(() => props.columns, [props.columns]);
    let data = useMemo(() => props.data, [props.data]);
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
    } = useTable({
        columns,
        data,
    })

    return (
        <Table {...getTableProps()} className="audit-table">
            <thead>
            {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                        <th {...column.getHeaderProps({
                            style: { width: column.width }
                        })}>
                            {column.render('Header')}
                        </th>
                    ))}
                </tr>
            ))}
            </thead>
            <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
                prepareRow(row)
                return (
                    <tr {...row.getRowProps()}
                        style={{
                            backgroundColor: i % 2 === 1 ? "#0000000A" :"#ffffff"
                        }}
                    >
                        {row.cells.map(cell => {
                            return <td {...cell.getCellProps({
                                style: {
                                    maxWidth: cell.column.width,
                                    wordBreak: "break-word"
                                }
                            })}>
                                {cell.render('Cell')}
                            </td>
                        })}
                    </tr>
                )
            })}
            </tbody>
        </Table>
    )
}

export default AuditPreview;